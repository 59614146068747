import styled from "styled-components";
import { gradients } from "../../styles/gradients";
import colors from "../../styles/colors";
import profileExchangeIcon from "../../assets/images/ProfileExchange_20_Gray900@3x.png";
import profileExchangeDiscountIcon from "../../assets/images/img_tickets@3x.png";
import rightArrowIcon from "../../assets/images/Right_12_Gray600@3x.png";

export const GradientBorderContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 4px;
  border: 1px solid transparent;
  background:
    // NOTE - 배경색
    linear-gradient(${colors.Blue300}, ${colors.Blue300}),
    // NOTE - border 색상
    ${gradients.Linear_1_03};
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 12px;
`;

export const FreeMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin: 11px;
`;

export const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-right: 4px;
`;

export const DiscountMainContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 11px;
  margin-right: 11px;
  margin-top: 7px;
  margin-bottom: 7px;
`;

export const DiscountSubContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-left: 8px;
`;

export const TimeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 4px;
`;

export const DiscountTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-right: 4px;
`;

export const ProfileExchangeIcon = styled.img.attrs({
  src: `${profileExchangeIcon}`,
})`
  width: 20px;
  height: 20px;
`;

export const ProfileExchangeDiscountIcon = styled.img.attrs({
  src: `${profileExchangeDiscountIcon}`,
})`
  width: 40px;
  height: 40px;
`;

export const RightArrowIcon = styled.img.attrs({
  src: `${rightArrowIcon}`,
})`
  width: 16px;
  height: 16px;
`;
