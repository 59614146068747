import React from "react";

import { Container, PaperIcon } from "./styles";
import { ShowTranslation, Icon } from "../../components";

import useSettings from "../../common/hooks/useSettings";
import useModeColors from "../../common/hooks/useModeColors";

import getTranslation from "../../common/utils/getTranslation";

interface IProps {
  backgroundType?: "white" | "gray";
  text?: string | string[];
  subtractHeight?: number;
}

const EmptyList = ({
  backgroundType = "white",
  text,
  subtractHeight,
}: IProps) => {
  const { displayMode } = useSettings();
  const modeColors = useModeColors();

  return (
    <Container backgroundType={backgroundType} subtractHeight={subtractHeight}>
      <Icon
        name={
          displayMode === "dark"
            ? "ic_graphic_paper_filled_dark"
            : "ic_graphic_paper_filled_light"
        }
        size={60}
      />
      <div style={{ height: 17 }} />
      <ShowTranslation size={12} weight={"r"} color={modeColors.text_02}>
        {text ? text : getTranslation("Empty.defaultText")}
      </ShowTranslation>
    </Container>
  );
};

EmptyList.defaultProps = {
  text: "",
};

export default EmptyList;
