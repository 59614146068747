import styled from "styled-components";

import { ShowTranslation } from "../../components";

import colors from "../../styles/colors";
import fontWeight from "../../styles/fontWeights";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 20px;
  padding-right: 20px;
`;

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const VerificationWrapper = styled.div`
  display: flex;
  flex-direction: row;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

export const Button = styled.button`
  display: flex;
  background-color: ${(props: { isValid?: boolean }) =>
    props.isValid ? colors.Blue700 : colors.Gray400};
  height: 40px;
  align-items: center;
  padding-right: 8px;
  padding-left: 8px;
  border-radius: 8px;
  justify-content: center;
`;

export const StyledInput = styled.input`
  display: flex;
  border: none;
  outline: none;
  resize: none;
  flex-grow: 1;
  height: 40px;
  font-size: 15px;
  line-height: 21px;
  overflow-y: scroll;
  padding: 4px;
  color: ${colors.Gray900};
  margin-bottom: 10px;
  border: 1px solid ${colors.Gray500};
  border-radius: 8px;

  ::placeholder {
    font-family: inherit;
    font-size: 15px;
    line-height: 21px;
    font-weight: ${fontWeight.regular};
    color: ${colors.Gray500};
  }
`;

export const DropdownWrapper = styled.div`
  align-self: flex-start;
  margin-bottom: 10px;
  margin-top: 10px;
`;

export const DropdownItem = styled.div`
  display: flex;
  align-items: center;
  background-color: ${colors.CNTMidGreen};
  border-radius: 4px;
  border: 1px solid rgba(0, 219, 199, 0.16);
  padding: 5px 6px 5px 6px;
  min-width: 105px;
`;

export const DropdownText = styled(ShowTranslation).attrs({
  size: 12,
  weight: "m",
  color: colors.CNTDarkGreen,
})`
  margin-left: 4px;
  margin-right: 4px;
`;
