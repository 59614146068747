import { ChangeEvent, useEffect, useState, useRef } from "react";
import { debounce } from "lodash";

import {
  SoundPlayer,
  ShowTranslation,
  CommonHeader,
  Icon,
} from "../../components";

import {
  Wrapper,
  WriteSetupWrapper,
  UploadWrapper,
  ContentsWrapper,
  StyledTextarea,
  ImageCancelButtonWrapper,
  AudioCancelButtonWrapper,
  UploadImageWrapper,
  UploadImage,
  UploadImageFile,
  MarginRightDiv,
  GifWrapper,
  Gif,
  GifFile,
  GifWatermark,
  SoundPlayerWrapper,
} from "./styles";

import getTranslation from "../../common/utils/getTranslation";

import { setSelectedTab } from "../../features/feedList/feedListSlice";

import { createFeed } from "../../features/feedWrite/feedWriteThunks";
import { useAppDispatch } from "../../common/hooks/reduxHooks";
import { useNavigate } from "react-router-dom";
import useInput from "../../common/hooks/useInput";

import useModal from "../../common/hooks/useModal";
import useModeColors from "../../common/hooks/useModeColors";
import useUserControl from "../../common/hooks/useUserControl";
import { unwrapResult } from "@reduxjs/toolkit";
import { TwoButtonModal, OneButtonModal } from "../../components";
import { UserControlModal } from "../../systems";

import colors from "../../styles/colors";

import { MessageFromAppCallbacks } from "../../common/utils/ReactNativeWebview/appEventListenerService/types";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";

const PUBLIC = "PUBLIC";

export type FileType = "IMAGE" | "AUDIO" | "GIF" | "NORMAL";

export type Files = Array<{ url: string }>;

const inputValidator = (text: string) => {
  if (text.trim().length >= 5) {
    return true;
  }
  return false;
};

const FeedWrite = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { getUserControlType } = useUserControl();

  const {
    value: text,
    onChangeText,
    isValidInput,
  } = useInput({
    initialValue: "",
    validator: inputValidator,
  });

  const {
    isVisible: isUserControlModalVisible,
    showModal: showUserControlModal,
    closeModal: closeUserControlModal,
  } = useModal();

  const {
    isVisible: isCancelWriteFeedModalVisible,
    showModal: showCancelWriteFeedModal,
    closeModal: closeCancelWriteFeedModal,
  } = useModal();

  const {
    isVisible: isWriteErrorModalVisible,
    showModal: showWriteErrorModal,
    closeModal: closeWriteErrorModal,
  } = useModal();

  const [files, setFiles] = useState<Files>([]);
  const [fileType, setFileType] = useState<FileType>("NORMAL");
  const [audioDuration, setAudioDuration] = useState(0);
  const [loading, setLoading] = useState<"NONE" | "UPLOADING">("NONE");
  const modeColors = useModeColors();

  const onChangeTextArea = (event: ChangeEvent<HTMLTextAreaElement>) => {
    onChangeText(event.target.value);
  };

  const deleteSelectedImage = (index: number) => {
    setFiles(prevImages => {
      const updatedImages = [...prevImages];
      updatedImages.splice(index, 1);

      if (updatedImages.length === 0) {
        setFileType("NORMAL");
      }
      return updatedImages;
    });
  };

  const deleteAudio = () => {
    setFiles([]);
    setAudioDuration(0);
    setFileType("NORMAL");
  };

  const deleteGif = () => {
    setFiles([]);
    setFileType("NORMAL");
  };

  const scrollToBottom = () => () => {
    let docScrollingElement = document.scrollingElement || document.body;
    docScrollingElement.scrollTop = docScrollingElement.scrollHeight;
  };

  const navigateToMain = () => {
    navigate(-1);
  };

  const onClickUpload = (fileType: FileType) => {
    setFileType(fileType);
    ReactNativeWebview.postMessageToApp({
      type: "REQUEST_FILE_UPLOAD",
      data: {
        fileType: fileType,
      },
    });
  };

  const writeFeed = async () => {
    if (loading === "UPLOADING") {
      return;
    }

    if (isValidInput || files.length > 0) {
      setLoading("UPLOADING");
      ReactNativeWebview.postMessageToApp({
        type: "SHOW_LOADING_INDICATOR",
        data: {
          isLoading: true,
        },
      });

      try {
        const writeResponse = await dispatch(
          createFeed({
            text: text,
            access: PUBLIC,
            contentType: files.length === 0 ? "NORMAL" : fileType,
            contentURL: files,
            contentDuration: audioDuration,
          })
        );

        ReactNativeWebview.postMessageToApp({
          type: "LOG_WROTE_FEED",
          data: {
            content: fileType,
          },
        });

        const unwrappedWriteResponse = unwrapResult(writeResponse);

        if (unwrappedWriteResponse.res_code === "SUCCESS_CREATE_FEED_POST") {
          setTimeout(() => {
            setLoading("NONE");
            ReactNativeWebview.postMessageToApp({
              type: "SHOW_LOADING_INDICATOR",
              data: {
                isLoading: false,
              },
            });

            dispatch(setSelectedTab("LATEST"));
            navigateToMain();
          }, 500);
        } else {
          setLoading("NONE");
        }
      } catch (error: any) {
        setLoading("NONE");
        ReactNativeWebview.postMessageToApp({
          type: "SHOW_LOADING_INDICATOR",
          data: {
            isLoading: false,
          },
        });
        if (error === "BLACK_TYPE_USER") {
          ReactNativeWebview.postMessageToApp({
            type: "SHOW_ACCOUNT_SUSPENDED_MODAL",
          });
          return;
        }
        showWriteErrorModal();
      }
    }
  };

  const onClickWriteButton = debounce(() => {
    if (
      getUserControlType() === "PAUSE" ||
      getUserControlType() === "WARNING" ||
      getUserControlType() === "FIRST_WARNING"
    ) {
      showUserControlModal();
      return;
    }
    writeFeed();
  }, 300);

  const onCloseUserControlModal = () => {
    if (getUserControlType() === "PAUSE") {
      closeUserControlModal();
      return;
    }
    closeUserControlModal();
    writeFeed();
  };

  useEffect(() => {
    const handleDeliveredFiles: MessageFromAppCallbacks["DELIVER_UPLOADED_FILES"] =
      data => {
        if (fileType === "GIF") {
          setFiles([{ url: data.files[0].url }]);
          return;
        }

        if (fileType === "AUDIO") {
          setFiles([{ url: data.files[0].url }]);
          setAudioDuration(data.files[0].duration ? data.files[0].duration : 0);
          return;
        }

        if (fileType === "IMAGE") {
          setFiles(data.files);
          return;
        }
      };

    ReactNativeWebview.addAppEventListener(
      "DELIVER_UPLOADED_FILES",
      handleDeliveredFiles
    );

    return () => {
      ReactNativeWebview.removeAppEventListener(
        "DELIVER_UPLOADED_FILES",
        handleDeliveredFiles
      );
    };
  }, [fileType]);

  const renderWriteSetup = () => {
    return (
      <WriteSetupWrapper>
        <UploadWrapper>
          {files.length > 0 ||
          !ReactNativeWebview.isReactNativeWebviewAvailable ? (
            <Icon
              name={"ic_image_line_regular"}
              size={20}
              color={modeColors.background_05}
            />
          ) : (
            <Icon
              name={"ic_image_line_regular"}
              size={20}
              onClick={() => onClickUpload("IMAGE")}
              color={modeColors.background_04}
            />
          )}
          <div style={{ width: 18 }} />
          {files.length > 0 ||
          !ReactNativeWebview.isReactNativeWebviewAvailable ? (
            <Icon
              name={"ic_mic_filled"}
              size={20}
              color={modeColors.background_05}
            />
          ) : (
            <Icon
              name={"ic_mic_filled"}
              size={20}
              onClick={() => onClickUpload("AUDIO")}
              color={modeColors.background_04}
            />
          )}
        </UploadWrapper>
      </WriteSetupWrapper>
    );
  };

  const renderContents = () => {
    return (
      <ContentsWrapper>
        <StyledTextarea
          autoFocus
          minRows={1}
          value={text}
          onChange={onChangeTextArea}
          onHeightChange={scrollToBottom}
          spellCheck="false"
          placeholder={getTranslation("FeedWrite.placeHolder") as string}
        />
      </ContentsWrapper>
    );
  };

  const renderFiles = () => {
    const renderImages = () => {
      if (fileType === "IMAGE" && files.length > 0) {
        return (
          <UploadImageWrapper>
            {files.map((image, index: number) => (
              <UploadImage key={`upload-image-id-${image.url}`}>
                <ImageCancelButtonWrapper>
                  <Icon
                    name={"ic_graphic_closecircle01_filled"}
                    size={24}
                    onClick={() => deleteSelectedImage(index)}
                  />
                </ImageCancelButtonWrapper>
                <UploadImageFile src={image.url} />
              </UploadImage>
            ))}
            <MarginRightDiv />
          </UploadImageWrapper>
        );
      }
    };
    const renderGif = () => {
      if (fileType === "GIF" && files.length > 0) {
        return (
          <GifWrapper>
            <Gif>
              <Icon
                name={"ic_graphic_closecircle01_filled"}
                size={24}
                onClick={deleteGif}
              />

              <GifFile src={files[0].url} />
            </Gif>
            <GifWatermark />
          </GifWrapper>
        );
      }
    };

    const renderSoundPlayer = () => {
      if (fileType === "AUDIO" && files.length > 0) {
        return (
          <SoundPlayerWrapper>
            <AudioCancelButtonWrapper>
              <Icon
                name={"ic_graphic_closecircle02_filled"}
                size={18}
                onClick={deleteAudio}
              />
            </AudioCancelButtonWrapper>

            <SoundPlayer
              type={"FULL"}
              audioUrl={files[0].url}
              initDuration={audioDuration}
            />
          </SoundPlayerWrapper>
        );
      }
    };

    return (
      <>
        {renderImages()}
        {renderSoundPlayer()}
        {renderGif()}
      </>
    );
  };

  return (
    <Wrapper>
      <TwoButtonModal
        title={getTranslation("FeedWrite.wantToCancelWrite") as string}
        content={getTranslation("FeedWrite.doesNotSaveContent") as string}
        action={getTranslation("FeedWrite.continueWrite") as string}
        secondAction={getTranslation("FeedWrite.cancelWrite") as string}
        isVisible={isCancelWriteFeedModalVisible}
        onAction={closeCancelWriteFeedModal}
        onSecondAction={navigateToMain}
        onClose={closeCancelWriteFeedModal}
        canBackdropPress={true}
      />

      <OneButtonModal
        title={getTranslation("FeedWrite.writeFailed") as string}
        isVisible={isWriteErrorModalVisible}
        onAction={closeWriteErrorModal}
        onClose={closeWriteErrorModal}
        action={getTranslation("Common.ok") as string}
        canBackdropPress={true}
      />

      <UserControlModal
        isVisible={isUserControlModalVisible}
        onClose={onCloseUserControlModal}
      />

      <CommonHeader
        headerLeft="close"
        headerTitle={getTranslation("FeedWrite.headerTitle") as string}
        headerRight={
          <ShowTranslation
            style={{
              backgroundColor:
                isValidInput || files.length > 0
                  ? colors.Blue700
                  : colors.Gray300,
              borderRadius: 14,
              paddingRight: "12px",
              paddingLeft: "12px",
              alignItems: "center",
              justifyContent: "center",
              height: "28px",
            }}
            size={15}
            weight={"b"}
            color={colors.White}
          >
            {getTranslation("FeedWrite.write")}
          </ShowTranslation>
        }
        onClickLeft={showCancelWriteFeedModal}
        onClickRight={onClickWriteButton}
      />
      {renderWriteSetup()}
      {renderContents()}
      {renderFiles()}
    </Wrapper>
  );
};

export default FeedWrite;
