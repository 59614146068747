import { OneButtonModal } from "../../components";
import { getYearMonthDayTime } from "../../common/utils/timeFormatters";
import useUserControl from "../../common/hooks/useUserControl";
import getTranslation from "../../common/utils/getTranslation";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import { ReportKey } from "../../features/report/reportSlice";
import { selectReportItemDescription } from "../../features/report/reportSlice";
interface IProps {
  isVisible: boolean;
  onClose: () => void;
}

const UserControlModal = ({ isVisible, onClose }: IProps) => {
  const reportItemDescription = useAppSelector(selectReportItemDescription);
  const {
    getUserControlType,
    getControlOff,
    getReasonKey,
    checkUserControlWarning,
    getUserControl,
    getIsAutomation,
  } = useUserControl();

  const checkOffWarningControl = async () => {
    if (
      getUserControlType() === "WARNING" ||
      getUserControlType() === "FIRST_WARNING"
    ) {
      await checkUserControlWarning();
    }
  };

  const onCloseModal = async () => {
    checkOffWarningControl();
    getUserControl("FEED");
    onClose();
    return;
  };

  const getTitle = () => {
    const timeFormat = getYearMonthDayTime(getControlOff());

    if (getIsAutomation()) {
      return getTranslation("UserControlModal.automationControlTitle", {
        "{n}": timeFormat,
      });
    }

    if (getUserControlType() === "PAUSE" && getReasonKey() !== "NONE") {
      return getTranslation(`UserControlModal.pauseWarningTitle`, {
        "{n1}": reportItemDescription[getReasonKey() as ReportKey],
        "{n2}": timeFormat,
      });
    }

    if (
      (getUserControlType() === "WARNING" ||
        getUserControlType() === "FIRST_WARNING") &&
      getReasonKey() !== "NONE"
    ) {
      return getTranslation(`UserControlModal.receivedReportReason`, {
        "{n}": reportItemDescription[getReasonKey() as ReportKey],
      });
    }

    return "";
  };

  const getContent = () => {
    if (getIsAutomation()) {
      return getTranslation("UserControlModal.automationControlMessage");
    }

    if (getUserControlType() === "FIRST_WARNING") {
      return getTranslation("UserControlModal.firstWarning");
    }

    if (getUserControlType() === "PAUSE") {
      return getTranslation(`UserControlModal.pauseWarningMessage`);
    }

    if (getUserControlType() === "WARNING") {
      return getTranslation(`UserControlModal.warningControlContent`);
    }

    return "";
  };

  return (
    <OneButtonModal
      isVisible={isVisible}
      title={getTitle() as string}
      content={getContent() as string}
      action={getTranslation("UserControlModal.confirm") as string}
      onAction={onCloseModal}
    />
  );
};

export default UserControlModal;
