import styled from "styled-components";
import { ImageSourceButton } from "../../styles/commonStyles";

import colors from "../../styles/colors";
import fontWeight from "../../styles/fontWeights";

import followersIcon from "../../assets/images/ic24FollowLineGray900@3x.png";
import alarmIcon from "../../assets/images/img_bell_line_regular@3x.png";
import plusIcon from "../../assets/images/ic14AddWhite@3x.png";

import { Link } from "react-router-dom";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.modeColors.background_01};
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  height: 48px;
  align-items: center;
  justify-content: space-between;
  padding: 0px 20px 0px 20px;
  z-index: 100;
  background-color: ${({ theme }) => theme.modeColors.background_01};
`;

export const HeaderButtonWrapper = styled.div`
  display: flex;
`;

export const AlarmButton = styled(ImageSourceButton).attrs({
  source: alarmIcon,
  width: 24,
  height: 24,
})``;

export const RedDot = styled.div`
  position: absolute;
  display: ${(props: { isVisible?: boolean | null }) =>
    props.isVisible ? `flex` : `none`};
  border-radius: ${5 / 2}px;
  right: 16px;
  height: 5px;
  width: 5px;
  background-color: ${colors.notiRed};
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 0px;
  z-index: 100;
`;

export const FollowersButton = styled(ImageSourceButton).attrs({
  source: followersIcon,
  width: 24,
  height: 24,
})`
  margin-right: 20px;
`;

export const PlusIcon = styled.img.attrs({
  src: plusIcon,
})`
  height: 14px;
  width: 14px;
  margin-right: 7px;
`;

export const LoadingMoreIndicatorWrapper = styled.div`
  position: absolute;
  bottom: 30px;
  z-index: 1;
  background-color: ${({ theme }) => theme.modeColors.background_01};
  width: 100vw;
`;

export const WriteFeedButton = styled.div`
  text-decoration: none;
  display: flex;
  color: ${colors.White};
  font-weight: ${fontWeight.bold};
  padding: 0px 20px;
  height: 47px;
  border-radius: 25px;
  align-items: center;
  justify-content: center;
  z-index: 10;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: ${colors.Blue700};
`;

export const GoMypageButton = styled(Link).attrs({ source: followersIcon })`
  text-decoration: none;
  background-color: ${colors.Blue700};
  color: ${colors.White};
  font-weight: ${fontWeight.bold};
  padding: 14px 20px;
  border-radius: 25px;
  align-items: center;
  z-index: 10;
  position: fixed;
  bottom: 80px;
  left: 20px;
`;

export const WriteFeedNoticeButton = styled(Link).attrs({
  source: followersIcon,
})`
  text-decoration: none;
  background-color: ${colors.linkBlue};
  color: ${colors.White};
  font-weight: ${fontWeight.bold};
  padding: 14px 20px;
  border-radius: 25px;
  align-items: center;
  z-index: 10;
  position: fixed;
  bottom: 80px;
  right: 20px;
`;
