import React from "react";

import { ModalProvider } from "styled-react-modal";
import {
  Action,
  ActionButton,
  Message,
  ModalBackground,
  StyledModal,
} from "./styles";
import ShowTranslation from "../ShowTranslation";
import getTranslation from "../../common/utils/getTranslation";

interface IProp {
  isVisible: boolean;
  onClose: () => void;
  message: string | string[];
  action?: string;
}

const AlertModal = ({ isVisible, onClose, message, action }: IProp) => {
  return (
    <ModalProvider backgroundComponent={ModalBackground}>
      <StyledModal allowScroll={false} isOpen={isVisible}>
        <Message>
          <ShowTranslation>{message}</ShowTranslation>
        </Message>

        <ActionButton onClick={onClose}>
          <Action>{action ? action : getTranslation("Common.ok")}</Action>
        </ActionButton>
      </StyledModal>
    </ModalProvider>
  );
};

export default AlertModal;
