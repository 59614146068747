import React, { MouseEvent } from "react";
import ReactDOMServer from "react-dom/server";
import basicIcons from "../../assets/svgs/basic";
import graphicIcons from "../../assets/svgs/graphic";
import useModeColors from "../../common/hooks/useModeColors";

type GraphicIconName = keyof typeof graphicIcons;
type BasicIconName = keyof typeof basicIcons;

type IconName = BasicIconName | GraphicIconName;

interface IconProps {
  name: IconName;
  size?: number;
  width?: number;
  height?: number;
  color?: string;
  onClick?: (event: MouseEvent<HTMLButtonElement>) => void;
}

function _isBasicIcon(name: IconName): name is BasicIconName {
  return name in basicIcons;
}

export { _isBasicIcon };

const Icon = ({ name, size, width, height, color, onClick }: IconProps) => {
  const modeColors = useModeColors();

  // Determine if the icon is from basicIcons or graphicIcons
  const IconComponent = _isBasicIcon(name)
    ? basicIcons[name]
    : graphicIcons[name as GraphicIconName];

  if (!IconComponent) {
    console.error(
      `Icon "${name}" does not exist in basicIcons or graphicIcons.`
    );
    return null;
  }

  // Set icon color, falling back to the theme color if it's a basic icon
  const iconColor = _isBasicIcon(name)
    ? color || modeColors.background_04
    : color;

  let modifiedSvgString = "";

  if (_isBasicIcon(name)) {
    // Render the SVG to a string and replace `fill="#222222"` with `fill="currentColor"`
    const svgString = ReactDOMServer.renderToString(
      <IconComponent
        width={width || size}
        height={height || size}
        fill={iconColor}
        style={{ display: "flex" }}
      />
    );
    modifiedSvgString = svgString.replace(
      /fill="#222222"/g,
      'fill="currentColor"'
    );
  }

  return (
    <button
      onClick={onClick}
      style={{
        background: "none",
        border: "none",
        padding: 0,
        display: "flex",
      }}
    >
      {_isBasicIcon(name) ? (
        <span
          style={{
            color: iconColor,
          }}
          dangerouslySetInnerHTML={{ __html: modifiedSvgString }}
        />
      ) : (
        <IconComponent
          width={width || size}
          height={height || size}
          style={{
            display: "flex",
          }}
        />
      )}
    </button>
  );
};

export default Icon;
