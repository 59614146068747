import { useState, useEffect } from "react";

const useInput = ({
  initialValue,
  validator,
  isTrim,
}: {
  initialValue?: string;
  validator?: (text: string) => boolean;
  isTrim?: boolean;
} = {}) => {
  const [value, setValue] = useState<string>(initialValue ? initialValue : "");

  const [isValidInput, setIsValidInput] = useState<boolean>(false);

  const onChangeText = (text: string) => {
    let input = text.trimStart();

    if (isTrim) {
      input = text.trim();
    }

    if (typeof validator === "function") {
      setIsValidInput(validator(input));
    }

    setValue(input);
  };

  const onEndEditing = () => {
    setValue(value.trim());
  };

  const resetValue = () => {
    setValue("");
    setIsValidInput(false);
  };

  const length = value.length;

  return {
    value,
    onChangeText,
    isValidInput,
    length,
    onEndEditing,
    setValue,
    resetValue,
  };
};

export default useInput;
