import React, { useState, useEffect } from "react";
import { ModalProvider } from "styled-react-modal";

import getTranslation from "../../common/utils/getTranslation";

import { Container, StyledModal, ModalBackground } from "./styles";
import { ShowTranslation, Icon } from "../../components";

import { getMonthDayTime } from "../../common/utils/timeFormatters";
import useServerMaintenance from "../../common/hooks/useServerMaintenance";
import useAuth from "../../common/hooks/useAuth";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";
import useModeColors from "../../common/hooks/useModeColors";
import useSettings from "../../common/hooks/useSettings";

const ServerMaintenanceModal = () => {
  const [isVisible, setIsVisible] = useState(false);
  const modeColors = useModeColors();
  const { maintenanceStartTime, maintenanceEndTime, isMaintenance } =
    useServerMaintenance();

  const { userData } = useAuth();
  const { displayMode } = useSettings();

  useEffect(() => {
    //서버 점검중일경우 점검 모달 뛰움
    if (isMaintenance) {
      setIsVisible(true);
      ReactNativeWebview.postMessageToApp({ type: "SHOW_APP_HEADER" });
    }

    if (
      isMaintenance &&
      "account_type" in userData &&
      userData.account_type === "QA"
    ) {
      setIsVisible(false);
    }
  }, [isMaintenance, userData]);

  return (
    <ModalProvider backgroundComponent={ModalBackground}>
      <StyledModal isOpen={isVisible}>
        <Container>
          <Icon
            name={
              displayMode === "dark"
                ? "ic_graphic_corn_filled_dark"
                : "ic_graphic_corn_filled_light"
            }
            size={60}
          />
          <div style={{ height: 17 }} />

          <ShowTranslation
            style={{ marginBottom: "8px" }}
            weight={"b"}
            size={17}
            color={modeColors.text_02}
          >
            {getTranslation("ServerMaintenanceModal.title")}
          </ShowTranslation>

          <ShowTranslation
            style={{ marginBottom: "3px" }}
            weight={"r"}
            size={13}
            color={modeColors.text_02}
          >
            {getTranslation("ServerMaintenanceModal.time", {
              "{t}": getMonthDayTime(maintenanceStartTime),
              "{t2}": getMonthDayTime(maintenanceEndTime),
            })}
          </ShowTranslation>

          <ShowTranslation weight={"r"} size={12} color={modeColors.text_02}>
            {getTranslation("ServerMaintenanceModal.message")}
          </ShowTranslation>

          {/* 임시적으로 message2 사용 중 나중에 다시 message 로 사용 */}

          {/* <ShowTranslation weight={"r"} size={12} color={colors.Gray500}>
            {getTranslation("ServerMaintenanceModal.message2")}
          </ShowTranslation> */}
        </Container>
      </StyledModal>
    </ModalProvider>
  );
};

export default ServerMaintenanceModal;
