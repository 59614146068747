import i18n from "../../translation/i18n";

type placeholders = {
  [key: string]: any;
};

const curlyBrackets = "{}";
const emptyString = "";

const replacePlaceholderWithValue = (
  text: string,
  placeholders: placeholders
) => {
  let replacedText = text;

  for (const value of Object.keys(placeholders)) {
    if (placeholders.hasOwnProperty(value)) {
      replacedText = replacedText.split(value).join(placeholders[value]);
    }
  }

  if (replacedText.includes(curlyBrackets)) {
    const bracketsIncluded = replacedText.split(curlyBrackets);
    if (bracketsIncluded.includes(emptyString)) {
      return bracketsIncluded.filter(string => string !== emptyString);
    } else {
      return bracketsIncluded;
    }
  }
  return replacedText;
};

const getTranslation = (
  translationKey: string,
  placeholders?: placeholders
) => {
  const text = i18n.t(translationKey);

  if (placeholders) {
    return replacePlaceholderWithValue(text, placeholders);
  } else {
    if (text.includes(curlyBrackets)) {
      return text.split(curlyBrackets);
    }
    return text;
  }
};

export default getTranslation;
