import React, { useEffect, useState, ChangeEvent } from "react";

import {
  StyledInput,
  Container,
  Button,
  Form,
  Wrapper,
  DropdownWrapper,
  DropdownItem,
  DropdownText,
  VerificationWrapper,
} from "./styles";

import { useNavigate } from "react-router-dom";

import { ShowTranslation } from "../../components";

import useAuth from "../../common/hooks/useAuth";

import useInput from "../../common/hooks/useInput";

import { configureAxiosUuidHeader } from "../../common/utils/axios";

import colors from "../../styles/colors";

const validator = (value: string) => {
  if (value.length > 0) {
    return true;
  }
  return false;
};

const phoneValidator = (value: string) => {
  if (isNaN(Number(value))) {
    return false;
  }

  if (value.length > 10) {
    return true;
  }

  return false;
};

const Login = () => {
  const navigate = useNavigate();

  const [loginType, setLoginType] = useState<"EMAIL" | "SMS">("EMAIL");
  const [isShowDropdown, setIsShowDropdown] = useState(false);
  const [isSMSSent, setIsSMSSent] = useState(false);
  const [isUuidAxiosHeaderConfigured, setIsUuidAxiosHeaderConfigured] =
    useState(false);

  const {
    loginUserByEmail,
    isAuthorized,
    getSMSAuthorizationCode,
    verifyAndLoginUserBySMS,
    userData,
  } = useAuth();

  const {
    value: email,
    isValidInput: isValidEmail,
    onChangeText: onChangeEmail,
  } = useInput({
    initialValue: "",
    validator,
  });
  const {
    value: password,
    isValidInput: isValidPassword,
    onChangeText: onChangePassword,
  } = useInput({
    initialValue: "",
    validator,
  });

  const {
    value: phoneNumber,
    isValidInput: isValidPhoneNumber,
    onChangeText: onChangePhoneNumber,
  } = useInput({
    initialValue: "",
    isTrim: true,
    validator: phoneValidator,
  });

  const {
    value: authCode,
    isValidInput: isValidAuthCode,
    onChangeText: onChangeAuthCode,
  } = useInput({
    initialValue: "",
    isTrim: true,
    validator,
  });

  const getSMSCode = async () => {
    const result = await getSMSAuthorizationCode(phoneNumber);
    if (result === "SUCCESS") {
      setIsSMSSent(true);
    }
  };

  const onChangeEmailInput = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeEmail(event.target.value);
  };

  const onChangePasswordInput = (event: ChangeEvent<HTMLInputElement>) => {
    onChangePassword(event.target.value);
  };

  const onChangePhoneNumberInput = (event: ChangeEvent<HTMLInputElement>) => {
    onChangePhoneNumber(event.target.value);
  };

  const onChangeAuthCodeInput = (event: ChangeEvent<HTMLInputElement>) => {
    onChangeAuthCode(event.target.value);
  };

  const onClickLogin = () => {
    if (loginType === "EMAIL") {
      loginUserByEmail({ email, password });
    }

    if (loginType === "SMS") {
      verifyAndLoginUserBySMS({ phoneNumber, authCode });
    }
  };

  const onClickVerifySMS = () => {
    getSMSCode();
  };

  const onClickToggleDropdown = () => {
    setIsShowDropdown(!isShowDropdown);
  };

  const onClickLoginType = () => {
    if (loginType === "EMAIL") {
      setLoginType("SMS");
    } else {
      setLoginType("EMAIL");
    }

    setIsShowDropdown(false);
  };

  const onKeyPress = (e: any) => {
    if (e.key === "Enter") {
      onClickLogin();
    }
  };

  useEffect(() => {
    if (isAuthorized) {
      configureAxiosUuidHeader(userData.uuid);
      setIsUuidAxiosHeaderConfigured(true);
    }
  }, [isAuthorized, userData]);

  useEffect(() => {
    if (isUuidAxiosHeaderConfigured) {
      navigate("/", { replace: true });
    }
  }, [isUuidAxiosHeaderConfigured]);

  const renderDropdown = () => {
    return (
      <DropdownWrapper>
        <DropdownItem onClick={onClickToggleDropdown}>
          <DropdownText>
            {loginType === "EMAIL" ? "이메일 로그인" : "SMS 로그린"}
          </DropdownText>
        </DropdownItem>
        {isShowDropdown && (
          <DropdownItem onClick={onClickLoginType}>
            <DropdownText>
              {loginType === "SMS" ? "이메일 로그인" : "SMS 로그인"}
            </DropdownText>
          </DropdownItem>
        )}
      </DropdownWrapper>
    );
  };

  const renderEmailLogin = () => {
    return (
      <Wrapper>
        <Form>
          <StyledInput
            value={email}
            onChange={onChangeEmailInput}
            onKeyPress={onKeyPress}
            type="email"
            placeholder={"이메일"}
          />
          <StyledInput
            value={password}
            onChange={onChangePasswordInput}
            onKeyPress={onKeyPress}
            type="password"
            placeholder={"비밀번호"}
          />
        </Form>

        <Button
          disabled={!isValidEmail || !isValidPassword}
          isValid={isValidEmail && isValidPassword}
          onClick={onClickLogin}
        >
          <ShowTranslation size={15} weight={"b"} color={colors.White}>
            로그인
          </ShowTranslation>
        </Button>
      </Wrapper>
    );
  };

  const renderSMSLogin = () => {
    return (
      <Wrapper>
        <Form>
          <StyledInput
            onChange={onChangePhoneNumberInput}
            value={phoneNumber}
            type="tel"
            placeholder={"전화번호"}
            maxLength={11}
            disabled={isSMSSent}
          />
        </Form>

        <Button
          style={{ marginBottom: 10 }}
          disabled={!isValidPhoneNumber || isSMSSent}
          isValid={isValidPhoneNumber}
          onClick={onClickVerifySMS}
        >
          <ShowTranslation size={15} weight={"b"} color={colors.White}>
            인증하기
          </ShowTranslation>
        </Button>

        <Form>
          <StyledInput
            value={authCode}
            onChange={onChangeAuthCodeInput}
            type="number"
            placeholder={"인증번호"}
          />
        </Form>

        <Button
          disabled={!isValidAuthCode}
          isValid={isValidAuthCode}
          onClick={onClickLogin}
        >
          <ShowTranslation size={15} weight={"b"} color={colors.White}>
            로그인
          </ShowTranslation>
        </Button>
      </Wrapper>
    );
  };

  return (
    <Container>
      <ShowTranslation style={{ marginBottom: 10 }} size={24} weight={"b"}>
        로그인
      </ShowTranslation>

      {renderDropdown()}

      {loginType === "EMAIL" ? renderEmailLogin() : renderSMSLogin()}
    </Container>
  );
};

export default Login;
