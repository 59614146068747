import styled from "styled-components";
import colors from "../../styles/colors";
import fontWeight from "../../styles/fontWeights";
import { DefaultTheme } from "styled-components";
interface IProps {
  height?: number;
  isSelected?: boolean;
  theme: DefaultTheme;
}

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${({ theme }) => theme.modeColors.background_01};
  height: ${(props: IProps) => `${props.height}px`};
`;

export const LeftTabWrapper = styled.div`
  height: ${(props: IProps) => `${props.height}px`};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${(props: IProps) =>
    props.isSelected
      ? props.theme.modeColors.background_04
      : props.theme.modeColors.background_05};
`;

export const LeftTab = styled.div`
  font-size: 15px;
  font-weight: ${(props: IProps) =>
    props.isSelected ? fontWeight.bold : fontWeight.medium};
  color: ${(props: IProps) =>
    props.isSelected
      ? props.theme.modeColors.text_01
      : props.theme.modeColors.text_04};
`;

export const RightTabWrapper = styled.div`
  height: ${(props: IProps) => `${props.height}px`};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${(props: IProps) =>
    props.isSelected
      ? props.theme.modeColors.background_04
      : props.theme.modeColors.background_05};
`;

export const RightTab = styled.div`
  font-size: 15px;
  font-weight: ${(props: IProps) =>
    props.isSelected ? fontWeight.bold : fontWeight.medium};
  color: ${(props: IProps) =>
    props.isSelected
      ? props.theme.modeColors.text_01
      : props.theme.modeColors.text_04};
`;

export const MiddleTabWrapper = styled.div`
  height: ${(props: IProps) => `${props.height}px`};
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  border-bottom-width: 1px;
  border-bottom-style: solid;
  border-bottom-color: ${(props: IProps) =>
    props.isSelected
      ? props.theme.modeColors.background_04
      : props.theme.modeColors.background_05};
`;

export const MiddleTab = styled.div`
  font-size: 15px;
  font-weight: ${(props: IProps) =>
    props.isSelected ? fontWeight.bold : fontWeight.medium};
  color: ${(props: IProps) =>
    props.isSelected
      ? props.theme.modeColors.text_01
      : props.theme.modeColors.text_04};
`;
