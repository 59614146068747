export type AudioStatus =
  | "PLAY"
  | "PAUSE"
  | "SEEKING"
  | "SEEKED"
  | "ENDED"
  | "DURATION"
  | "CURRENT_TIME";

export type AudioStatusGroup = {
  play: "PLAY";
  pause: "PAUSE";
  ended: "ENDED";
  seeking: "SEEKING";
  seeked: "SEEKED";
  duration: "DURATION";
  currentTime: "CURRENT_TIME";
};

let audio: HTMLAudioElement | undefined = undefined;
let currentSrc: string | undefined = undefined;
let currentCallback = ({
  status,
  duration,
  currentTime,
}: {
  status: AudioStatus;
  duration?: number;
  currentTime?: number;
}) => {};

const AUDIO_STATUS: AudioStatusGroup = {
  play: "PLAY",
  pause: "PAUSE",
  ended: "ENDED",
  seeking: "SEEKING",
  seeked: "SEEKED",
  duration: "DURATION",
  currentTime: "CURRENT_TIME",
};

function startPlayer(
  audioSrc: string,
  callback: ({
    status,
    duration,
    currentTime,
  }: {
    status: AudioStatus;
    duration?: number;
    currentTime?: number;
  }) => {}
) {
  if (audio && audioSrc === currentSrc) {
    //audio instance created playing the same source file
    playPlayer();
    return;
  }

  if (currentSrc === undefined) {
    // there is no source file set
    currentSrc = audioSrc;
    currentCallback = callback;
  }

  if (currentSrc !== audioSrc) {
    // source file is different from current one
    if (audio !== undefined) {
      pausePlayer();
      stopPlayer(); //resets all source and audio instance
    }
    currentSrc = audioSrc;
    currentCallback = callback;
  }

  if (audio === undefined) {
    //audio instance not created
    audio = new Audio(audioSrc);
  }

  audio.addEventListener("play", onAudioPlay);
  audio.addEventListener("pause", onAudioPause);
  audio.addEventListener("ended", onAudioEnded);
  audio.addEventListener("loadeddata", onAudioDataLoaded);
  audio.addEventListener("timeupdate", onAudioTimeUpdate);
  audio.addEventListener("seeking", onAudioSeeking);
  audio.addEventListener("seeked", onAudioSeeked);

  playPlayer();
}

const onAudioPlay = () => {
  currentCallback({ status: AUDIO_STATUS.play });
};

const onAudioPause = () => {
  currentCallback({ status: AUDIO_STATUS.pause });
};

const onAudioEnded = () => {
  currentCallback({ status: AUDIO_STATUS.ended });
  stopPlayer();
};

const onAudioDataLoaded = () => {
  currentCallback({
    status: AUDIO_STATUS.duration,
    duration: getDuration(),
  });
};

const onAudioTimeUpdate = () => {
  currentCallback({
    status: AUDIO_STATUS.currentTime,
    currentTime: getCurrentTime(),
    duration: getDuration(),
  });
};

const onAudioSeeking = () => {
  currentCallback({
    status: AUDIO_STATUS.seeking,
    currentTime: getCurrentTime(),
    duration: getDuration(),
  });
};

const onAudioSeeked = () => {
  currentCallback({
    status: AUDIO_STATUS.seeked,
    currentTime: getCurrentTime(),
    duration: getDuration(),
  });
};

const getDuration = () => {
  if (audio) {
    if (audio.duration > 1) {
      return Math.floor(audio.duration);
    }
    return Math.ceil(audio.duration);
  }
  return 0;
};

const getCurrentTime = () => {
  if (audio) {
    if (audio.currentTime < roundTime(audio.duration)) {
      return roundUp(audio.currentTime);
    }
    return roundDown(audio.currentTime);
  }
  return 0;
};

const playPlayer = () => {
  audio && audio.play();
};

const pausePlayer = () => {
  audio && audio.pause();
};

const seekTo = (seconds: number) => {
  if (audio) {
    audio.currentTime = roundTime(seconds);
  }
};

const roundTime = (time: number) => {
  if (time > 1) {
    return roundDown(time);
  }
  return roundUp(time);
};

const roundUp = (time: number) => {
  return Math.ceil(time);
};

const roundDown = (time: number) => {
  return Math.floor(time);
};

const stopPlayer = () => {
  if (audio) {
    audio.removeEventListener("play", onAudioPlay);
    audio.removeEventListener("pause", onAudioPause);
    audio.removeEventListener("ended", onAudioEnded);
    audio.removeEventListener("loadeddata", onAudioDataLoaded);
    audio.removeEventListener("timeupdate", onAudioTimeUpdate);
    audio.removeEventListener("seeking", onAudioSeeking);
    audio.removeEventListener("seeked", onAudioSeeked);
    audio = undefined;
    currentSrc = undefined;
  }
  currentCallback({ status: AUDIO_STATUS.ended });
};

export {
  AUDIO_STATUS,
  startPlayer,
  stopPlayer,
  playPlayer,
  pausePlayer,
  seekTo,
  roundTime,
};
