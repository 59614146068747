import { useState, useEffect, useCallback } from "react";

const useModal = (initVisible?: boolean) => {
  const [isVisible, setIsVisible] = useState<boolean>(
    initVisible ? initVisible : false
  );

  const showModal = useCallback(() => {
    setIsVisible(true);
  }, []);

  const closeModal = useCallback(() => {
    setIsVisible(false);
  }, []);

  useEffect(() => {
    return () => {
      closeModal();
    };
  }, [closeModal]);

  return { isVisible, showModal, closeModal };
};

export default useModal;
