import dayjs from "dayjs";
import getTranslation from "./getTranslation";

export const getCreatedAtTimes = (time: string | Date) => {
  let minutes = dayjs().diff(time, "minute");

  if (minutes < 1) {
    return getTranslation("FormattedTimes.secondsAgo");
  }
  if (minutes >= 1440) {
    return dayjs(time).format("YYYY. MM. DD");
  }

  if (minutes >= 60) {
    return getTranslation("FormattedTimes.hoursAgo", {
      "{t}": Math.round(Number(minutes / 60)),
    });
  }

  return getTranslation("FormattedTimes.minutesAgo", {
    "{t}": minutes,
  });
};

export const getMonthDayTime = (time: string | Date) => {
  return dayjs(time).format("M. DD HH:mm");
};

// input: "2021-06-29T16:47:50.033438+09:00"
// output: "2021.06.29 16:47"
export const getYearMonthDayTime = (time: string | Date) => {
  return dayjs(time).format("YYYY.MM.DD HH:mm");
};

export const getYearMonthDay = (time: string | Date) => {
  return dayjs(time).format("YYYY.MM.DD");
};

export const getMonthDateDayTime = (time: string | Date) => {
  return dayjs(time).format("MM.DD (dd) HH:mm");
};

export const getTime = (time: string | Date) => {
  return dayjs(time).format("HH:mm");
};

export const convertUTCToKST = (time: string | Date) => {
  const newTime = dayjs(time).format("YYYY-MM-DDTHH:mm:ss.000000+09:00");
  return newTime;
};
