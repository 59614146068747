import React from "react";
import { ThemeProvider as StyledThemeProvider } from "styled-components";

import useSettings from "../../common/hooks/useSettings";

import { lightModeThemes, darkModeThemes } from "../../styles/themes";
const ThemeProvider: React.FC = ({ children }) => {
  const { displayMode } = useSettings();

  return (
    <StyledThemeProvider
      theme={displayMode === "dark" ? darkModeThemes : lightModeThemes}
    >
      {children}
    </StyledThemeProvider>
  );
};

export default ThemeProvider;
