import parseURL from "url-parse";

export const parseNextPageUrl = (nextPageUrl: string) => {
  const parsedNextPageUrl = parseURL(nextPageUrl, true);

  if ("page" in parsedNextPageUrl.query) {
    return Number(parsedNextPageUrl.query.page);
  }
  return 0;
};

export const parseNextFeedId = (nextPageUrl: string) => {
  const parsedNextFeedId = parseURL(nextPageUrl, true);
  if ("page" in parsedNextFeedId.query) {
    return parsedNextFeedId.query.page as string;
  }
  return 0;
};
