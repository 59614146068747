import React, { useState, MouseEvent } from "react";

import {
  Grid,
  OneImage,
  TwoImage,
  ThreeLeftImage,
  ThreeRightImage,
  FourImage,
  FiveImageTop,
  FiveImageBottom,
  FiveLastImageGrid,
  FiveLastImage,
  FiveImageCaption,
} from "./style";

interface IProps {
  images: Array<{ url: string }>;
  onClickImage: (index: number) => void;
}

const ImageGrid: React.FC<IProps> = ({ images, onClickImage }) => {
  const imageBoxWidth = window.innerWidth;

  const [height] = useState<number>(
    imageBoxWidth > 560 ? (560 * 1) / 1.6 : (imageBoxWidth * 1) / 1.6
  );

  const onPressImage = (event: MouseEvent, index: number) => {
    onClickImage(index);
    event.stopPropagation();
  };

  switch (images.length) {
    case 1:
      return (
        <Grid height={height}>
          <OneImage
            src={images[0].url}
            onClick={event => {
              onPressImage(event, 0);
            }}
          />
        </Grid>
      );
    case 2:
      return (
        <Grid height={height}>
          <TwoImage
            src={images[0].url}
            style={{ marginRight: "3px" }}
            onClick={event => {
              onPressImage(event, 0);
            }}
          />
          <TwoImage
            src={images[1].url}
            onClick={event => {
              onPressImage(event, 1);
            }}
          />
        </Grid>
      );
    case 3:
      return (
        <Grid height={height}>
          <ThreeLeftImage
            src={images[0].url}
            onClick={event => {
              onPressImage(event, 0);
            }}
          />
          <ThreeRightImage
            src={images[1].url}
            style={{ float: "right" }}
            onClick={event => {
              onPressImage(event, 1);
            }}
          />
          <ThreeRightImage
            src={images[2].url}
            style={{
              float: "right",
              marginTop:
                imageBoxWidth > 560 ? -178 : -((imageBoxWidth * 1) / 3.19),
            }}
            onClick={event => {
              onPressImage(event, 2);
            }}
          />
        </Grid>
      );
    case 4:
      return (
        <Grid height={height}>
          <FourImage
            src={images[0].url}
            style={{ marginRight: "3px" }}
            onClick={event => {
              onPressImage(event, 0);
            }}
          />
          <FourImage
            src={images[1].url}
            onClick={event => {
              onPressImage(event, 1);
            }}
          />
          <FourImage
            src={images[2].url}
            style={{ marginRight: "3px" }}
            onClick={event => {
              onPressImage(event, 2);
            }}
          />
          <FourImage
            src={images[3].url}
            onClick={event => {
              onPressImage(event, 3);
            }}
          />
        </Grid>
      );
    default:
      return (
        <Grid height={height}>
          <FiveImageTop
            src={images[0].url}
            style={{ marginRight: "2.5px" }}
            onClick={event => {
              onPressImage(event, 0);
            }}
          />
          <FiveImageTop
            src={images[1].url}
            onClick={event => {
              onPressImage(event, 1);
            }}
          />
          <FiveImageBottom
            src={images[2].url}
            style={{ marginRight: "2.5px" }}
            onClick={event => {
              onPressImage(event, 2);
            }}
          />
          <FiveImageBottom
            src={images[3].url}
            style={{ marginRight: "2.5px" }}
            onClick={event => {
              onPressImage(event, 3);
            }}
          />
          {images.length > 5 ? (
            <FiveLastImageGrid>
              <FiveImageCaption>+{images.length - 5}</FiveImageCaption>
              <FiveLastImage
                src={images[4].url}
                onClick={event => {
                  onPressImage(event, 4);
                }}
              />
            </FiveLastImageGrid>
          ) : (
            <FiveImageBottom
              onClick={event => {
                onPressImage(event, 4);
              }}
              src={images[4].url}
            />
          )}
        </Grid>
      );
  }
};

export default ImageGrid;
