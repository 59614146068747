import colors from "./colors";

/**
 * NOTE - 그라디언트 색상을 정의
 *
 * (example)
 * const exampleContainer = styled.div`
 * ...
 * ...
 *   background: ${gradients.Linear_1_01};
 * `;
 */

export const gradients = {
  Linear_1_01: `linear-gradient(to bottom, ${colors.Blue900}, ${colors.Blue700})`,
  Linear_1_02: `linear-gradient(to bottom, ${colors.Orange700}, ${colors.White})`,
  Linear_1_03: `linear-gradient(to bottom, ${colors.Blue700}, ${colors.Blue500})`,
  Linear_1_04: `linear-gradient(to bottom, ${colors.Orange900}, ${colors.Orange700})`,
  Linear_1_05: `linear-gradient(to bottom, ${colors.Purple900}, ${colors.Purple700})`,
  Linear_1_06: `linear-gradient(to bottom, ${colors.Gray900}, ${colors.Gray800})`,
  Linear_2_01: `linear-gradient(to right, ${colors.Blue500}, ${colors.MintNeon})`,
};
