import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  AuthorizeUserPayload,
  IsAuthorized,
  AccountState,
  IsAuthError,
  Loading,
} from "./types";

import { fetchUserData } from "./accountThunks";

const initialState: AccountState = {
  loading: "NONE",
  isAuthorized: false,
  authToken: null,
  userData: {},
  userInventory: {
    itemCounts: {
      PROFILE_EXCHANGE: 0,
    },
  },
  authError: false,
};

export const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<Loading>) => {
      state.loading = action.payload;
    },

    setAuthToken: (state, action: PayloadAction<AuthorizeUserPayload>) => {
      state.authToken = action.payload.authToken;
    },

    setUserData: (state, action: PayloadAction<any>) => {
      state.userData = { ...state.userData, ...action.payload };
    },

    setIsAuthorized: (state, action: PayloadAction<IsAuthorized>) => {
      state.isAuthorized = action.payload;
    },

    setAuthError: (state, action: PayloadAction<IsAuthError>) => {
      state.authError = action.payload;
    },

    setItemCounts: (state, action) => {
      state.userInventory = {
        ...state.userInventory,
        itemCounts: {
          ...state.userInventory.itemCounts,
          ...action.payload,
        },
      };
    },
  },
  extraReducers: (builders) => {
    builders.addCase(fetchUserData.pending, (state, action) => {
      state.loading = "LOADING_USER_DATA";
    });

    builders.addCase(fetchUserData.fulfilled, (state, action) => {
      state.loading = "NONE";
      state.userData = action.payload;
    });

    builders.addCase(fetchUserData.rejected, (state, action) => {
      if (action.payload === "NOT_AUTHORIZED_USER") {
        state.authError = true;
      }
    });
  },
});

export const {
  setUserData,
  setIsAuthorized,
  setAuthToken,
  setAuthError,
  setItemCounts,
} = accountSlice.actions;

export const selectAccountIsLoading = (state: RootState) =>
  state.account.loading;
export const selectAuthToken = (state: RootState) => state.account.authToken;
export const selectIsAuthorized = (state: RootState) =>
  state.account.isAuthorized;
export const selectUserData = (state: RootState) => state.account.userData;
export const selectAuthError = (state: RootState) => state.account.authError;
export const selectUserItemCounts = (state: RootState) =>
  state.account.userInventory.itemCounts;

export default accountSlice.reducer;
