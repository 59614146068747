import { useState, useEffect, useCallback } from "react";
import { throttle } from "lodash";

const useScroll = ({ throttleValue }: { throttleValue?: number } = {}) => {
  const [scrollDirection, setScrollDirection] = useState<"up" | "down" | null>(
    null
  );

  const [oldPageYOffset, setOldPageYOffset] = useState(0);
  const [newPageYOffSet, setNewPageYOffset] = useState(0);

  const [scrollY, setScrollY] = useState(0);
  const [scrollX, setScrollX] = useState(0);

  const [isReachedTop, setIsReachedTop] = useState(false);
  const [isReachedBottom, setIsReachedBottom] = useState(false);

  const onScroll = () => {
    const body = document.body;
    const html = document.documentElement;
    const windowHeight =
      "innerHeight" in window
        ? window.innerHeight
        : document.documentElement.offsetHeight;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight
    );

    const windowBottom = Math.round(windowHeight + window.pageYOffset);
    const scrolledPercent = windowBottom / docHeight;

    setIsReachedBottom(scrolledPercent >= 0.98 ? true : false);
    setNewPageYOffset(window.pageYOffset);
    setScrollY(window.scrollY);
    setScrollX(window.scrollX);
  };

  const scrollToTop = useCallback(() => {
    window.scrollTo(0, 0);
  }, []);

  const throttleOnScroll = throttle(onScroll, throttleValue || 1000, {
    leading: true,
  });

  useEffect(() => {
    if (oldPageYOffset < newPageYOffSet) {
      setScrollDirection("up");
    } else if (oldPageYOffset > newPageYOffSet) {
      setScrollDirection("down");
    }
    setOldPageYOffset(newPageYOffSet);
  }, [oldPageYOffset, newPageYOffSet]);

  useEffect(() => {
    if (scrollY === 0) {
      setIsReachedTop(true);
    } else {
      setIsReachedTop(false);
    }
  }, [scrollY]);

  useEffect(() => {
    //원레 throttleOnScroll 사용
    window.addEventListener("scroll", onScroll);
    return () => {
      window.removeEventListener("scroll", onScroll);
    };
  }, []);

  return {
    scrollY,
    scrollX,
    scrollDirection,
    isReachedTop,
    isReachedBottom,
    scrollToTop,
  };
};

export default useScroll;
