import React, { useState, useEffect } from "react";

import useScroll from "../../common/hooks/useScroll";
import { ScrollToTopIconContainer } from "./styles";
import { Icon } from "../../components";
import useSettings from "../../common/hooks/useSettings";
const ScrollToTopButton = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { scrollToTop, scrollY } = useScroll({ throttleValue: 100 });
  const { displayMode } = useSettings();
  useEffect(() => {
    if (scrollY > 600) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [scrollY]);

  return (
    <ScrollToTopIconContainer onClick={scrollToTop} visible={isVisible}>
      <Icon
        name={
          displayMode === "dark"
            ? "ic_graphic_upfeed_filled_dark"
            : "ic_graphic_upfeed_filled_light"
        }
        size={47}
      />
    </ScrollToTopIconContainer>
  );
};

export default ScrollToTopButton;
