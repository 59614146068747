import Modal from "styled-react-modal";
import styled from "styled-components";
import colors from "../../styles/colors";
import fontWeight from "../../styles/fontWeights";

export const ModalBackground = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: ${"rgba(0, 0 , 0, 0.7)"};
  z-index: 1500;
`;

export const StyledModal = Modal.styled`
  position: absolute;
  background-color: ${colors.White};
  padding: 30px 20px 10px 20px;
  border-radius: 16px 16px 0 0;
  bottom: 0;
  width: 100vw;
`;

export const Title = styled.div`
  font-size: 19px;
  font-weight: ${fontWeight.bold};
  text-align: center;
  color: ${colors.Gray900};
`;

export const Content = styled.div`
  font-size: 15px;
  font-weight: ${fontWeight.regular};
  color: ${colors.Gray700};
  text-align: center;
  margin-top: 10px;
`;

export const ActionButton = styled.button`
  background-color: ${colors.Blue700};
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  width: 100%;
  height: 54px;
  margin-top: 30px;
`;

export const Action = styled.div`
  font-size: 17px;
  font-weight: ${fontWeight.bold};
  color: ${colors.White};
`;
