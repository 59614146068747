import React from "react";

import { Container } from "./styles";
import { ShowTranslation, Icon } from "../../components";

import useSettings from "../../common/hooks/useSettings";
import useModeColors from "../../common/hooks/useModeColors";

interface IProps {
  text: string;
}

const Error = ({ text }: IProps) => {
  const { displayMode } = useSettings();
  const modeColors = useModeColors();
  return (
    <Container>
      <Icon
        name={
          displayMode === "dark"
            ? "ic_graphic_warning_filled_dark"
            : "ic_graphic_warning_filled_light"
        }
        size={60}
      />
      <div style={{ height: 17 }} />
      <ShowTranslation weight={"r"} size={12} color={modeColors.text_02}>
        {text}
      </ShowTranslation>
    </Container>
  );
};

Error.defaultProps = {
  text: "ERROR 404",
};

export default Error;
