import styled from "styled-components";
import colors from "../../styles/colors";
import fontWeight from "../../styles/fontWeights";
import { DefaultTheme } from "styled-components";
import { ImageSourceButton } from "../../styles/commonStyles";

const FullPlayerContainer = styled.div`
  display: flex;
  height: 54px;
  padding-left: 15px;
  padding-right: 15px;
  border-radius: 6px;
  background-color: ${({ theme }) => theme.modeColors.background_02};
  align-items: center;
  justify-content: center;
`;

const ProgressBar = styled.input`
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: flex;
  flex-grow: 1;
  margin-left: 16px;
  height: 3px;
  border-radius: 2px;
  cursor: pointer;
  outline: none;
  border: none;
  transition: background 0.1s ease;
  background: ${colors.Gray300};
  background: ${(props: { progressPercent: string; theme: DefaultTheme }) =>
    `-webkit-gradient(linear, 0% 0%, 100% 0%, 
    color-stop(
    ${props.progressPercent}, ${colors.neonGreen}), 
    color-stop(
    ${props.progressPercent}, ${props.theme.modeColors.background_03}))`};

  background: ${(props: { progressPercent: string; theme: DefaultTheme }) =>
    `-moz-gradient(linear, 0% 0%, 100% 0%, 
    color-stop(
    ${props.progressPercent}, ${colors.neonGreen}), 
    color-stop(
    ${props.progressPercent}, ${props.theme.modeColors.background_03}))`}

  &:focus {
    outline: none;
    border: none;
  }

  &:hover {
    outline: none;
    border: none;
  }

  &::-webkit-slider-thumb {
    -webkit-appearance: none !important;
    background: ${colors.neonGreen};
    cursor: pointer;
    border: unset;
    padding: unset;
    margin: 0px;
    height: 9px;
    width: 9px;
    border-radius: 4.5px;
  }

  &::-moz-slider-thumb {
    -moz-appearance: none;
    background: ${colors.neonGreen};
    cursor: pointer;
    border: unset;
    padding: unset;
    margin: unset;
    margin-top: 0px;
    height: 9px;
    width: 9px;
    border-radius: 4.5px;
  }
`;

const RemainTimerSpacer = styled.div`
  width: 8px;
`;

const AudioControlButton = styled(ImageSourceButton)``;

const MiniPlayerContainer = styled.div`
  display: flex;
  width: 79px;
  height: 30px;
  border: ${({ theme }) => `1px solid ${theme.modeColors.border_03};`};
  border-radius: 35px;
  justify-content: center;
  align-items: center;
`;

export {
  RemainTimerSpacer,
  ProgressBar,
  AudioControlButton,
  FullPlayerContainer,
  MiniPlayerContainer,
};
