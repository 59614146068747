import axios, { AxiosResponse } from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import apis from "../../api/apis";

import { snsAxiosInstance } from "../../common/utils/axios";

type ChangeHideStatusArgs = {
  feedId: string;
  totalCountManageLikes: number;
  text: string;
  hide: "OWN" | "CS";
};

const changeHideStatus = createAsyncThunk(
  "management/changeHideStatus",
  async (data: ChangeHideStatusArgs, { rejectWithValue }) => {
    try {
      const response = await snsAxiosInstance.put(
        `${apis.management.feedPost}/${data.feedId}`,
        data
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue("FAIL TO CHANGE STATUS");
    }
  }
);

export { changeHideStatus };
