import {
  Wrapper,
  LeftTabWrapper,
  LeftTab,
  RightTabWrapper,
  RightTab,
  MiddleTabWrapper,
  MiddleTab,
} from "./styles";

interface IProps {
  height: number;
  tabState: string;
  leftTabKey: string;
  middleTabKey?: string;
  rightTabKey: string;
  leftTab: string | JSX.Element;
  middleTab?: string | JSX.Element;
  rightTab: string | JSX.Element;
  onLeftTab: () => void;
  onMiddleTab?: () => void;
  onRightTab: () => void;
}

const ConvertingTab = ({
  height,
  tabState,
  leftTabKey,
  middleTabKey,
  rightTabKey,
  leftTab,
  middleTab,
  rightTab,
  onLeftTab,
  onMiddleTab,
  onRightTab,
}: IProps) => {
  return (
    <Wrapper height={height}>
      <LeftTabWrapper
        onClick={onLeftTab}
        isSelected={tabState === leftTabKey}
        height={height}
      >
        <LeftTab isSelected={tabState === leftTabKey}>{leftTab}</LeftTab>
      </LeftTabWrapper>
      {middleTabKey && middleTab && (
        <MiddleTabWrapper
          onClick={onMiddleTab}
          isSelected={tabState === middleTabKey}
          height={height}
        >
          <MiddleTab isSelected={tabState === middleTabKey}>
            {middleTab}
          </MiddleTab>
        </MiddleTabWrapper>
      )}
      <RightTabWrapper
        onClick={onRightTab}
        isSelected={tabState === rightTabKey}
        height={height}
      >
        <RightTab isSelected={tabState === rightTabKey}>{rightTab}</RightTab>
      </RightTabWrapper>
    </Wrapper>
  );
};

ConvertingTab.defaultProps = {
  height: 50,
  tabState: "LEFT",
  leftTabKey: "LEFT",
  rightTabKey: "RIGHT",
  leftTab: "추천",
  rightTab: "최신",
  onLeftTab: () => {},
  onRightTab: () => {},
};

export default ConvertingTab;
