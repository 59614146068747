import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
  * {
    box-sizing: border-box;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  

  body {
    
    min-width: 320px;
    position:relative;
    margin: 0px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen','Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
    -khtml-user-select: none; /* Konqueror HTML */
    -moz-user-select: none; /* Old versions of Firefox */
    -ms-user-select: none; /* Internet Explorer/Edge */
    user-select: none; /* Non-prefixed version, currently supported by Chrome, Edge, Opera and Firefox */
    
    background-color: ${props => {
      return props.theme.modeColors.background_01;
    }};
  
  }

  div {

    color: ${props => {
      return props.theme.modeColors.text_01;
    }};
  }

  button {
    padding: 0;
    border: none;
    background: none;
    outline: none;
    background-repeat: no-repeat;
    box-sizing: border-box;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
  }

`;

export default GlobalStyle;
