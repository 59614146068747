import { DefaultTheme } from "styled-components";
import { lightModeColors, darkModeColors } from "./colors";

export const lightModeThemes: DefaultTheme = {
  modeColors: lightModeColors,
};

export const darkModeThemes: DefaultTheme = {
  modeColors: darkModeColors,
};
