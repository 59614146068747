import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { fetchUserControl, fetchReportDefinitions } from "./reportThunks";

type ReportDomain =
  | "CALL"
  | "CHAT"
  | "CONVERSATION"
  | "FEED"
  | "COMMENT"
  | "SWIPE"
  | "PROFILE";

export type ReportKey =
  | "NOT_VIBE"
  | "NETWORK_ERROR"
  | "NO_ANSWER"
  | "SCAM_TALK"
  | "AGGRESSIVE_TONE"
  | "RUDE_TALK"
  | "MULTI_PEOPLE"
  | "PERSONAL_LIFE"
  | "ASK_CONTACT"
  | "SEXUAL_TALK"
  | "VIOLENT_TALK"
  | "FEED_CONTACT"
  | "FEED_ANONYMITY"
  | "FEED_VIOLENT"
  | "FEED_ARGUMENT"
  | "FEED_BAD_PHOTO"
  | "FEED_SEXUAL"
  | "FEED_FARMING"
  | "FEED_BULLY"
  | "FEED_AD"
  | "FEED_SCAM"
  | "FEED_OTHERS"
  | "FAKE_PROFILE"
  | "BAD_NICKNAME"
  | "BAD_PROFILE"
  | "MINOR"
  | "BIO_CONTACT";

export type ReportKeyScore = 0 | 1 | 2;
export type ReportKeyList = Array<ReportKey>;

export type ReportFlow = "REPORT" | "HANGUP" | "REPORT_AND_HANGUP";

export type ReportDomainDefinitions = Record<ReportDomain, Array<ReportKey>>;

export type ReportItemDescription = Record<ReportKey, string>;

export type ReportKeysWithInfo = ReportKeyList;

export type ReportScoresByKey = Record<ReportKey, ReportKeyScore>;

export interface ReportState {
  loading: "NONE" | "LOADING_USER_CONTROL";
  userControl: any;
  userControlError: boolean;
  reportDomainDefinitions: ReportDomainDefinitions;
  reportItemDescription: ReportItemDescription;
  reportKeysWithInfo: ReportKeysWithInfo;
  reportScoresByKey: ReportScoresByKey;
}

const initialState: ReportState = {
  loading: "NONE",
  userControl: {},
  userControlError: false,
  reportDomainDefinitions: {} as ReportState["reportDomainDefinitions"],
  reportItemDescription: {} as ReportState["reportItemDescription"],
  reportKeysWithInfo: [] as ReportState["reportKeysWithInfo"],
  reportScoresByKey: {} as ReportState["reportScoresByKey"],
};

export type GetRequestReportDefinitionsResponseData = {
  report_reason_keys: Array<{
    key: ReportKey;
    translated_description: string;
    score: ReportKeyScore;
  }>;
  keys_in_each_domain: ReportDomainDefinitions;
  meta_datas_for_ui: {
    keys_for_info: ReportKeyList;
  };
};

export function formatResponseToReportItemDescriptionInterface(
  data: GetRequestReportDefinitionsResponseData
) {
  return data.report_reason_keys.reduce((acc, reportReasonKey) => {
    acc[reportReasonKey.key] = reportReasonKey.translated_description;
    return acc;
  }, {} as ReportItemDescription);
}

export function formatResponseToReportKeysWithInfoInterface(
  data: GetRequestReportDefinitionsResponseData
): ReportKeysWithInfo {
  if (data.meta_datas_for_ui && data.meta_datas_for_ui.keys_for_info) {
    return data.meta_datas_for_ui.keys_for_info;
  }

  return [];
}

export function formatResponseToReportScoresByKeyInterface(
  data: GetRequestReportDefinitionsResponseData
) {
  return data.report_reason_keys.reduce((acc, reportReasonKey) => {
    acc[reportReasonKey.key] = reportReasonKey.score
      ? reportReasonKey.score
      : 0;
    return acc;
  }, {} as ReportScoresByKey);
}

export const reportSlice = createSlice({
  name: "report",
  initialState,
  reducers: {},
  extraReducers: builders => {
    builders.addCase(fetchUserControl.pending, (state, action) => {
      state.loading = "LOADING_USER_CONTROL";
    });

    builders.addCase(fetchUserControl.fulfilled, (state, action) => {
      state.userControl = action.payload;
      state.loading = "NONE";
    });

    builders.addCase(fetchUserControl.rejected, (state, action) => {
      state.loading = "NONE";
      state.userControlError = true;
    });

    builders.addCase(fetchReportDefinitions.fulfilled, (state, action) => {
      state.reportDomainDefinitions = action.payload.keys_in_each_domain;
      state.reportItemDescription =
        formatResponseToReportItemDescriptionInterface(action.payload);
      state.reportKeysWithInfo = formatResponseToReportKeysWithInfoInterface(
        action.payload
      );
      state.reportScoresByKey = formatResponseToReportScoresByKeyInterface(
        action.payload
      );
    });
  },
});

export const selectUserControl = (state: RootState) => state.report.userControl;
export const selectUserControlLoading = (state: RootState) =>
  state.report.loading;
export const selectuserControlError = (state: RootState) =>
  state.report.userControlError;
export const selectReportDomainDefinitions = (state: RootState) =>
  state.report.reportDomainDefinitions;
export const selectReportItemDescription = (state: RootState) =>
  state.report.reportItemDescription;
export const selectReportKeysWithInfo = (state: RootState) =>
  state.report.reportKeysWithInfo;
export const selectReportScoresByKey = (state: RootState) =>
  state.report.reportScoresByKey;

export default reportSlice.reducer;
