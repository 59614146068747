import { useState, useEffect, ChangeEvent, MouseEvent } from "react";

import { useLocation } from "react-router-dom";

import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";

import {
  AudioStatus,
  AUDIO_STATUS,
  startPlayer,
  stopPlayer,
  pausePlayer,
  seekTo,
  roundTime,
} from "../../common/utils/audioManager";

import {
  selectAudioPlayerGlobalState,
  setAudioPlayerGlobalState,
} from "../../features/globalAppState/globalAppStateSlice";
import { FullPlayerContainer, MiniPlayerContainer, ProgressBar } from "./style";

import colors from "../../styles/colors";

import { Icon, ShowTranslation } from "../../components";

import useModeColors from "../../common/hooks/useModeColors";
interface IProps {
  type: "FULL" | "MINI";
  audioUrl: string;
  initDuration: number;
}

const pad = (num: number) => {
  return ("0" + num).slice(-2);
};

const convertToPlayTime = (time: number) => {
  let secs = roundTime(time);

  const minutes = Math.floor(secs / 60);
  const seconds = secs % 60;
  return pad(minutes) + ":" + pad(seconds);
};

const convertToPercent = (progress: number, total: number) => {
  const percent = (progress / total) * 100;
  return `${percent}%`;
};

const SoundPlayer = ({ type, audioUrl, initDuration }: IProps) => {
  const dispatch = useAppDispatch();
  const audioPlayerGlobalState = useAppSelector(selectAudioPlayerGlobalState);
  const location = useLocation();
  const [isPlaying, setIsPlaying] = useState(false);

  const [audioDuration, setAudioDuration] = useState(initDuration);

  const [currentTime, setCurrentTime] = useState(
    convertToPlayTime(initDuration ? initDuration / 1000 : 0)
  );

  const [audioProgress, setAudioProgress] = useState(0);
  const [progressPercent, setProgressPercent] = useState("0%");

  const modeColors = useModeColors();

  useEffect(() => {
    setAudioDuration(initDuration);
    setCurrentTime(convertToPlayTime(initDuration ? initDuration / 1000 : 0));
  }, [initDuration]);

  useEffect(() => {
    if (location || audioPlayerGlobalState === "STOP_PLAYER") {
      pausePlayer();
      stopPlayer();
      dispatch(setAudioPlayerGlobalState("NONE"));
    }

    return () => {
      pausePlayer();
      stopPlayer();
      dispatch(setAudioPlayerGlobalState("NONE"));
    };
  }, [location, audioPlayerGlobalState, dispatch]);

  const audioManagerHandler = ({
    status,
    duration,
    currentTime,
  }: {
    status: AudioStatus;
    duration?: number;
    currentTime?: number;
  }) => {
    switch (status) {
      case AUDIO_STATUS.play: {
        setIsPlaying(true);
        break;
      }

      case AUDIO_STATUS.pause: {
        setIsPlaying(false);
        break;
      }

      case AUDIO_STATUS.ended: {
        setIsPlaying(false);
        setCurrentTime(
          convertToPlayTime(initDuration ? initDuration / 1000 : 0)
        );
        setAudioDuration(initDuration ? initDuration : 0);
        setAudioProgress(0);
        setProgressPercent("0%");
        break;
      }

      case AUDIO_STATUS.duration: {
        if (duration) {
          setAudioDuration(duration);
        }
        break;
      }

      case AUDIO_STATUS.currentTime: {
        if (currentTime && duration) {
          setAudioProgress(currentTime);
          setCurrentTime(convertToPlayTime(currentTime));
          setProgressPercent(convertToPercent(currentTime, duration));
        }
        break;
      }

      case AUDIO_STATUS.seeking: {
        if (currentTime && duration) {
          setAudioProgress(currentTime);
          setCurrentTime(convertToPlayTime(currentTime));
          setProgressPercent(convertToPercent(currentTime, duration));
        }
        break;
      }

      case AUDIO_STATUS.seeked: {
        if (currentTime && duration) {
          setAudioProgress(currentTime);
          setCurrentTime(convertToPlayTime(currentTime));
          setProgressPercent(convertToPercent(currentTime, duration));
        }
        break;
      }

      default:
        break;
    }

    return {};
  };

  const play = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    startPlayer(audioUrl, audioManagerHandler);
  };

  const pause = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    pausePlayer();
  };

  const onSeekPlayer = (event: ChangeEvent<HTMLInputElement>) => {
    event.stopPropagation();
    seekTo(Number(event.target.value));
  };

  const onSeekDone = (event: any) => {
    event.stopPropagation();
    seekTo(Number(event.target.value));
  };

  const renderFullPlayer = () => {
    return (
      <FullPlayerContainer>
        {isPlaying ? (
          <Icon
            name="ic_pausecircle_line_regular"
            size={28}
            onClick={pause}
            color={colors.Navy600}
          />
        ) : (
          <Icon
            name="ic_playcircle_line_regular"
            size={28}
            onClick={play}
            color={colors.Navy600}
          />
        )}

        <ProgressBar
          disabled={!isPlaying}
          type="range"
          value={audioProgress}
          step="0.01"
          min={0}
          max={audioDuration}
          className="progress"
          onChange={onSeekPlayer}
          onTouchEnd={onSeekDone}
          progressPercent={progressPercent}
        />
        <div style={{ width: 6 }} />
        <ShowTranslation size={12} weight={"r"} color={modeColors.text_02}>
          {currentTime}
        </ShowTranslation>
      </FullPlayerContainer>
    );
  };

  const renderMiniPlayer = () => {
    return (
      <MiniPlayerContainer>
        {isPlaying ? (
          <Icon
            name="ic_pause_filled"
            size={18}
            onClick={pause}
            color={modeColors.background_04}
          />
        ) : (
          <Icon
            name="ic_play_filled"
            size={18}
            onClick={play}
            color={modeColors.background_04}
          />
        )}
        <div style={{ width: 8 }} />
        <ShowTranslation size={12} weight={"r"} color={modeColors.text_01}>
          {currentTime}
        </ShowTranslation>
      </MiniPlayerContainer>
    );
  };

  return type === "FULL" ? renderFullPlayer() : renderMiniPlayer();
};

export default SoundPlayer;
