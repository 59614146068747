import { createAsyncThunk } from "@reduxjs/toolkit";
import { mainAxiosInstance } from "../../common/utils/axios";
import apis from "../../api/apis";
import { PhoneLanguage, Country } from "../../common/types/commonTypes";

const fetchUserControl = createAsyncThunk(
  "report/fetchUserControl",
  async ({ domain }: { domain: "FEED" }, { rejectWithValue }) => {
    try {
      const response = await mainAxiosInstance.get(
        `${apis.report.userControl}`,
        {
          params: { domain },
        }
      );

      return response.data;
    } catch (error: any) {
      return rejectWithValue("FAILED_TO_FETCH_USERCONTROL");
    }
  }
);

const updateUserControl = createAsyncThunk(
  "report/updateUserControl",
  async ({ domain }: { domain: "FEED" }, { rejectWithValue }) => {
    try {
      const response = await mainAxiosInstance.post(
        `${apis.report.userControl}/check/warning`,
        { domain }
      );
    } catch (error) {
      return rejectWithValue("FAILED_TO_UPDATE_USERCONTROL");
    }
  }
);

const fetchReportDefinitions = createAsyncThunk(
  "report/fetchReportDefinitions",
  async (
    {
      language_locale,
      country_code,
    }: {
      language_locale: PhoneLanguage;
      country_code: Country;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await mainAxiosInstance.get(
        `${apis.report.reportDefinitions}`,
        {
          params: { language_locale, country_code },
        }
      );

      return response.data.data;
    } catch (error) {
      return rejectWithValue("FAILED_TO_FETCH_REPORT_DEFINITIONS");
    }
  }
);

export { fetchUserControl, updateUserControl, fetchReportDefinitions };
