import React, { useEffect } from "react";

import { Container } from "./styles";
import { Error } from "../../components";

import getTranslation from "../../common/utils/getTranslation";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";

const AuthError = () => {
  useEffect(() => {
    ReactNativeWebview.postMessageToApp({ type: "SHOW_APP_HEADER" });
  }, []);

  return (
    <Container>
      <Error text={getTranslation("AuthError.errorMessage") as string} />
    </Container>
  );
};

export default AuthError;
