import { ComponentPropsWithoutRef } from "react";
import { ToolTip } from "../../components";
import { useDispatch } from "react-redux";
import {
  selectToolTips,
  setToolTips,
} from "../../features/globalAppState/globalAppStateSlice";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";
import getTranslation from "../../common/utils/getTranslation";

// NOTE - 무료 프로필 교환 아이템을 보유할 경우, Feed List에서 아이템 보유 배너 하단에 나타나는 툴팁
const FreeProfileExchangeItemNoticeToolTip = () => {
  const dispatch = useDispatch();

  const freeProfileExchangeItemNoticeToolTip =
    useAppSelector(selectToolTips).FREE_PROFILE_EXCHANGE_ITEM_NOTICE_VISIBLE;

  const tooltipProps: ComponentPropsWithoutRef<typeof ToolTip> = {
    title: getTranslation(
      "ProfileExchangeNudge.surpriseGiftProfileExchangeReceived"
    ) as string,
    content: getTranslation(
      "ProfileExchangeNudge.sendProfileExchangeToFavoriteFriend"
    ) as string,
    isVisible: freeProfileExchangeItemNoticeToolTip,
    onClose: () => {
      dispatch(
        setToolTips({
          key: "FREE_PROFILE_EXCHANGE_ITEM_NOTICE_VISIBLE",
          isVisible: false,
        })
      );

      ReactNativeWebview.postMessageToApp({
        type: "FREE_PROFILE_EXCHANGE_ITEM_NOTICE_TOOL_TIP_SEEN",
      });
    },
    toolTipPosition: { left: 20 },
    triangleHorizontalPosition: { left: 11 },
  };

  return <ToolTip {...tooltipProps} />;
};

export default FreeProfileExchangeItemNoticeToolTip;
