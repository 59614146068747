import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

import {
  FeedListData,
  FeedListState,
  FeedListLoading,
  SelectedTab,
} from "./types";

import {
  fetchFeed,
  fetchInitFeeds,
  likeFeed,
  unlikeFeed,
  deleteFeed,
  fetchMyFeedStatus,
} from "./feedListThunks";

import { parseNextFeedId } from "../../common/utils/parseNextPageUrl";
import { createBookmark, deleteBookmark } from "../bookmark/bookmarkThunks";

const initialState: FeedListState = {
  loading: "LOADING",

  selectedTab: "LATEST",

  feeds: {
    my: [],
    latest: [],
    recommend: [],
    notice: [],
  },

  nextPageIds: {
    my: 0,
    latest: 0,
    recommend: 0,
    notice: 0,
  },

  myFeedStatus: {
    totalFeedCount: 0,
    totalLikeCount: 0,
    totalCommentCount: 0,
  },

  error: null,
};

export const feedListSlice = createSlice({
  name: "feedList",
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<FeedListLoading>) => {
      state.loading = action.payload;
    },

    setSelectedTab: (state, action: PayloadAction<SelectedTab>) => {
      state.selectedTab = action.payload;
    },
  },

  extraReducers: builder => {
    builder.addCase(fetchFeed.pending, (state, action) => {
      if (action.meta.arg.fetchType === "MORE") {
        state.loading = "LOADING_MORE";
      }
    });

    builder.addCase(fetchFeed.fulfilled, (state, action) => {
      if (action.meta.arg.fetchType === "REFRESH") {
        state.feeds[action.meta.arg.category] = action.payload.feed_list;
      }

      if (action.meta.arg.fetchType === "MORE") {
        state.feeds[action.meta.arg.category] = state.feeds[
          action.meta.arg.category
        ].concat(action.payload.feed_list);
      }

      state.nextPageIds[action.meta.arg.category] = parseNextFeedId(
        action.payload.next_page
      );

      state.loading = "NONE";
    });

    builder.addCase(fetchFeed.rejected, (state, action) => {
      state.loading = "NONE";
    });

    builder.addCase(fetchInitFeeds.pending, (state, action) => {
      state.loading = "LOADING";
    });

    builder.addCase(fetchInitFeeds.fulfilled, (state, action) => {
      state.loading = "NONE";
      state.feeds = action.payload.feeds;
      state.nextPageIds = action.payload.nextPageIds;
    });

    builder.addCase(fetchInitFeeds.rejected, (state, action) => {
      state.loading = "NONE";
    });

    builder.addCase(likeFeed.pending, (state, action) => {
      const updateList = (feedList: FeedListData, feedId: string) => {
        return feedList.map(item => {
          if (item.feedpost.feedpost_id === feedId) {
            const updatedFeed = { ...item };
            const { feedpost } = updatedFeed;
            const updatedPost = { ...feedpost };

            updatedFeed.is_liked = true;
            updatedPost.total_count_likes += 1;
            updatedFeed.feedpost = updatedPost;

            return updatedFeed;
          }
          return item;
        });
      };

      const updatedFeeds = {
        my: updateList(state.feeds.my, action.meta.arg.feedId),
        latest: updateList(state.feeds.latest, action.meta.arg.feedId),
        recommend: updateList(state.feeds.recommend, action.meta.arg.feedId),
      };

      state.feeds = { notice: state.feeds.notice, ...updatedFeeds };
    });

    builder.addCase(likeFeed.fulfilled, (state, action) => {});

    builder.addCase(likeFeed.rejected, (state, action) => {
      const updateList = (feedList: FeedListData, feedId: string) => {
        return feedList.map(item => {
          if (item.feedpost.feedpost_id === feedId) {
            const updatedFeed = { ...item };
            const { feedpost } = updatedFeed;
            const updatedPost = { ...feedpost };

            updatedFeed.is_liked = false;
            updatedPost.total_count_likes -= 1;
            updatedFeed.feedpost = updatedPost;
            return updatedFeed;
          }
          return item;
        });
      };

      const updatedFeeds = {
        my: updateList(state.feeds.my, action.meta.arg.feedId),
        latest: updateList(state.feeds.latest, action.meta.arg.feedId),
        recommend: updateList(state.feeds.recommend, action.meta.arg.feedId),
      };

      state.feeds = { notice: state.feeds.notice, ...updatedFeeds };
    });

    builder.addCase(unlikeFeed.pending, (state, action) => {
      const updateList = (feedList: FeedListData, feedId: string) => {
        return feedList.map(item => {
          if (item.feedpost.feedpost_id === feedId) {
            const updatedFeed = { ...item };
            const { feedpost } = updatedFeed;
            const updatedPost = { ...feedpost };

            updatedFeed.is_liked = false;
            updatedPost.total_count_likes -= 1;
            updatedFeed.feedpost = updatedPost;

            return updatedFeed;
          }
          return item;
        });
      };

      const updatedFeeds = {
        my: updateList(state.feeds.my, action.meta.arg.feedId),
        latest: updateList(state.feeds.latest, action.meta.arg.feedId),
        recommend: updateList(state.feeds.recommend, action.meta.arg.feedId),
      };

      state.feeds = { notice: state.feeds.notice, ...updatedFeeds };
    });

    builder.addCase(unlikeFeed.fulfilled, (state, action) => {});

    builder.addCase(unlikeFeed.rejected, (state, action) => {
      const updateList = (feedList: FeedListData, feedId: string) => {
        return feedList.map(item => {
          if (item.feedpost.feedpost_id === feedId) {
            const updatedFeed = { ...item };
            const { feedpost } = updatedFeed;
            const updatedPost = { ...feedpost };

            updatedFeed.is_liked = true;
            updatedPost.total_count_likes += 1;
            updatedFeed.feedpost = updatedPost;
            return updatedFeed;
          }
          return item;
        });
      };

      const updatedFeeds = {
        my: updateList(state.feeds.my, action.meta.arg.feedId),
        latest: updateList(state.feeds.latest, action.meta.arg.feedId),
        recommend: updateList(state.feeds.recommend, action.meta.arg.feedId),
      };

      state.feeds = { notice: state.feeds.notice, ...updatedFeeds };
    });

    builder.addCase(deleteFeed.pending, (state, action) => {});

    builder.addCase(deleteFeed.fulfilled, (state, action) => {
      const updateList = (feedList: FeedListData, feedId: string) => {
        return feedList.filter(item => item.feedpost.feedpost_id !== feedId);
      };

      const updatedFeeds = {
        my: updateList(state.feeds.my, action.meta.arg.feedId),
        latest: updateList(state.feeds.latest, action.meta.arg.feedId),
        recommend: updateList(state.feeds.recommend, action.meta.arg.feedId),
      };

      state.feeds = { notice: state.feeds.notice, ...updatedFeeds };
    });

    builder.addCase(deleteFeed.rejected, (state, action) => {});

    builder.addCase(fetchMyFeedStatus.pending, (state, action) => {});

    builder.addCase(fetchMyFeedStatus.fulfilled, (state, action) => {
      state.myFeedStatus = {
        totalFeedCount: action.payload.total_count_feed_posts,
        totalCommentCount: action.payload.total_count_comments,
        totalLikeCount: action.payload.total_count_likes,
      };
    });

    builder.addCase(fetchMyFeedStatus.rejected, (state, action) => {});

    builder.addCase(createBookmark.pending, (state, action) => {
      const updateList = (feedList: FeedListData, feedId: string) => {
        return feedList.map(item => {
          if (item.feedpost.feedpost_id === feedId) {
            item.is_bookmarked = true;
          }

          return item;
        });
      };

      const updatedFeeds = {
        my: updateList(state.feeds.my, action.meta.arg.feedpostId),
        latest: updateList(state.feeds.latest, action.meta.arg.feedpostId),
        recommend: updateList(
          state.feeds.recommend,
          action.meta.arg.feedpostId
        ),
      };

      state.feeds = { notice: state.feeds.notice, ...updatedFeeds };
    });

    builder.addCase(createBookmark.fulfilled, (state, action) => {});

    builder.addCase(createBookmark.rejected, (state, action) => {
      const updateList = (feedList: FeedListData, feedId: string) => {
        return feedList.map(item => {
          if (item.feedpost.feedpost_id === feedId) {
            item.is_bookmarked = false;
          }

          return item;
        });
      };

      const updatedFeeds = {
        my: updateList(state.feeds.my, action.meta.arg.feedpostId),
        latest: updateList(state.feeds.latest, action.meta.arg.feedpostId),
        recommend: updateList(
          state.feeds.recommend,
          action.meta.arg.feedpostId
        ),
      };

      state.feeds = { notice: state.feeds.notice, ...updatedFeeds };
    });

    builder.addCase(deleteBookmark.pending, (state, action) => {
      const updateList = (feedList: FeedListData, feedId: string) => {
        return feedList.map(item => {
          if (item.feedpost.feedpost_id === feedId) {
            item.is_bookmarked = false;
          }

          return item;
        });
      };

      const updatedFeeds = {
        my: updateList(state.feeds.my, action.meta.arg.feedpostId),
        latest: updateList(state.feeds.latest, action.meta.arg.feedpostId),
        recommend: updateList(
          state.feeds.recommend,
          action.meta.arg.feedpostId
        ),
      };

      state.feeds = { notice: state.feeds.notice, ...updatedFeeds };
    });

    builder.addCase(deleteBookmark.fulfilled, (state, action) => {});

    builder.addCase(deleteBookmark.rejected, (state, action) => {
      const updateList = (feedList: FeedListData, feedId: string) => {
        return feedList.map(item => {
          if (item.feedpost.feedpost_id === feedId) {
            item.is_bookmarked = true;
          }

          return item;
        });
      };

      const updatedFeeds = {
        my: updateList(state.feeds.my, action.meta.arg.feedpostId),
        latest: updateList(state.feeds.latest, action.meta.arg.feedpostId),
        recommend: updateList(
          state.feeds.recommend,
          action.meta.arg.feedpostId
        ),
      };

      state.feeds = { notice: state.feeds.notice, ...updatedFeeds };
    });
  },
});

export const { setLoading, setSelectedTab } = feedListSlice.actions;

export const selectSelectedTab = (state: RootState) =>
  state.feedList.selectedTab;

export const selectFeed = (state: RootState) => state.feedList.feeds;

export const selectFeedLoading = (state: RootState) => state.feedList.loading;

export const selectFeedNextPageIds = (state: RootState) =>
  state.feedList.nextPageIds;

export const selectMyFeedStatus = (state: RootState) =>
  state.feedList.myFeedStatus;

export default feedListSlice.reducer;
