import styled from "styled-components";
import colors from "../../styles/colors";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 20px 17px 20px;
  border-bottom: ${({ theme }) =>
    `1px solid ${theme.modeColors.background_02}`};
`;

export const Spacer = styled.div`
  height: 20px;
`;

export const WithBookmarkButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
