import styled from "styled-components";

const Grid = styled.div`
  width: calc(100%);
  border-radius: 6px;
  height: ${(props: { height: number }) => props.height + "px" || "0px"};
  overflow: hidden;
  z-index: 100;
  border: ${({ theme }) => `0.5px solid ${theme.modeColors.border_02}`};
`;

const OneImage = styled.img.attrs({ loading: "lazy", alt: "" })`
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1.6;
`;

const TwoImage = styled.img.attrs({ loading: "lazy", alt: "" })`
  width: calc(50% - 1.5px);
  height: 100%;
  object-fit: cover;
  aspect-ratio: 0.98;
`;

const ThreeLeftImage = styled.img.attrs({ loading: "lazy", alt: "" })`
  width: calc(50% - 1.5px);
  height: 100%;
  object-fit: cover;
  aspect-ratio: 0.79;
`;

const ThreeRightImage = styled.img.attrs({ loading: "lazy", alt: "" })`
  width: calc(50% - 1.5px);
  height: 50%;
  object-fit: cover;
  aspect-ratio: 1.61;
`;

const FourImage = styled.img.attrs({ loading: "lazy", alt: "" })`
  width: calc(50% - 1.5px);
  object-fit: cover;
  height: 50%;
  aspect-ratio: 1.61;
`;

const FiveImageTop = styled.img.attrs({ loading: "lazy", alt: "" })`
  width: calc(50% - 1.5px);
  object-fit: cover;
  height: 50%;
  aspect-ratio: 1.61;
  margin-bottom: -1px;
`;

const FiveImageBottom = styled.img.attrs({ loading: "lazy", alt: "" })`
  display: inline-block;
  position: relative;
  width: calc(33% - 1px);
  object-fit: cover;
  height: 50%;
  aspect-ratio: 1.05;
`;

const FiveLastImageGrid = styled.div`
  position: relative;
  display: inline-block;
  width: calc(33% - 1px);
  height: 50%;
`;

const FiveLastImage = styled.img.attrs({ loading: "lazy", alt: "" })`
  display: inline-block;
  width: 110%;
  object-fit: cover;
  height: 100%;
  aspect-ratio: 1.05;
  filter: brightness(50%);
`;

const FiveImageCaption = styled.div`
  z-index: 1;
  color: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export {
  Grid,
  OneImage,
  TwoImage,
  ThreeLeftImage,
  ThreeRightImage,
  FourImage,
  FiveImageTop,
  FiveImageBottom,
  FiveLastImageGrid,
  FiveLastImage,
  FiveImageCaption,
};
