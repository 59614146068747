import React from "react";

import { ShowTranslation, Icon } from "../../components";
import { debounce } from "lodash";
import useModeColors from "../../common/hooks/useModeColors";
import useSettings from "../../common/hooks/useSettings";

import { Container, Spacer } from "./styles";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";

const ThemeToggle = () => {
  const modeColors = useModeColors();
  const { displayMode, setDisplayMode } = useSettings();

  const onClickFeedModeChangeButton = debounce(() => {
    ReactNativeWebview.postMessageToApp({
      type: "SET_FEED_COLOR_MODE",
      data: {
        mode: displayMode === "dark" ? "light" : "dark",
      },
    });
    setTimeout(() => {
      setDisplayMode(displayMode === "dark" ? "light" : "dark");
    }, 90);
  }, 300);

  return (
    <Container onClick={onClickFeedModeChangeButton}>
      <Icon
        name={
          displayMode === "dark"
            ? "ic_graphic_lightdark_filled_dark"
            : "ic_graphic_lightdark_filled_light"
        }
        size={20}
      />

      <Spacer />
      <ShowTranslation size={15} weight={"b"} color={modeColors.text_01}>
        {displayMode === "dark" ? "Dark" : "Light"}
      </ShowTranslation>
      <Spacer />

      <Icon
        name="ic_switch01_regular"
        size={18}
        color={modeColors.background_04}
      />
    </Container>
  );
};

export default ThemeToggle;
