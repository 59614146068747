import styled from "styled-components";
import colors from "../../styles/colors";

import gifWatermark from "../../assets/images/imgGiphyLogo@3x-1.png";
import gifMark from "../../assets/images/imgGifMark@3x.png";

export const GifContainer = styled.div`
  position: relative;
`;

export const Gif = styled.img.attrs({ loading: "lazy", alt: "" })`
  border-radius: 6px;
  width: 100%;
  height: 100%;
  object-fit: cover;
  aspect-ratio: 1;
`;

export const GifWatermark = styled.img.attrs({
  src: gifWatermark,
})`
  display: flex;
  position: absolute;
  width: 119px;
  height: 18px;
  bottom: 6px;
  right: 6px;
`;

export const GifMark = styled.img.attrs({
  src: gifMark,
})`
  display: flex;
  position: absolute;
  width: 28px;
  height: 18px;
  bottom: 6px;
  left: 6px;
`;
