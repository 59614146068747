export const ja = [
  [
    "22時に",
    "23時に",
    "街で",
    "家の前で",
    "結婚式で",
    "公園で",
    "今年",
    "金曜日に",
    "東京で",
    "洞窟の中で",
    "動物園で",
    "同窓会で",
    "冬に",
    "都会で",
    "旅行中",
    "買い物中",
    "面接中",
    "明け方に",
    "牧場で",
    "夢の中で",
    "無人島で",
    "病院で",
    "部屋で",
    "部屋の隅で",
    "事務室で",
    "山奥で",
    "森の中で",
    "書店で",
    "夕方に",
    "夕食中",
    "雪の上で",
    "雪の日に",
    "授業中",
    "神社で",
    "夜明けに",
    "夜中に",
    "夜に",
    "夜にも",
    "映画館で",
    "屋上で",
    "友達の家で",
    "宇宙で",
    "雨の日に",
    "遊園地で",
    "日々",
    "日暮れに",
    "日曜日に",
    "一人で",
    "一週間",
    "入社日に",
    "子供の日に",
    "電車で",
    "田舎で",
    "卒業式で",
    "朝に",
    "酒屋で",
    "職場で",
    "車庫で",
    "倉庫で",
    "車で",
    "春に",
    "沖縄で",
    "秋に",
    "誕生日に",
    "土曜日に",
    "平凡に",
    "布団の中で",
    "韓国で",
    "夏に",
    "夏の海辺で",
    "海辺で",
    "海で",
    "火星で",
    "花咲く春に",
    "休憩中",
    "会社で",
    "図書館で",
    "学校で",
    "寿司屋で",
    "帰り道に",
    "昼休みに",
    "毎日",
    "渋谷で",
    "温泉で",
    "飲み会で",
    "駅前で",
    "アメリカで",
    "お正月に",
    "お祭りで",
    "お風呂で",
    "カフェで",
    "コンビニで",
    "ジムで",
    "ソファで",
    "どこかで",
    "バイト先で",
    "バスで",
    "パリで",
    "ハワイで",
    "プールで",
    "フランスで",
    "ローマで",
    "ロンドンで",
  ],
  [
    "嬉しい",
    "楽しい",
    "幸せな",
    "スッキリする",
    "笑う",
    "満足する",
    "爽快な",
    "感動する",
    "感心する",
    "癒される",
    "落ち着く",
    "懐かしむ",
    "愛する",
    "恋する",
    "憧れる",
    "泣かない",
    "気が引ける",
    "気遣う",
    "同情される",
    "尊敬される",
    "かわいそうな",
    "寂しい",
    "悲しい",
    "孤独な",
    "困惑する",
    "ニヤニヤする",
    "哀れな",
    "戸惑う",
    "涙ぐむ",
    "萎える",
    "心が痛む",
    "憂鬱な",
    "失望する",
    "ため息する",
    "虚しい",
    "情けない",
    "やかましい",
    "惨めな",
    "へこむ",
    "がっかりする",
    "切ない",
    "泣く",
    "落ち込む",
    "苦労する",
    "慌てる",
    "呆れる",
    "びっくりする",
    "怖がる",
    "不気味な",
    "たじろぐ",
    "苦笑する",
    "不安な",
    "心配する",
    "青ざめる",
    "震える",
    "絶望する",
    "息苦しい",
    "悩む",
    "心細い",
    "鳥肌が立つ",
    "恐ろしい",
    "怯える",
    "愛される",
    "驚く",
    "多忙な",
    "涙もろい",
    "優しい",
    "喋る",
    "怠ける",
    "嫉妬する",
    "困っている",
    "大笑いする",
    "忙しい",
    "フラれた",
    "不愉快な",
    "叱られる",
    "文句を言う",
    "怒鳴る",
    "イライラする",
    "のんびりする",
    "大変な",
    "我慢する",
    "フラれる",
    "悟りを開く",
    "無気力な",
    "だらける",
    "冷たい",
    "後悔する",
    "悔しがる",
    "恥ずかしい",
    "後ろめたい",
    "恨めしい",
    "惜しむ",
    "悔やむ",
    "切羽詰まる",
    "モヤモヤする",
    "しんどい",
    "苦しい",
    "切なくなる",
    "緊張する",
    "立派な",
    "感銘を受ける",
    "心打たれる",
    "酔ってる",
    "照れる",
    "疲れた",
    "強がる",
    "妙な",
    "頑張る",
    "ノリノリの",
    "欲張る",
    "ドキドキする",
    "中途半端な",
    "こわばる",
    "心乱れる",
    "いらつく",
    "焦る",
    "居眠りする",
    "爆笑する",
    "安心する",
    "すっきりする",
    "うとうとする",
    "静かな",
    "自信満々な",
    "地味な",
    "ウケる",
    "慎重な",
    "イケてる",
    "可愛い",
    "かっこいい",
    "美しい",
    "活発な",
    "繊細な",
    "目立たない",
    "目立つ",
    "考える",
    "感極まる",
    "話したい",
    "隠れる",
    "ほっとする",
    "つまらない",
    "退屈な",
    "照れ臭い",
    "眠れない",
  ],
  [
    "巨人",
    "麒麟",
    "紀州犬",
    "金魚",
    "白虎",
    "北海道犬",
    "四国犬",
    "柴犬",
    "宇宙人",
    "幽霊",
    "蝶",
    "精霊",
    "朱雀",
    "天使",
    "秋田犬",
    "海馬",
    "玄武",
    "アイアイ",
    "アオサギ",
    "アカギツネ",
    "アカネズミ",
    "アザラシ",
    "アジアゾウ",
    "アシカ",
    "アトリ",
    "アナウサギ",
    "アフリカ",
    "アメリカ",
    "アライグマ",
    "アリクイ",
    "アルパカ",
    "アルマジロ",
    "レトリバー",
    "バーマン",
    "イタチ",
    "イッカク",
    "イノシシ",
    "イルカ",
    "インコ",
    "インドゾウ",
    "インパラ",
    "ウィペット",
    "ウサギ",
    "ウシ",
    "ウナギ",
    "ウマ",
    "ウンピョウ",
    "エゾリス",
    "エビ",
    "エルフ",
    "エンオウ",
    "オウム",
    "オオカミ",
    "オカピ",
    "オグロヌー",
    "オス",
    "オセロット",
    "オットセイ",
    "オナガザル",
    "オポッサム",
    "オマキザル",
    "オルカ",
    "カオマニー",
    "ガゼル",
    "カタツムリ",
    "カナリア",
    "カニ",
    "カバ",
    "カピバラ",
    "カマイルカ",
    "カモ",
    "カモノハシ",
    "カモメ",
    "カラカル",
    "カラス",
    "カワウソ",
    "カンガルー",
    "キジ",
    "キタキツネ",
    "キタリス",
    "キツネ",
    "キツネザル",
    "キヌザル",
    "キバタン",
    "キリン",
    "キンシコウ",
    "クジラ",
    "クマ",
    "クマネズミ",
    "グリフォン",
    "クルマエビ",
    "クロサイ",
    "ケガニ",
    "コアラ",
    "コイ",
    "コウモリ",
    "コククジラ",
    "コクジラ",
    "コビトカバ",
    "コヨーテ",
    "コラット",
    "ゴリラ",
    "サーバル",
    "サイ",
    "サケ",
    "サバンナ",
    "サモエド",
    "サル",
    "サルモン",
    "シーズー",
    "シカ",
    "ジネズミ",
    "シマウマ",
    "シマリス",
    "ジャガー",
    "シャチ",
    "ジャッカル",
    "ジャマ",
    "ジュゴン",
    "シロイルカ",
    "シロクマ",
    "シロサイ",
    "スカンク",
    "スズメ",
    "セイウチ",
    "セミクジラ",
    "ゾウ",
    "ダチョウ",
    "タヌキ",
    "タンチョウ",
    "チーター",
    "チワワ",
    "チンチラ",
    "ツチブタ",
    "つる",
    "テナガザル",
    "テングザル",
    "トイガー",
    "トイボブ",
    "トド",
    "トナカイ",
    "トビ",
    "ドブネズミ",
    "トモエガモ",
    "トラ",
    "トラネコ",
    "ナガスクジラ",
    "ナキウサギ",
    "ナマケモノ",
    "ナンバット",
    "ニホンザル",
    "ニホンジカ",
    "ニホンリス",
    "にわとり",
    "ヌー",
    "ヌートリア",
    "ネコ",
    "ネズミ",
    "ノウサギ",
    "ノヤギ",
    "ハイエナ",
    "バイソン",
    "バク",
    "パグ",
    "ハクジラ",
    "ハクチョウ",
    "ハクビシン",
    "パサン",
    "ハツカネズミ",
    "ハト",
    "ハバナ",
    "パピヨン",
    "ハムスター",
    "ハリネズミ",
    "ハリモグラ",
    "パンダ",
    "ビーグル",
    "ビーバー",
    "ヒガシゴリラ",
    "ビクーニャ",
    "ヒグマ",
    "ビスカーチャ",
    "ビスカッチャ",
    "ビッグホーン",
    "ヒツジ",
    "ヒト",
    "ヒナコウモリ",
    "ヒヒ",
    "ピューマ",
    "ヒョウ",
    "プードル",
    "フェネック",
    "フェレット",
    "フクロネコ",
    "フクロネズミ",
    "フクロモグラ",
    "ブタ",
    "ブルドッグ",
    "ヘラコウモリ",
    "ヘラジカ",
    "ベルーガ",
    "ベンガル",
    "ペンギン",
    "ボノボ",
    "ボブテイル",
    "龍",
    "ホンドギツネ",
    "マーモセット",
    "マーモット",
    "マイルカ",
    "マカク",
    "マガモ",
    "マダコ",
    "マナティー",
    "マルチーズ",
    "マレーバク",
    "マングース",
    "マンクス",
    "マンチカン",
    "マントヒヒ",
    "マンドリル",
    "ミヌエット",
    "ミンククジラ",
    "ムース",
    "ムササビ",
    "メガネザル",
    "メス",
    "モグラ",
    "モモンガ",
    "モルモット",
    "ヤギ",
    "ヤマアラシ",
    "ヤマネ",
    "ヤマネコ",
    "ヤリイカ",
    "ユキウサギ",
    "ユキヒョウ",
    "ライオン",
    "ラクダ",
    "ラクン",
    "ラサアプソ",
    "ラッコ",
    "ラバ",
    "ラフコリー",
    "ラマ",
    "リカオン",
    "リス",
    "リスザル",
    "リャマ",
    "レミング",
    "ローラシア",
    "ロバ",
    "ワピチ",
    "ワラビー",
    "ワラルー",
    "トムソンガゼル",
  ],
];

export const ko = [
  [
    "월요일에",
    "화요일에",
    "수요일에",
    "목요일에",
    "금요일에",
    "토요일에",
    "일요일에",
    "1월에",
    "2월에",
    "3월에",
    "4월에",
    "5월에",
    "6월에",
    "7월에",
    "8월에",
    "9월에",
    "10월에",
    "11월에",
    "12월에",
    "올해에",
    "내년에",
    "내후년에",
    "작년에",
    "재작년에",
    "아침에",
    "새벽에",
    "저녁에",
    "정오에",
    "해질녘에",
    "새벽녘에",
    "오밤중에",
    "자정에",
    "점심시간에",
    "1시에",
    "2시에",
    "3시에",
    "4시에",
    "5시에",
    "6시에",
    "7시에",
    "8시에",
    "9시에",
    "10시에",
    "11시에",
    "12시에",
    "13시에",
    "14시에",
    "15시에",
    "16시에",
    "17시에",
    "18시에",
    "19시에",
    "20시에",
    "21시에",
    "22시에",
    "23시에",
    "24시에",
    "밤만되면",
    "매일",
    "수업중에",
    "회사에서",
    "혼자",
    "눈만뜨면",
    "금방까지",
    "안씻고",
    "일주일째",
    "피시방에서",
    "빈집에서",
    "창고에서",
    "차고에서",
    "소파에서",
    "책상에서",
    "미국에서",
    "중국에서",
    "유럽에서",
    "친구와",
    "회사동료와",
    "아직도",
    "웃으면서",
    "집요하게",
    "비만 오면",
    "눈만 오면",
    "남모르게",
    "태연하게",
    "아침에도",
    "씻지않고",
    "날마다",
    "초원을 지배할",
    "생일날",
    "크리스마스까지",
    "꽃피는 봄에",
    "이유도 없이",
    "갑자기",
    "한달에 한번",
    "우주에서",
    "프랑스에서",
    "아프리카에서",
    "재미없게",
    "정말",
    "너무",
    "아예",
    "심각하게",
    "어이없게",
  ],
  [
    "뭉클한",
    "눈물겨운",
    "서운한",
    "처량한",
    "울적한",
    "위축되는",
    "허탈한",
    "애끓는",
    "애처로운",
    "외로운",
    "후회스러운",
    "울고싶은",
    "북받치는",
    "쓸쓸한",
    "주눅드는",
    "공허한",
    "허전한",
    "침울한",
    "적적한",
    "낙심되는",
    "우울한",
    "참담한",
    "맥빠지는",
    "애틋한",
    "애석한",
    "비참한",
    "풀이 죽은",
    "암담한",
    "무기력한",
    "서글픈",
    "안타까운",
    "마음이 무거운",
    "뭔가 잃은 듯한",
    "기쁜",
    "벅찬",
    "포근한",
    "흐뭇한",
    "상쾌한",
    "짜릿한",
    "시원한",
    "반가운 ",
    "후련한",
    "살맛 나는",
    "신바람 나는",
    "아늑한",
    "흥분되는",
    "온화한",
    "안전한",
    "느긋한",
    "끝내주는",
    "날아 갈 듯한",
    "괜찮은",
    "쌈박한",
    "정다운",
    "그리운",
    "화사한",
    "자유로운",
    "따사로운",
    "감미로운",
    "황홀한",
    "상큼한",
    "평화로운",
    "당황스러운",
    "초조한",
    "무서운",
    "긴장되는",
    "어이없는",
    "억울한",
    "조급한",
    "걱정스러운",
    "참담한",
    "두려운",
    "어리둥절한",
    "놀라운",
    "멍한",
    "조마조마한",
    "막막한",
    "답답한",
    "참을 수 없는",
    "겁나는",
    "섬뜩한",
    "난처한",
    "죽을 것 같은",
    "떨리는",
    "충격적인",
    "위태위태한",
    "기가 막힌",
    "정신이 번쩍 드는",
    "전전긍긍하는",
    "어이없는",
    "살벌한",
    "조바심을 태우는",
    "큰일날 것 같은",
    "활기찬 ",
    "힘찬",
    "생생한",
    "의기 양양한",
    "든든한",
    "격렬한",
    "열렬한",
    "당당한",
    "팔팔한",
    "엄청난",
    "자신만만한",
    "패기만만한",
    "야생마 같은",
    "강렬한",
    "충만한",
    "싱싱한",
    "무기력한",
    "기죽은",
    "넋 나간",
    "왜소한",
    "미세한",
    "부끄러운",
    "쑥스러운",
    "수줍은",
    "멋 적은",
    "민망한",
    "계면쩍은",
    "어색한",
    "미안한",
    "애매한",
    "뻔뻔스러운",
    "어중간한",
    "서투른",
    "묘한",
    "아리송한",
    "자책하는",
    "이상한",
    "창피한",
    "죄스러운",
    "영문모를",
    "쪽팔리는",
    "무거운",
    "캄캄한",
    "혼란스러운",
    "주체할 수 없는",
    "들뜬",
    "피곤한",
    "녹초가 된",
    "마음이 급한",
    "무감각한",
    "무관심한",
  ],
  [
    "블레스복",
    "아이벡스",
    "큰뿔양",
    "돌산양",
    "아메리카들소",
    "히말라야타알",
    "유럽들소",
    "하마",
    "꼬마하마",
    "꽃사슴",
    "야쿠사슴",
    "흰뺨검둥오리",
    "콘돌",
    "독수리",
    "백한",
    "금계",
    "황금계",
    "산계",
    "꼬마홍학 ",
    "큐바홍학",
    "유럽홍학",
    "칠레홍학",
    "큰뿔소",
    "일런드",
    "검둥이원숭이",
    "일본원숭이",
    "돼지꼬리원숭이",
    "보넷원숭이",
    "필리핀원숭이",
    "토쿠원숭이",
    "히말라야원숭이",
    "사바나원숭이",
    "브라자원숭이",
    "올빼미원숭이",
    "다람쥐원숭이",
    "코먼마모셋",
    "검은손기번",
    "에조사슴",
    "물사슴",
    "돼지사슴",
    "고라니",
    "바라싱가",
    "붉은사슴",
    "사불상",
    "다마사슴",
    "노루",
    "단봉낙타",
    "라마",
    "흰손기번",
    "망토원숭이",
    "모나원숭이",
    "삼색다람쥐",
    "과나코",
    "그랜트얼룩말",
    "꽃말",
    "나귀",
    "흰코뿔소",
    "아메리카테이퍼",
    "오랑우탄",
    "로랜드고릴라",
    "침팬지",
    "알락꼬리여우원숭이",
    "다이아나원숭이",
    "슬로우로리스",
    "아시아코끼리",
    "왈라루",
    "동부회색캥거루",
    "붉은캥거루",
    "늑대 ",
    "여우",
    "진돗개",
    "삽살개",
    "너구리",
    "코요테",
    "기니아픽",
    "비버",
    "노랑목도리담비",
    "오소리",
    "유럽불곰",
    "아메리카검정곰",
    "반달가슴곰",
    "말레이곰",
    "수달",
    "남아메리카물개",
    "바다사자",
    "에조불곰",
    "백비심",
    "잔점박이물범",
    "사자",
    "치타",
    "카피바라",
    "마라",
    "렛서팬더",
    "황조롱이",
    "달마수리",
    "말똥가리",
    "흰꼬리수리",
    "올빼미",
    "수리부엉이",
    "큰유황앵무",
    "큰고니",
    "검은고니",
    "두발가락나무늘보",
    "큰기러기",
    "카나다기러기",
    "타조",
    "홍대머리황새",
    "왜가리",
    "흑따오기",
    "홍따오기",
    "노랑부리저어새",
    "아프리카저어새",
    "두루미",
    "재두루미",
    "검은목두루미",
    "큰두루미",
    "쇠재두루미",
    "관학",
    "화식조",
    "그물무늬왕뱀",
    "노랑아나콘다",
    "구렁이",
    "누룩뱀",
    "샴악어",
    "말레이가비알",
    "바다악어",
    "안경카이망",
    "보라찌르레기 ",
    "푸른찌르레기",
    "구관조",
    "문조",
    "십자매",
    "인도공작",
    "인도공작",
    "왕관앵무",
    "오색앵무",
    "큰장수앵무 ",
    "알렉산더앵무",
    "회색앵무",
    "푸른이마아마존앵무",
    "몽크앵무",
    "수다쟁이앵무",
    "금강앵무",
    "홍금강앵무",
    "사랑앵무",
    "재규어",
    "퓨마",
    "시베리아호랑이",
    "표범 ",
    "얼룩무늬하이에나",
    "줄무늬하이에나",
    "킹카쥬",
    "그물무늬기린",
    "마사이기린",
    "겜스복",
    "워터벅",
    "흰오릭스",
    "바바리양",
    "시타퉁가",
    "아시아물소",
    "악어거북",
    "큰개미핥기",
    "분홍펠리칸",
    "홍부리황새",
    "황새",
    "토끼",
    "청금강앵무",
    "풍산개",
    "흑염소",
    "양",
    "염소",
    "에뮤",
    "몽고야생말",
    "아프리카물소",
    "난쟁이카이만",
    "나일악어",
    "남생이",
    "뉴기니아앵무",
    "뉴기니악어",
    "늑대거북",
    "대왕박쥐",
    "돼지코거북",
    "라쿤",
    "레오파드거북",
    "말승냥이",
    "맹글로브뱀",
    "무풀론",
    "미어캣",
    "바위자고새",
    "뱀목거북",
    "버마왕뱀",
    "보더콜리",
    "붉은관유황앵무",
    "볼파이손",
    "붉은코뿔새",
    "사막여우",
    "비어드드레곤",
    "아시아큰거북",
    "알비노버마왕뱀",
    "자카스펭귄",
    "붉은코코아티",
    "금개구리",
    "스라소니",
    "초록이구아나",
    "미니피그",
    "토코투칸",
    "왕관비둘기",
    "보라부채머리",
    "백색유황앵무",
    "고핀",
    "큰소쩍새",
    "긴점박이올빼미",
    "인도별거북",
    "자라",
    "맹그로브뱀",
    "샌드피쉬",
    "북방산개구리",
    "장수풍뎅이",
    "애사슴벌레",
    "넓적사슴벌레",
    "두꺼비",
    "중국장수도롱뇽",
    "톱사슴벌레",
    "왕사슴벌레",
    "물방개",
    "검정물방개",
    "참매",
    "일본원숭이",
    "불개",
    "점박이물범",
    "남방큰돌고래",
    "큰돌고래",
    "도롱뇽",
    "맨드릴",
    "불개",
    "샤망",
    "토끼",
    "붉은관부채머리",
    "삵",
    "쌍봉낙타",
    "다람쥐원숭이",
    "기니피그",
    "큰개미핥개",
    "미니피그",
    "문조",
    "십자매",
    "일본원숭이",
    "코먼마모셋",
    "삼색다람쥐",
    "나귀",
    "진돗개",
    "삽살개",
    "풍산개",
    "흑염소",
    "난쟁이카이만",
    "붉은모란앵무",
  ],
];

export const en = [
  [
    "Accidentally",
    "Angrily",
    "Awkwardly",
    "Beautifully",
    "Blindly",
    "Boldly",
    "Bravely",
    "Brightly",
    "Busily",
    "Calmly",
    "Carefully",
    "Cautiously",
    "Cheerfully",
    "Clearly",
    "Courageously",
    "Deliberately",
    "Eagerly",
    "Easily",
    "Elegantly",
    "Enormously",
    "Enthusiastically",
    "Faithfully",
    "Fast",
    "Peacefully",
    "Fiercely",
    "Fondly",
    "Foolishly",
    "Gently",
    "Gladly",
    "Gracefully",
    "Greedily",
    "Happily",
    "Hastily",
    "Healthily",
    "Honestly",
    "Hungrily",
    "Hurriedly",
    "Innocently",
    "Irritably",
    "Joyously",
    "Justly",
    "Kindly",
    "Lazily",
    "Loosely",
    "Loudly",
    "Madly",
    "Charmingly",
    "Mysteriously",
    "Neatly",
    "Nervously",
    "Noisily",
    "Obediently",
    "Openly",
    "Painfully",
    "Patiently",
    "Perfectly",
    "Politely",
    "Poorly",
    "Powerfully",
    "Promptly",
    "Punctually",
    "Quickly",
    "Quietly",
    "Rapidly",
    "Rarely",
    "Recklessly",
    "Reluctantly",
    "Repeatedly",
    "Rightfully",
    "Roughly",
    "Rudely",
    "Sadly",
    "Selfishly",
    "Sensibly",
    "Seriously",
    "Sharply",
    "Shyly",
    "Silently",
    "Sleepily",
    "Slowly",
    "Smoothly",
    "Softly",
    "Solemnly",
    "Speedily",
    "Stealthily",
    "Sternly",
    "Stupidly",
    "Successfully",
    "Suspiciously",
    "Swiftly",
    "Tenderly",
    "Tensely",
    "Thoughtfully",
    "Tightly",
    "Unexpectedly",
    "Victoriously",
    "Violently",
    "Vivaciously",
    "Warmly",
    "Weakly",
    "Wearily",
    "Wildly",
    "Wisely",
  ],
  [
    "riding",
    "sitting",
    "standing",
    "fighting",
    "laughing",
    "reading",
    "playing",
    "listening",
    "crying",
    "thinking",
    "singing",
    "watching",
    "dancing",
    "turning",
    "winning",
    "flying",
    "lying",
    "running",
    "sleeping",
    "writing",
    "checking",
    "jumping",
    "drawing",
    "drinking",
    "cooking",
    "washing",
    "waiting",
    "climbing",
    "talking",
    "crawling",
    "dreaming",
    "digging",
    "clapping",
    "knitting",
    "sewing",
    "smelling",
    "kissing",
    "hugging",
    "snoring",
    "bathing",
    "bowing",
    "painting",
    "diving",
    "skiing",
    "stacking",
    "buying",
    "shaking",
    "busy",
    "cleaning",
    "courageous",
    "delightful",
    "bored",
    "calm",
    "clear",
    "comfortable",
    "disturbed",
    "depressed",
    "dizzy",
    "determined",
    "curious",
    "clever",
    "cheerful",
    "bright",
    "brainy",
    "encouraging",
    "eager",
    "fine",
    "frightened",
    "gifted",
    "healthy",
    "hilarious",
    "helpful",
    "innocent",
    "jealous",
    "happy",
    "gorgeous",
    "gentle",
    "friendly",
    "funny",
    "fancy",
    "fair",
    "excited",
    "enthusiastic",
    "energetic",
    "famous",
    "enchanting",
    "lazy",
    "lucky",
    "precious",
    "rich",
    "successful",
    "puzzled",
    "shy",
    "sparkling",
    "outstanding",
    "old-fashioned",
    "mysterious",
    "motionless",
    "long",
    "magnificent",
    "lively",
    "lovely",
    "misty",
    "nice",
    "pleasant",
    "powerful",
    "strange",
    "talented",
    "tired",
    "witty",
    "victorious",
    "zealous",
    "thoughtful",
    "troubled",
    "uninterested",
    "upset",
    "wild",
    "thoughtless",
    "tense",
    "ugly",
    "cute",
    "admirable",
    "moderate",
    "chill",
    "wandering",
    "wide-eyed",
    "super",
    "selfish",
    "relieved",
    "proud",
    "beautiful",
    "chubby",
    "elegant",
    "fit",
    "glamorous",
    "dazzling",
    "short",
    "attractive",
    "alive",
    "jolly",
    "polite",
    "wonderful",
    "silly",
    "thankful",
  ],
  [
    "bat",
    "marten",
    "kiwi",
    "gazelle",
    "starfish",
    "chickadee",
    "albatross",
    "duck",
    "puma",
    "whippet",
    "crane fly",
    "turtle",
    "blackbird",
    "catfish",
    "scallop",
    "wren",
    "tapir",
    "macaw",
    "centipede",
    "spider",
    "hoverfly",
    "impala",
    "wolf",
    "manta ray",
    "fish",
    "emu",
    "magpie",
    "lemur",
    "cicada",
    "sloth",
    "trout",
    "lamprey",
    "dinosaur",
    "vole",
    "crane",
    "flamingo",
    "walrus",
    "reindeer",
    "spoonbill",
    "bird",
    "dolphin",
    "mink",
    "puffin",
    "gorilla",
    "chameleon",
    "marmot",
    "aardwolf",
    "cardinal",
    "fancy rat",
    "mollusk",
    "eagle",
    "prawn",
    "gecko",
    "dormouse",
    "booby",
    "xerinae",
    "tortoise",
    "parrot",
    "wombat",
    "planarian",
    "squid",
    "grouse",
    "panda",
    "finch",
    "crayfish",
    "guanaco",
    "tahr",
    "termite",
    "angelfish",
    "perch",
    "rat",
    "tarantula",
    "pony",
    "skink",
    "hornet",
    "silkworm",
    "bug",
    "tiglon",
    "cheetah",
    "jaguar",
    "marlin",
    "shrimp",
    "ape",
    "pinniped",
    "raccoon",
    "goose",
    "piranha",
    "gibbon",
    "primate",
    "firefly",
    "urial",
    "harrier",
    "gamefowl",
    "canidae",
    "swordtail",
    "red panda",
    "anteater",
    "bison",
    "rodent",
    "earthworm",
    "narwhal",
    "whale",
    "dingo",
    "cow",
    "catshark",
    "hamster",
    "orca",
    "jellyfish",
    "hyena",
    "camel",
    "mammal",
    "marsupial",
    "swan",
    "felidae",
    "wildcat",
    "sea slug",
    "lobster",
    "galliform",
    "vulture",
    "wasp",
    "quail",
    "capybara",
    "tree frog",
    "bonobo",
    "mosquito",
    "worm",
    "chipmunk",
    "pheasant",
    "newt",
    "badger",
    "meerkat",
    "tarsier",
    "antlion",
    "leech",
    "moth",
    "parakeet",
    "deer",
    "quokka",
    "iguana",
    "mandrill",
    "blue bird",
    "clam",
    "ermine",
    "penguin",
    "echidna",
    "louse",
    "buzzard",
    "crab",
    "pigeon",
    "scorpion",
    "canid",
    "stork",
    "platypus",
    "crow",
    "sturgeon",
    "wallaby",
    "octopus",
    "orangutan",
    "mongoose",
    "beetle",
    "asp",
    "bobolink",
    "stingray",
    "quelea",
    "elephant",
    "kite",
    "tiger",
    "crawdad",
    "fowl",
    "swift",
    "fly",
    "moose",
    "sawfish",
    "lark",
    "baboon",
    "coral",
    "leopon",
    "haddock",
    "bear",
    "hare",
    "possum",
    "herring",
    "zebra",
    "shark",
    "peafowl",
    "loon",
    "carp",
    "manatee",
    "beaver",
    "takin",
    "bedbug",
    "sailfish",
    "swallow",
    "partridge",
    "limpet",
    "porcupine",
    "cuckoo",
    "ocelot",
    "weasel",
    "lungfish",
    "viper",
    "bandicoot",
    "opossum",
    "locust",
    "pelican",
    "cockroach",
    "boar",
    "toucan",
    "thrush",
    "dove",
    "caribou",
    "boa",
    "tick",
    "barracuda",
    "mule",
    "mole",
    "alligator",
    "amphibian",
    "jay",
    "sole",
    "giraffe",
    "anaconda",
    "gerbil",
    "bobcat",
    "condor",
    "monkey",
    "squirrel",
    "hawk",
    "tuna",
    "heron",
    "fox",
    "eel",
    "halibut",
    "lizard",
    "otter",
    "turkey",
    "pike",
    "basilisk",
    "gull",
    "mackerel",
    "rooster",
    "rook",
    "dragonfly",
    "frog",
    "flea",
    "whitefish",
    "gopher",
    "skunk",
    "hedgehog",
    "mastodon",
    "python",
    "snake",
    "aardvark",
    "clownfish",
    "warbler",
    "snail",
  ],
];

export const zh = [
  [
    "在下午13点",
    "在下午14点",
    "在下午15点",
    "在下午16点",
    "在下午17点",
    "在下午18点",
    "在下午19点",
    "在下午20点",
    "在晚上",
    "在早上",
    "在学校",
    "在商店",
    "在饭店",
    "在咖啡",
    "在机场",
    "在运动场",
    "在家",
    "在百货商店",
    "在公司",
    "在洗手间",
    "在游乐园",
    "在公园",
    "在星期一",
    "在星期二",
    "在星期三",
    "在星期四",
    "在星期五",
    "在星期六",
    "在星期日",
    "在1月",
    "在2月",
    "在3月",
    "在4月",
    "在5月",
    "在6月",
    "在7月",
    "在8月",
    "在9月",
    "在10月",
    "在11月",
    "在12月",
    "在春天",
    "在夏天",
    "在秋天",
    "在冬天",
    "今年",
    "去年",
    "明年",
    "后年",
    "在沙发",
    "在一个人",
    "和朋友",
    "和爸爸",
    "和妈妈",
    "和姐姐",
    "和妹妹",
    "和弟弟",
    "和小狗",
    "和小猫",
    "在圣诞节",
    "在万圣节",
    "在教室",
    "在上课中",
    "在美国",
    "在法国",
    "在韩国",
    "在日本",
    "在英国",
    "在中国",
    "一个月一次",
    "在凌晨",
    "在朋友家",
    "在办公室",
    "在美容院",
    "在医院",
    "在超市",
    "在邮局",
    "在开车的",
    "在跳舞的",
    "在吃饭色",
    "在做饭的",
    "在写作业的",
    "在学习的",
    "在考考试的",
    "在找东西的",
    "在唱歌的",
    "在躺着的",
    "在睡觉的",
    "在吵架的",
    "在打工的",
    "在邮局",
    "在图书馆",
    "在体育馆",
    "在药店",
    "在餐厅",
    "在银行",
    "在厨房",
    "在警局",
    "在路上",
    "在回家的",
    "在地铁的",
    "在巴士的",
    "在电梯的",
    "在植物园的",
    "在酒店的",
    "在楼上",
    "在楼下",
    "在宿舍",
    "在书店",
    "在健身房",
    "在跑步",
    "在买东西的",
    "在学院",
    "在消防署",
    "在电影院",
    "在火车站",
    "在出租车",
    "在理发店",
    "在休息的",
    "在吃苹果的",
    "在坐飞机的",
    "在说话的",
    "在喝水",
    "在穿衣服的",
    "在找东西的",
    "下雨天",
    "突然",
    "在客厅",
    "在网吧",
    "在放学的",
    "在下班的",
    "在傍晚",
    "今晚",
    "在市场",
    "在便利店",
    "在观览车",
    "在停车场",
    "在游泳池",
    "笑着",
    "在踢足球",
    "在玩手机",
  ],
  [
    "流泪的",
    "思考的",
    "眨眼睛的",
    "打哈欠的",
    "打喷嚏的",
    "发抖的",
    "饥饿的",
    "落下的",
    "融化的",
    "沉浸的",
    "长大的",
    "有自信的",
    "自满的",
    "委屈的",
    "累的",
    "烦人的",
    "沉睡的",
    "安静的",
    "重的",
    "滑动的",
    "意识到的",
    "失眠的",
    "离开的",
    "迷路的",
    "放松的",
    "祈祷的",
    "患难的",
    "无聊的",
    "疼痛的",
    "冲动的",
    "醉的",
    "厌倦的",
    "忍受的",
    "怀疑的",
    "流浪的",
    "自豪的",
    "爱恋的",
    "陷阱的",
    "叹息的",
    "惊喜的",
    "出走的",
    "赞美的",
    "理解的",
    "回忆的",
    "事情严重的",
    "活泼的",
    "强烈的",
    "新鲜的",
    "健康的",
    "猥琐的",
    "尴尬的",
    "矛盾的",
    "模糊的",
    "兴奋的",
    "匆忙的",
    "奇怪的",
    "不可思议",
    "恼火的",
    "压抑的",
    "严肃的",
    "惊慌失措的",
    "平淡的",
    "热衷的",
    "舒适的",
    "微笑着的",
    "勇敢的",
    "幼稚的",
    "着急的",
    "困惑的",
    "犹豫的",
    "幻想的",
    "心酸的",
    "空虚的",
    "疲惫的",
    "安心的",
    "懒惰的",
    "无力的",
    "混乱的",
    "解脱的",
    "爽快的",
    "清新的",
    "可惜的",
    "安全的",
    "舒服的",
    "苦恼的",
    "没有自信的",
    "做梦的",
    "厌倦的",
    "看颜色的",
    "宽心的",
    "自私的",
    "胆小的",
    "没有关注的",
    "吃饱的",
    "不舒服的",
    "不开心的",
    "受不了的",
    "吃力的",
    "感慨的",
    "寂寞的",
    "忧虑的",
    "痴迷的",
    "郁闷的",
    "幸福的",
    "忐忑的",
    "可惜的",
    "不安的",
    "高兴的",
    "喜欢的",
    "害怕的",
    "想念的",
    "紧张的",
    "伤心的",
    "担心的",
    "开心的",
    "生气的",
    "惊讶的",
    "嫉妒的",
    "厌恶的",
    "满足的",
    "感动的",
    "欣赏的",
    "吃惊的",
    "沮丧的",
    "散步的",
    "孤独的",
    "同情的",
    "幽默的",
    "骄傲的",
    "羡慕的",
    "安慰的",
    "悲伤的",
    "失望的",
    "平静的",
    "害羞的",
    "心动的",
    "愉快的",
    "装帅的",
    "装可爱的",
    "想哭的",
    "好奇的",
    "焦虑的",
    "沮丧的",
    "发狂的",
    "抱怨的",
    "疲劳的",
  ],
  [
    "小猫",
    "小狗",
    "小猪",
    "飞鸟",
    "皮皮虾",
    "鱼儿",
    "花朵",
    "森林",
    "画家",
    "诗人",
    "音乐家",
    "艺术家",
    "蜘蛛侠",
    "钢铁侠",
    "魔法师",
    "精灵",
    "野马",
    "海豚",
    "熊猫",
    "狐狸",
    "蝴蝶",
    "猩猩",
    "宝宝",
    "小仙女",
    "蜜蜂",
    "蚂蚁",
    "蝗虫",
    "蜈蚣",
    "蜻蜓",
    "海龟",
    "蜥蜴",
    "猫头鹰",
    "蝌蚪",
    "鳄鱼",
    "蟋蟀",
    "蛇",
    "蝉",
    "狼",
    "熊",
    "狮子",
    "猴子",
    "兔子",
    "海豹",
    "鲨鱼",
    "海马",
    "鹿",
    "羊",
    "鸵鸟",
    "大象",
    "斑马",
    "长颈鹿",
    "老虎",
    "鸽子",
    "犀牛",
    "骆驼",
    "刺猬",
    "鹦鹉",
    "海鸥",
    "螃蟹",
    "鸭子",
    "鼹鼠",
    "海驴",
    "老鼠",
    "美洲豹",
    "萤火虫",
    "鹰雀",
    "独角兽",
    "河马",
    "龙虾",
    "龙",
    "乌龟",
    "海獭",
    "蝙蝠",
    "青蛙",
    "牛",
    "鸡",
    "豺狼",
    "鳄鸟",
    "驴子",
    "乌鸦",
    "松鼠",
    "袋鼠",
    "蜘蛛",
    "蚊子",
    "金鱼",
    "猫头鹰",
    "孔雀",
    "燕子",
    "喜鹊",
    "天鹅",
    "黄鹂",
    "白鹤",
    "鹤",
    "鸥鸟",
    "冒险家",
    "小熊猫",
    "火烈鸟",
    "金刚",
    "魔龙",
    "蜗牛",
    "蚂蚁熊",
    "蚁后",
    "萌萌兔",
    "耗子",
    "水虎鱼",
    "飞鹰",
    "鬣狗",
    "北极熊",
    "食蚁兽",
    "小鸡",
    "企鹅",
    "快乐的小狗",
    "黄牛",
    "蚯蚓",
    "瓢虫",
    "山兔",
    "野狗",
    "黑猪",
    "乌骨鸡",
    "大蟒",
    "信天翁",
    "啄木鸟",
    "啼鸟",
    "鹭鸟",
    "孔雀鱼",
    "鸮",
    "翠鸟",
    "王企鹅",
    "小企鹅",
    "小鹅苗",
    "树懒",
    "针尾雀",
    "杀手鲸",
    "印度犀牛",
    "松鼠猴",
    "金雕",
    "丹顶鹤",
    "象龟",
    "大飞翔鱼",
    "竹节虫",
    "银鱼",
    "南美鹦鹉",
    "白眉长臂猿",
    "白唇鹿",
    "双角犀牛",
    "水牛",
    "针鼹鼠",
    "帝王蝶",
    "黑犀牛",
    "金丝猴",
    "蓝眼考拉",
    "考拉",
    "长毛象",
    "长颈鲸",
    "蓝眼猫",
    "长颈蛇",
    "长尾猴",
    "奇翼龙",
    "巨型章鱼",
    "长嘴鳄鱼",
    "剑尾鱼",
    "蓝鸟",
    "灵龟",
    "长鳍鱼",
    "巨蜥蜴",
    "秃鹰",
    "野牛",
    "灰狼",
    "美洲狮",
    "驯鹿",
    "地松鼠",
    "白尾鹿",
    "基奇峡谷精灵",
    "火狐狸",
    "树袋熊",
    "珠母蟹",
    "美国貂",
    "高原羊",
    "北美野猪",
    "青鳉鱼",
    "鼯鼠",
    "猫鼬",
    "虎鲸",
    "水獺",
    "豚鼠",
    "鹈鹕",
    "水黾虫",
    "杜鹃",
    "金龟子",
    "鹤",
    "锹形虫",
    "幼虫",
    "灰鼠",
    "吉娃娃",
    "秋田犬",
    "巴哥犬",
    "西施犬",
    "合士奇",
    "松狮",
    "沙漠狐狸",
    "艾鼬",
  ],
];
