import Modal from "styled-react-modal";
import styled from "styled-components";
import colors from "../../styles/colors";
import profileExchangeIcon from "../../assets/images/ProfileExchange_20_Gray900@3x.png";
import { ImageSourceButton } from "../../styles/commonStyles";

export const ProfileExchangeIcon = styled(ImageSourceButton).attrs({
  source: `${profileExchangeIcon}`,
})`
  width: 20px;
  height: 20px;
`;

export const ModalBackground = styled.div`
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 1000;
`;

export const StyledModal = Modal.styled`

  position: relative;
  flex-direction: column;
  display: flex;
  z-index: 1000;
  width: 100vw;
`;

export const Container = styled.div`
  display: flex;
  height: 100vh;
  flex-direction: column;
  padding-bottom: 130px;
  background-color: ${({ theme }) => theme.modeColors.background_01};
  overflow: scroll;
`;

export const StickyHeader = styled.div`
  position: sticky;
  top: 0px;
  z-index: 1000;
  background-color: ${({ theme }) => theme.modeColors.background_01};
`;

export const ContentContainer = styled.div`
  /* overflow: scroll; */
  padding-bottom: 130px;
`;

export const FeedItemContainer = styled.div`
  min-height: calc(100vh - 130px);
`;
