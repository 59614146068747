import styled from "styled-components";

import coneIcon from "../../assets/images/img64ColorCone@3x.png";
import colors from "../../styles/colors";
import Modal from "styled-react-modal";

export const ModalBackground = styled.div`
  width: 100vw;
  height: 100%;
  position: fixed;
  z-index: 9999;
`;

export const StyledModal = Modal.styled`
  background-color: ${colors.White};
  position: relative;
  height: 100vh;
  flex-direction: column;
  display: flex;
  z-index: 1000;
  width: 100vw;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${({ theme }) => theme.modeColors.background_01};
  height: calc(
    100vh -
      ${(props: { subtractHeight?: number }) => props.subtractHeight || 0}px
  );
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0px 30px;
`;

export const ConeIcon = styled.img.attrs({
  src: `${coneIcon}`,
})`
  width: 64px;
  height: 64px;
  margin-bottom: 12px;
`;
