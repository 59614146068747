import React from "react";
import { PromotionDataType } from "../../features/promotion/promotionSlice";
import dayjs from "dayjs";
import ShowTranslation from "../ShowTranslation";
import RemainingTimeCounter from "../RemainingTimeCounter";
import {
  DiscountMainContainer,
  DiscountSubContainer,
  DiscountTextContainer,
  FreeMainContainer,
  GradientBorderContainer,
  ProfileExchangeDiscountIcon,
  ProfileExchangeIcon,
  RightArrowIcon,
  SubContainer,
  TimeContainer,
} from "./styles";
import colors from "../../styles/colors";
import { FreeProfileExchangeItemNoticeToolTip } from "../../systems";
import getTranslation from "../../common/utils/getTranslation";

interface ProfileExchangeItemBannerProps {
  onPressBanner?: () => void;
  profileExchangeItemCount?: number;
  freePromotionData?: PromotionDataType["profile_exchange"];
  discountPromotionData?: PromotionDataType["profile_exchange_3_discount"];
}

const ProfileExchangeItemBanner = ({
  onPressBanner,
  profileExchangeItemCount = 0,
  freePromotionData,
  discountPromotionData,
}: ProfileExchangeItemBannerProps) => {
  if (
    (!discountPromotionData &&
      freePromotionData &&
      freePromotionData.status === "ENABLED") ||
    (profileExchangeItemCount && profileExchangeItemCount > 0)
  ) {
    return (
      <>
        <GradientBorderContainer>
          <FreeMainContainer>
            <SubContainer>
              <ProfileExchangeIcon />
              <ShowTranslation
                color={colors.Black}
                weight="b"
                size={15}
                style={{ marginLeft: 8 }}
              >
                {getTranslation("ProfileExchangeNudge.profileExchangeItem")}
              </ShowTranslation>
            </SubContainer>

            <ShowTranslation size={15} weight="m" color={colors.Black}>
              {getTranslation("Common.numberOfItemsN", {
                "{n}": profileExchangeItemCount,
              })}
            </ShowTranslation>
          </FreeMainContainer>
        </GradientBorderContainer>
        {profileExchangeItemCount > 0 && freePromotionData ? (
          <FreeProfileExchangeItemNoticeToolTip />
        ) : null}
      </>
    );
  }

  if (
    discountPromotionData &&
    discountPromotionData.status === "ENABLED" &&
    discountPromotionData.endTime &&
    dayjs().isBefore(discountPromotionData.endTime)
  ) {
    return (
      <>
        <GradientBorderContainer onClick={onPressBanner}>
          <DiscountMainContainer>
            <ProfileExchangeDiscountIcon />

            <DiscountSubContainer>
              <DiscountTextContainer>
                <TimeContainer>
                  <ShowTranslation
                    color={colors.Blue700}
                    size={13}
                    weight="b"
                    style={{ marginRight: 3 }}
                  >
                    {getTranslation("ProfileExchangeNudge.untilBenefitEnds")}
                  </ShowTranslation>

                  <RemainingTimeCounter
                    expireTime={discountPromotionData?.endTime}
                    color={"Blue700"}
                    size={13}
                    weight={"b"}
                    format="mm:ss"
                  />
                </TimeContainer>

                <ShowTranslation color={colors.Black} weight="b" size={15}>
                  {getTranslation(
                    "ProfileExchangeNudge.profileExchange60PercentOffBenefit"
                  )}
                </ShowTranslation>
              </DiscountTextContainer>

              <RightArrowIcon />
            </DiscountSubContainer>
          </DiscountMainContainer>
        </GradientBorderContainer>
      </>
    );
  }

  return null;
};

function shouldMemo(
  prevProps: ProfileExchangeItemBannerProps,
  nextProps: ProfileExchangeItemBannerProps
) {
  return (
    prevProps.profileExchangeItemCount === nextProps.profileExchangeItemCount &&
    prevProps.discountPromotionData?.status ===
      nextProps.discountPromotionData?.status &&
    prevProps.freePromotionData?.status === nextProps.freePromotionData?.status
  );
}

export default React.memo(ProfileExchangeItemBanner, shouldMemo);
