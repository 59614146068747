import React from "react";

import { useAppSelector, useAppDispatch } from "../../common/hooks/reduxHooks";
import {
  selectToolTips,
  setToolTips,
} from "../../features/globalAppState/globalAppStateSlice";
import getTranslation from "../../common/utils/getTranslation";
import { ToolTipProps } from "../../components/ToolTip/types";
import { ToolTip } from "../../components";

const ProfileExchangeToolTip = () => {
  const dispatch = useAppDispatch();
  const { PROFILE_EXCHANGE_VISIBLE } = useAppSelector(selectToolTips);

  const toolTipProps: ToolTipProps = {
    isVisible: PROFILE_EXCHANGE_VISIBLE,
    title: getTranslation("ProfileExchangeToolTip.title") as string,
    content: getTranslation("ProfileExchangeToolTip.content") as string,
    onClose: () => {
      dispatch(
        setToolTips({ key: "PROFILE_EXCHANGE_VISIBLE", isVisible: false })
      );
    },
    triangleType: "TOP",
    triangleHorizontalPosition: {
      left: 11,
    },
    toolTipPosition: {
      left: 20,
      top: 49,
    },
  };

  return <ToolTip {...toolTipProps} />;
};

export default ProfileExchangeToolTip;
