import { useCallback, useEffect, useState } from "react";
import dayjs from "dayjs";
import colors from "../../styles/colors";
import ShowTranslation from "../ShowTranslation";

interface RemainingTimeCounterProps {
  expireTime: string;
  color: keyof typeof colors;
  size: number;
  weight: "b" | "l" | "m" | "r";
  format: "hh:mm:ss" | "mm:ss";
}

const RemainingTimeCounter = ({
  expireTime,
  size = 13,
  color = "Gray900",
  weight = "m",
  format = "mm:ss",
}: RemainingTimeCounterProps) => {
  const calculateRemainingTime = useCallback(() => {
    const now = dayjs();
    const expire = dayjs(expireTime);

    const remainingTimeInSeconds = expire.diff(now, "seconds");

    const hours = Math.floor(remainingTimeInSeconds / 3600);
    const minutes =
      format === "hh:mm:ss"
        ? Math.floor((remainingTimeInSeconds % 3600) / 60)
        : Math.floor(remainingTimeInSeconds / 60);
    const seconds = remainingTimeInSeconds % 60;

    return {
      hours: String(hours).padStart(2, "0"),
      minutes: String(minutes).padStart(2, "0"),
      seconds: String(seconds).padStart(2, "0"),
      remainingTimeInSeconds,
    };
  }, [expireTime, format]);

  const [time, setTime] = useState(calculateRemainingTime);

  useEffect(() => {
    const timer = setInterval(() => {
      const newTime = calculateRemainingTime();
      setTime(newTime);

      if (newTime.remainingTimeInSeconds <= 0) {
        clearInterval(timer);
      }
    }, 1000);

    return () => clearInterval(timer);
  }, [expireTime, calculateRemainingTime]);

  if (time.remainingTimeInSeconds <= 0) {
    return (
      <ShowTranslation size={size} weight={weight} color={colors[color]}>
        {format === "hh:mm:ss" ? "00:00:00" : "00:00"}
      </ShowTranslation>
    );
  }

  return (
    <ShowTranslation size={size} weight={weight} color={colors[color]}>
      {format === "hh:mm:ss"
        ? `${time.hours}:${time.minutes}:${time.seconds}`
        : `${time.minutes}:${time.seconds}`}
    </ShowTranslation>
  );
};

export default RemainingTimeCounter;
