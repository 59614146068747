import React, { CSSProperties } from "react";
import getTranslation from "../../common/utils/getTranslation";

import { Container, LinkText, InnerText } from "./style";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";

const FeedHiddenContent = ({
  onClickLearnMore,
  reportCount,
  hiddenType,
  style,
}: {
  onClickLearnMore?: () => void;
  reportCount?: number;
  hiddenType:
    | "NONE"
    | "CS"
    | "REPORT"
    | "ALL"
    | "OTHER_USERS"
    | "BY_REPORT"
    | "OTHERS";
  style?: CSSProperties;
}) => {
  const onClick = () => {
    ReactNativeWebview.postMessageToApp({
      type: "NAVIGATE_TO_FEED_RULE_WEBVIEW",
    });
  };

  if (reportCount) {
    return (
      <Container style={{ ...style }}>
        <InnerText>
          {getTranslation("FeedCommon.isHiddenFeedByReports", {
            "{n}": reportCount,
          })}
          {hiddenType === "ALL" ||
          hiddenType === "OTHER_USERS" ||
          hiddenType === "OTHERS" ||
          hiddenType === "BY_REPORT" ? null : (
            <LinkText onClick={onClickLearnMore}>
              {getTranslation("FeedCommon.viewDetail")}
            </LinkText>
          )}
        </InnerText>
      </Container>
    );
  }

  return (
    <Container style={{ ...style }}>
      <InnerText>
        {getTranslation("FeedCommon.isHiddenFeed")}
        {hiddenType === "ALL" ||
        hiddenType === "OTHER_USERS" ||
        hiddenType === "OTHERS" ||
        hiddenType === "BY_REPORT" ? null : (
          <LinkText onClick={onClick}>
            {getTranslation("FeedCommon.viewDetail")}
          </LinkText>
        )}
      </InnerText>
    </Container>
  );
};

export default FeedHiddenContent;
