import { ReactComponent as ic_graphic_lightdark_filled_dark } from "./ic_graphic_lightdark_filled_dark.svg";
import { ReactComponent as ic_graphic_lightdark_filled_light } from "./ic_graphic_lightdark_filled_light.svg";
import { ReactComponent as ic_graphic_paper_filled_dark } from "./ic_graphic_paper_filled_dark.svg";
import { ReactComponent as ic_graphic_paper_filled_light } from "./ic_graphic_paper_filled_light.svg";
import { ReactComponent as ic_graphic_upbluecircle } from "./ic_graphic_upbluecircle.svg";
import { ReactComponent as ic_graphic_upfeed_filled_dark } from "./ic_graphic_upfeed_filled_dark.svg";
import { ReactComponent as ic_graphic_upfeed_filled_light } from "./ic_graphic_upfeed_filled_light.svg";
import { ReactComponent as ic_graphic_warning_filled_dark } from "./ic_graphic_warning_filled_dark.svg";
import { ReactComponent as ic_graphic_warning_filled_light } from "./ic_graphic_warning_filled_light.svg";
import { ReactComponent as ic_graphic_corn_filled_dark } from "./ic_graphic_corn_filled_dark.svg";
import { ReactComponent as ic_graphic_corn_filled_light } from "./ic_graphic_corn_filled_light.svg";
import { ReactComponent as ic_graphic_closecircle01_filled } from "./ic_graphic_closecircle01_filled.svg";
import { ReactComponent as ic_graphic_closecircle02_filled } from "./ic_graphic_closecircle02_filled.svg";

const graphicIcons = {
  ic_graphic_lightdark_filled_dark,
  ic_graphic_lightdark_filled_light,
  ic_graphic_paper_filled_dark,
  ic_graphic_paper_filled_light,
  ic_graphic_upbluecircle,
  ic_graphic_upfeed_filled_dark,
  ic_graphic_upfeed_filled_light,
  ic_graphic_warning_filled_dark,
  ic_graphic_warning_filled_light,
  ic_graphic_corn_filled_dark,
  ic_graphic_corn_filled_light,
  ic_graphic_closecircle01_filled,
  ic_graphic_closecircle02_filled,
};

export default graphicIcons;
