import axios, { AxiosResponse } from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import apis from "../../api/apis";

import { snsAxiosInstance } from "../../common/utils/axios";
type CreateFeedArgs = {
  title?: string;
  text: string;
  access: "PUBLIC" | "PRIVATE";
  contentType: "NORMAL" | "AUDIO" | "IMAGE" | "GIF" | "CALL_ROOM";
  contentURL: Array<{ url: string }>;
  contentDuration: number;
  callRoomId?: string;
};

const createFeed = createAsyncThunk(
  "feedWrite/createfeed",
  async (data: CreateFeedArgs, { rejectWithValue }) => {
    try {
      const response = await snsAxiosInstance.post(apis.feed.feedPost, data);
      return response.data;
    } catch (error: any) {
      if (error.response.data.message === "BLACK_TYPE_USER") {
        return rejectWithValue("BLACK_TYPE_USER");
      }
      return rejectWithValue("FAILED_WRITE_FEED");
    }
  }
);

const createFeedNotice = createAsyncThunk(
  "feedWrite/createFeedNotice",
  async (data: CreateFeedArgs, { rejectWithValue }) => {
    try {
      const response = await snsAxiosInstance.post(apis.feed.feedNotice, data);
      return response.data;
    } catch (error) {
      return rejectWithValue("FAILED_WRITE_FEED");
    }
  }
);

type CreateCallRoomArgs = {
  keyword: string;
  keyword_name: string;
  title: string;
  description: string;
  max_member_count: number;
  open_type: "OPEN" | "SCHEDULED";
  scheduled_opening_time?: string;
};

const createCallRoom = createAsyncThunk(
  "feedWrite/createCallRoom",
  async (data: CreateCallRoomArgs, { rejectWithValue }) => {
    try {
      const response = await snsAxiosInstance.post(apis.callRoom.create, data);
      return response.data;
    } catch (error: any) {
      if (
        error.response.data.res_code === "FAILED_OVER_MAX_SCHEDULED_ROOM_COUNT"
      ) {
        return rejectWithValue("FAILED_OVER_MAX_SCHEDULED_ROOM_COUNT");
      }
      return rejectWithValue("FAILED_CREATE_CALL_ROOM");
    }
  }
);

export { createFeed, createFeedNotice, createCallRoom };
