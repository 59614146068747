import React, { useEffect, useState } from "react";

import { useLocation, useNavigate } from "react-router-dom";

import {
  selectShowAlertStatus,
  setShowAlertStatus,
} from "../../features/bookmark/bookmarkSlice";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import AlertModal from "../../components/AlertModal";
import getTranslation from "../../common/utils/getTranslation";
import { fetchBookmarkList } from "../../features/bookmark/bookmarkThunks";
import { fetchFeed } from "../../features/feedList/feedListThunks";
import { selectFeedNextPageIds } from "../../features/feedList/feedListSlice";

const BookmarkErrorModal = () => {
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;

  const showAlertStatus = useAppSelector(selectShowAlertStatus);
  const feedNextPageIds = useAppSelector(selectFeedNextPageIds);

  const [isVisible, setIsVisible] = useState(false);

  const refreshFeed = async () => {
    await dispatch(
      fetchFeed({
        fetchType: "REFRESH",
        page: feedNextPageIds.recommend,
        category: "recommend",
      })
    );

    await dispatch(
      fetchFeed({
        fetchType: "REFRESH",
        page: feedNextPageIds.latest,
        category: "latest",
      })
    );

    await dispatch(
      fetchFeed({
        fetchType: "REFRESH",
        page: feedNextPageIds.my,
        category: "my",
      })
    );
  };

  useEffect(() => {
    if (showAlertStatus) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  }, [showAlertStatus, dispatch]);

  const onClose = async () => {
    dispatch(setShowAlertStatus(false));
    setIsVisible(false);

    if (pathname.split("/")[1] === "feed_detail") {
      navigate(-1);
    }

    refreshFeed();

    await dispatch(fetchBookmarkList({ fetchType: "INIT" }));
  };

  const alertModalProps = {
    isVisible,
    onClose,
    message: getTranslation("FeedBookmarkListPage.bookmarkError"),
  };

  return <AlertModal {...alertModalProps} />;
};

export default BookmarkErrorModal;
