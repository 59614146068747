import React from "react";

import { ShowTranslation, Icon } from "../../components";

import getTranslation from "../../common/utils/getTranslation";

import colors from "../../styles/colors";
import useModeColors from "../../common/hooks/useModeColors";

import emptyHeart from "../../assets/images/icHeartEmpty@3x.png";
import comment from "../../assets/images/icComment18@3x.png";

import { MyFeedDataProps } from "./types";
import { Container, LikeCountCommentCountWrapper } from "./styles";

const MyFeedData = ({
  totalFeedCount,
  totalLikeCount,
  totalCommentCount,
}: MyFeedDataProps) => {
  const modeColors = useModeColors();
  return (
    <Container>
      <ShowTranslation
        size={13}
        weight={"m"}
        highlightSize={12}
        highlightWeight={"b"}
        color={modeColors.text_02}
        style={{ marginBottom: 6 }}
      >
        {getTranslation("MyFeedData.posts", { "{n}": totalFeedCount })}
      </ShowTranslation>

      <LikeCountCommentCountWrapper>
        <ShowTranslation
          size={13}
          weight={"m"}
          color={modeColors.text_02}
          style={{ alignItems: "baseline", marginRight: 9 }}
        >
          {getTranslation("MyFeedData.totalReactions")}
        </ShowTranslation>
        <Icon
          name={"ic_heart_line_regular"}
          size={16}
          color={modeColors.background_05}
        />
        <div style={{ width: 2 }} />

        <ShowTranslation
          size={13}
          weight={"b"}
          color={modeColors.text_02}
          style={{ alignItems: "baseline", marginRight: 7 }}
        >
          {totalLikeCount}
        </ShowTranslation>
        <Icon
          name={"ic_burble_line_bold"}
          size={16}
          color={modeColors.background_05}
        />
        <div style={{ width: 2 }} />

        <ShowTranslation
          size={13}
          weight={"b"}
          color={modeColors.text_02}
          style={{ alignItems: "baseline", marginRight: 9 }}
        >
          {totalCommentCount}
        </ShowTranslation>
      </LikeCountCommentCountWrapper>
    </Container>
  );
};

export default MyFeedData;
