import React from "react";

import { Container } from "./styles";

import { Icon } from "../../components";

interface IProps {
  onClick: () => void;
}

const BookmarkButton = ({ onClick }: IProps) => {
  return (
    <Container onClick={onClick} onDragStart={event => event.preventDefault()}>
      <Icon name="ic_bookmark_line_regular" size={20} />
    </Container>
  );
};

export default BookmarkButton;
