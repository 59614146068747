import { useEffect, useState } from "react";

const useScrollRef = (ref: any | null) => {
  if (process.env.NODE_ENV === "development") {
    if (typeof ref !== "object" || (ref && typeof ref === "undefined")) {
      console.error(
        "`useScroll` expects a single ref argument from useRef or from useState"
      );
    }
  }

  const [refElement, setRefElement] = useState<any>(null);
  const [scrollY, setScrollY] = useState(0);
  const [scrollX, setScrollX] = useState(0);

  const [isReachedTop, setIsReachedTop] = useState(false);
  const [isReachedBottom, setIsReachedBottom] = useState(false);

  const scrollToBottom = () => {
    if (refElement) {
      refElement.scrollTo(0, refElement.scrollHeight);
    }
  };

  const scrollToTop = () => {
    if (refElement) {
      refElement.scrollTo(0, 0);
    }
  };

  const handler = () => {
    if (refElement) {
      setIsReachedBottom(
        Math.round(refElement.scrollTop + refElement.offsetHeight) /
          refElement.scrollHeight >
          0.98
          ? true
          : false
      );
      setScrollX(refElement.scrollLeft);
      setScrollY(refElement.scrollTop);
      setIsReachedTop(refElement.scrollTop === 0);
    }
  };

  useEffect(() => {
    if (!ref) {
      return;
    }

    let element: any;

    element = ref;

    if ("current" in ref && ref.current) {
      if (!ref.current) {
        return;
      }
      element = ref.current;
    }

    setRefElement(element);
  }, [ref]);

  useEffect(() => {
    if (refElement) {
      refElement.addEventListener("scroll", handler);
    }

    return () => {
      if (refElement) {
        refElement.removeEventListener("scroll", handler);
      }
    };
  }, [refElement]);

  return {
    scrollX,
    scrollY,
    isReachedTop,
    isReachedBottom,
    scrollToBottom,
    scrollToTop,
  };
};

export default useScrollRef;
