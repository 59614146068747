import axios, { AxiosResponse } from "axios";

import { createAsyncThunk, unwrapResult } from "@reduxjs/toolkit";

import apis from "../../api/apis";

import { serverMaintenanceApi } from "../../common/utils/axios";

import { ServerMaintenanceResponse } from "./types";

const fetchServerStatus = createAsyncThunk(
  "serverMaintenance/fetchServerStatus",
  async (arg, { rejectWithValue }) => {
    try {
      const response: AxiosResponse<ServerMaintenanceResponse> =
        await serverMaintenanceApi.get(apis.serverMaintenance);

      return {
        isMaintenance: response.data.maintenance,
        endTime: response.data.end_time,
        startTime: response.data.start_time,
      };
    } catch (error) {
      return rejectWithValue(null);
    }
  }
);

export { fetchServerStatus };
