import {
  MessageFromApp,
  AppEventListenersList,
  MessageFromAppEvents,
  MessageFromAppCallbacks,
} from "./types";

class AppEventListenerService {
  private _listenersList: AppEventListenersList;

  constructor() {
    this._listenersList = [] as AppEventListenersList;
  }

  private _onMessage = async (event: any) => {
    const message: MessageFromApp = JSON.parse(event.data);
    this._listenersList.forEach(listener => {
      if (message.type === listener.eventType) {
        listener.onEvent(message.data as never);
      }
    });
  };

  public addListener = <
    EventType extends MessageFromAppEvents[keyof MessageFromAppEvents],
    OnEvent extends MessageFromAppCallbacks[EventType]
  >(
    /**
     * @param eventType 앱에서 웹로 보내주는 이벤트 타입 입니다 removeAppEventListener 를 할시 꼭 동일한 함수 의 eventType 추가해주세요!!
     *
     */

    eventType: EventType,

    /**
     * @param onEvent 이벤트를 받을시 처리해주는 콜벡 함수 입니다. removeAppEventListener 를 할시 addAppEventListener 에 사용한 동일한 콜벡 의 reference 가 넘어와야합니다!
     *
     */
    onEvent: OnEvent
  ) => {
    if (window.ReactNativeWebView) {
      this._listenersList.push({
        eventType: eventType,
        onEvent,
        onMessage: this._onMessage,
      });

      this._listenersList.forEach(listener => {
        if (
          listener.eventType === eventType &&
          Object.is(listener.onEvent, onEvent)
        ) {
          window.addEventListener("message", listener.onMessage);
          document.addEventListener("message", listener.onMessage);
        }
      });
    }
  };

  public removeListener = <
    EventType extends MessageFromAppEvents[keyof MessageFromAppEvents],
    OnEvent extends MessageFromAppCallbacks[EventType]
  >(
    /**
     * @param eventType 앱에서 웹로 보내주는 이벤트 타입 입니다 removeAppEventListener 를 할시 꼭 동일한 함수 의 eventType 추가해주세요!!
     *
     */

    eventType: EventType,

    /**
     * @param onEvent 이벤트를 받을시 처리해주는 콜벡 함수 입니다. removeAppEventListener 를 할시 addAppEventListener 에 사용한 동일한 콜벡 의 reference 가 넘어와야합니다!
     *
     */
    onEvent: OnEvent
  ) => {
    const nonRemovedListeners = [] as AppEventListenersList;
    this._listenersList.forEach(listener => {
      if (
        listener.eventType === eventType &&
        Object.is(listener.onEvent, onEvent)
      ) {
        window.removeEventListener("message", listener.onMessage);
        document.removeEventListener("message", listener.onMessage);
      } else {
        nonRemovedListeners.push(listener);
      }
    });

    this._listenersList = nonRemovedListeners;
  };
}

export default AppEventListenerService;
