import { useAppSelector, useAppDispatch } from "../../common/hooks/reduxHooks";

import {
  selectIsAuthorized,
  selectAuthToken,
  selectUserData,
  selectAuthError,
  setIsAuthorized,
  setAuthToken,
  setAuthError,
} from "../../features/account/accountSlice";

import {
  fetchUserData,
  emailLogin,
  getSMSAuthCode,
  verifySMSAuthCode,
  smsLogin,
} from "../../features/account/accountThunks";

import { unwrapResult } from "@reduxjs/toolkit";

import { configureAxiosAuthorizationHeader } from "../../common/utils/axios";

const useAuth = () => {
  const dispatch = useAppDispatch();

  const userData: any = useAppSelector(selectUserData);

  const isAuthorized: boolean = useAppSelector(selectIsAuthorized);

  const authToken: string | null = useAppSelector(selectAuthToken);

  const isAuthError: boolean = useAppSelector(selectAuthError);

  const authorizeUser = async (authToken: string) => {
    try {
      dispatch(
        setAuthToken({
          authToken: authToken,
        })
      );

      await dispatch(fetchUserData());
      dispatch(setIsAuthorized(true));
    } catch (error) {
      dispatch(setAuthError(true));
    }
  };

  const loginUserByEmail = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    try {
      const emailLoginResult = await dispatch(emailLogin({ email, password }));

      const unwrappedEmailLoginResult = unwrapResult(emailLoginResult);

      if (unwrappedEmailLoginResult.code === "1") {
        configureAxiosAuthorizationHeader(unwrappedEmailLoginResult.token);
        await authorizeUser(unwrappedEmailLoginResult.token);
        dispatch(setAuthError(false));
      }
    } catch (error) {
      alert("로그인 실패");
    }
  };

  const loginUserBySMS = async (authHash: string) => {
    const smsLoginResult = await dispatch(smsLogin({ authHash }));

    const unwrappedSmsLoginResult = unwrapResult(smsLoginResult);

    if (unwrappedSmsLoginResult.code === "1") {
      configureAxiosAuthorizationHeader(unwrappedSmsLoginResult.token);
      await authorizeUser(unwrappedSmsLoginResult.token);
      dispatch(setAuthError(false));
    }
  };

  const getSMSAuthorizationCode = async (phoneNumber: string) => {
    try {
      await dispatch(getSMSAuthCode({ phoneNumber }));
      alert("인증 번호 발급 성공 :)");
      return "SUCCESS";
    } catch (error) {
      alert("인증 번호 발급 실패 :(");
      return "FAILED";
    }
  };

  const verifyAuthCode = async ({
    phoneNumber,
    authCode,
  }: {
    phoneNumber: string;
    authCode: string;
  }) => {
    const authResult = await dispatch(
      verifySMSAuthCode({ authCode, phoneNumber })
    );
    const unwrappedAuthResult = unwrapResult(authResult);
    if (unwrappedAuthResult.res_code === "ALREADY_SIGN_UP_USER") {
      return unwrappedAuthResult.data.hash;
    }
  };

  const verifyAndLoginUserBySMS = async ({
    phoneNumber,
    authCode,
  }: {
    phoneNumber: string;
    authCode: string;
  }) => {
    try {
      const authHash = await verifyAuthCode({ phoneNumber, authCode });
      await loginUserBySMS(authHash);
    } catch (error) {
      if (error === "NEW_USER_SIGNUP") {
        alert("회원가입 해주세요!");
        return;
      }
      alert("인증 번호 오류로 로그인 실패");
    }
  };

  const getUserData = async () => {
    await dispatch(fetchUserData());
  };

  const setUserAuthError = () => {
    dispatch(setAuthError(true));
  };

  return {
    isAuthorized,
    authToken,
    isAuthError,
    authorizeUser,
    userData,
    getUserData,
    setUserAuthError,
    loginUserByEmail,
    getSMSAuthorizationCode,
    verifyAndLoginUserBySMS,
  };
};

export default useAuth;
