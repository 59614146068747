import React, { useState, useRef, useEffect, MouseEvent } from "react";
import { debounce } from "lodash";
import {
  FeedItemContainer,
  FeedItemHeaderWrapper,
  FeedUserInfoWrapper,
  FeedContentWrapper,
  FeedStatusWrapper,
  LikeCommentWrapper,
  FeedTextWrapper,
  FeedFileWrapper,
  ImageButton,
  FeedText,
  ThickBorder,
  HyperLink,
  ManagerIcon,
  LegacyFeedCallRoomWrapper,
  FeedItemActionWrapper,
} from "./styles";

import {
  SoundPlayer,
  ImageGrid,
  FeedHiddenContent,
  ShowTranslation,
  BigGif,
  Icon,
} from "../../components";
import { useAppDispatch } from "../../common/hooks/reduxHooks";

import { likeFeed, unlikeFeed } from "../../features/feedList/feedListThunks";

import managerCheck from "../../assets/images/icVerified@3x.png";
import male from "../../assets/images/img_feedmale@3x.png";
import female from "../../assets/images/img_feedfemale@3x.png";
import nonbinary from "../../assets/images/img_feednonbinary@3x.png";

import colors from "../../styles/colors";

import useAuth from "../../common/hooks/useAuth";
import getTranslation from "../../common/utils/getTranslation";
import generateRandomNickname from "../../common/utils/generateRandomNickname";

import ReactNativeWebview from "../../common/utils/ReactNativeWebview";

import { getCreatedAtTimes } from "../../common/utils/timeFormatters";

import { setToolTips } from "../../features/globalAppState/globalAppStateSlice";

import { changeHideStatus } from "../../features/management/managementThunks";

import { IFeedItem } from "../../common/types/commonTypes";

import {
  createBookmark,
  deleteBookmark,
} from "../../features/bookmark/bookmarkThunks";

import useUserControl from "../../common/hooks/useUserControl";
import useModeColors from "../../common/hooks/useModeColors";
interface IProps {
  item: IFeedItem;
  listType?: "LATEST" | "MY" | "TARGET_USER" | "RECOMMEND" | "BOOKMARK";
  isTruncate?: boolean;
  navigateToFeedDetail?: (item: IFeedItem) => void;
  navigateToFeedReportDetail?: (item: IFeedItem) => void;
  likeFeedFromDetail?: () => void;
  unlikeFeedFromDetail?: () => void;
  onClickComment?: (item: IFeedItem) => void;
  createBookmarkDetail?: () => void;
  deleteBookmarkDetail?: () => void;
  isDetail?: boolean;
  showUserControlModal?: () => void;
}

const FeedItem = ({
  isTruncate,
  item,
  listType,
  likeFeedFromDetail,
  unlikeFeedFromDetail,
  navigateToFeedReportDetail,
  navigateToFeedDetail,
  onClickComment,
  createBookmarkDetail,
  deleteBookmarkDetail,
  isDetail = false,
  showUserControlModal,
}: IProps) => {
  const dispatch = useAppDispatch();
  const modeColors = useModeColors();
  const [isShowMore, setIsShowMore] = useState(false);
  const feedTextRef = useRef<HTMLDivElement>(null);
  const { userData } = useAuth();

  const { getUserControlType } = useUserControl();

  const [selectedManagerHide, setSelecteManagerHide] = useState<
    "OWN" | "CS" | null
  >(null);

  const { feedpost, is_liked, user, is_bookmarked } = item;

  const {
    access,
    content,
    created_at,
    feedpost_id,
    hide,
    total_count_comments,
    total_count_likes,
    total_count_reports,
  } = feedpost;

  const { text, duration, type: contentType, detail } = content;

  const {
    is_manager,
    relation_with_me,
    representative_image,
    sex,
    uuid,
    nickname,
    approval,
    country,
  } = user;

  const onClickLearnMore = () => {
    navigateToFeedReportDetail && navigateToFeedReportDetail(item);
  };

  const onClickItem = () => {
    navigateToFeedDetail && navigateToFeedDetail(item);
  };

  const onClickuserInfo = (event: MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();

    if (isDetail) {
      dispatch(
        setToolTips({ key: "PROFILE_EXCHANGE_VISIBLE", isVisible: false })
      );

      if (!is_manager && relation_with_me !== "ME" && hide === "NONE") {
        ReactNativeWebview.postMessageToApp({
          type: "SHOW_PROFILE_EXCHANGE_REQUEST_MODAL",
          data: {
            userUuid: uuid,
            userNickname: nickname,
            userSex: sex,
            feedId: feedpost_id,
            contentType: content.type,
            contentText: content.text,
            approvalStatus: approval,
          },
        });
      }

      return;
    }

    if (hide === "NONE" || hide === "OWN") {
      navigateToFeedDetail && navigateToFeedDetail(item);
    }
  };

  const onClickMore = (event: MouseEvent<HTMLButtonElement>) => {
    if (getUserControlType() === "PAUSE") {
      // TODO: 유저컨트롤 모달 전역 상태 로 관리 할수있게 refactoring 해야함
      showUserControlModal && showUserControlModal();
      event.stopPropagation();
      return;
    }

    ReactNativeWebview.postMessageToApp({
      type: "SHOW_ACTION_SHEET",
      data: {
        contentUserUuid: uuid,
        contentUserCountry: country,
        contentUserApprovalStatus: approval,
        feedId: feedpost_id,
        postType: "FEED",
        contentId: feedpost_id,
        blockPostType: "FEED",
      },
    });

    event.stopPropagation();
  };

  const onClickChangeHideStatus = (hideStatus: "OWN" | "CS") => {
    if (selectedManagerHide === null) {
      setSelecteManagerHide(hideStatus);
      dispatch(
        changeHideStatus({
          feedId: feedpost_id,
          totalCountManageLikes: total_count_likes,
          text: text,
          hide: hideStatus,
        })
      );
    }
  };

  const onClickImage = (index: number) => {
    ReactNativeWebview.postMessageToApp({
      type: "NAVIGATE_TO_IMAGE_DETAIL",
      data: {
        imageData: detail,
        imageIndex: index,
      },
    });
  };

  const onClickHeart = debounce((event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    if (is_liked) {
      if (isDetail) {
        unlikeFeedFromDetail && unlikeFeedFromDetail();
      } else {
        dispatch(unlikeFeed({ feedId: item.feedpost.feedpost_id }));
      }
      return;
    }

    if (isDetail) {
      likeFeedFromDetail && likeFeedFromDetail();
    } else {
      dispatch(likeFeed({ feedId: item.feedpost.feedpost_id }));
    }

    ReactNativeWebview.postMessageToApp({ type: "LOG_SENT_FEED_LIKES" });
  }, 300);

  const onClickCommentButton = (event: MouseEvent<HTMLButtonElement>) => {
    onClickComment && onClickComment(item);
    event.stopPropagation();
  };

  const onClickBookmarkButton = async (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    if (is_bookmarked) {
      if (isDetail) {
        deleteBookmarkDetail && deleteBookmarkDetail();
      } else {
        await dispatch(deleteBookmark({ feedpostId: feedpost_id }));
      }
    } else {
      if (isDetail) {
        createBookmarkDetail && createBookmarkDetail();
      } else {
        await dispatch(
          createBookmark({
            feedpostId: feedpost_id,
            contentType: content.type === "CALL_ROOM" ? "NORMAL" : content.type,
            contentUserUuid: uuid,
          })
        );
      }
    }
  };

  useEffect(() => {
    if (isTruncate && feedTextRef.current) {
      const height = feedTextRef.current.offsetHeight;

      const lines = height / 20;
      if (lines >= 10) {
        setIsShowMore(true);
      }
    }
  }, [isTruncate, feedTextRef]);

  const renderFeedItemHeader = () => {
    const userImage = is_manager
      ? representative_image
      : sex === "F"
      ? female
      : sex === "O"
      ? nonbinary
      : male;

    return (
      <FeedItemHeaderWrapper onClick={onClickuserInfo}>
        <ImageButton
          disabled={
            hide === "CS" ||
            hide === "REPORT" ||
            (isDetail && relation_with_me === "ME")
          }
          style={{ borderRadius: 32 / 2 }}
          source={userImage}
          width={32}
          height={32}
          marginRight={6}
        />
        <FeedUserInfoWrapper>
          <FeedStatusWrapper>
            <ShowTranslation
              style={{ lineHeight: "20px" }}
              size={14}
              weight={"m"}
              color={modeColors.text_01}
            >
              {is_manager
                ? nickname
                : generateRandomNickname(nickname, feedpost_id)}
            </ShowTranslation>

            {is_manager && (
              <ManagerIcon
                style={{ marginLeft: 3 }}
                width={14}
                height={14}
                src={managerCheck}
              />
            )}
          </FeedStatusWrapper>
          <FeedStatusWrapper>
            <ShowTranslation size={12} weight={"r"} color={modeColors.text_02}>
              {getCreatedAtTimes(created_at)}
            </ShowTranslation>
          </FeedStatusWrapper>
        </FeedUserInfoWrapper>

        {is_manager ||
        hide !== "NONE" ||
        relation_with_me === "ME" ||
        listType === "BOOKMARK" ? null : (
          <Icon name="ic_more_filled_bold" size={18} onClick={onClickMore} />
        )}
      </FeedItemHeaderWrapper>
    );
  };

  const onClickHyperlink = (event: MouseEvent, url: string) => {
    ReactNativeWebview.postMessageToApp({
      type: "OPEN_HYPERLINK",
      data: { url },
    });
    event.stopPropagation();
  };

  const renderFeedContent = () => {
    const renderFeedText = () => {
      const domainExp =
        /(?:http(s)?:\/\/)?(?:www\.)?([a-zA-Z0-9\-]+\.[a-zA-Z]{2,})(\/[\/a-zA-Z0-9-%#?&=\w.]*)*/gi;

      const checkIfTextHasHyperlink = () => {
        const textWithHyperlink = text.split("{@}");
        if (textWithHyperlink.length > 1) {
          return true;
        }
        return false;
      };

      const getTextWithHyperLink = () => {
        const textWithHyperlink = text.split("{@}");
        return textWithHyperlink.map((textItem, index) => {
          if (textItem.match(domainExp)) {
            return (
              <HyperLink
                key={`text-id-${textItem}-${item.feedpost.feedpost_id}+${index}`}
                onClick={event => {
                  onClickHyperlink(event, textItem);
                }}
              >
                {textItem}
              </HyperLink>
            );
          }
          return textItem;
        });
      };

      const generateText = () => {
        if (checkIfTextHasHyperlink()) {
          return getTextWithHyperLink();
        }
        return text;
      };

      return (
        <FeedTextWrapper>
          <FeedText
            ref={feedTextRef}
            isTruncate={isTruncate}
            isHyperlink={checkIfTextHasHyperlink()}
          >
            {generateText()}
          </FeedText>
          {isShowMore && !checkIfTextHasHyperlink() ? (
            <ShowTranslation
              size={15}
              weight={"r"}
              color={modeColors.text_03}
              style={{ lineHeight: "20px" }}
            >
              {getTranslation("FeedCommon.seeMoreContent")}
            </ShowTranslation>
          ) : null}
        </FeedTextWrapper>
      );
    };

    const renderImages = () => {
      if (contentType === "IMAGE" && detail.length > 0) {
        const imagesUrl = detail.map(value => {
          return { url: value.url ?? "" };
        });

        return (
          <FeedFileWrapper>
            <ImageGrid onClickImage={onClickImage} images={imagesUrl} />
          </FeedFileWrapper>
        );
      }
    };

    const renderSoundPlayer = () => {
      if (contentType === "AUDIO" && detail.length > 0) {
        return (
          <FeedFileWrapper>
            <SoundPlayer
              type={"FULL"}
              audioUrl={detail[0].url!}
              initDuration={duration}
            />
          </FeedFileWrapper>
        );
      }
    };

    const renderGif = () => {
      if (contentType === "GIF" && detail.length > 0) {
        return (
          <FeedFileWrapper>
            <BigGif source={detail[0].url!} />
          </FeedFileWrapper>
        );
      }
    };

    const renderLegacyCallRoom = () => {
      if (contentType === "CALL_ROOM") {
        return (
          <LegacyFeedCallRoomWrapper>
            <ShowTranslation
              style={{ flexShrink: 1 }}
              size={13}
              color={modeColors.text_02}
              weight={"r"}
            >
              {getTranslation("Common.legacyFeature")}
            </ShowTranslation>
          </LegacyFeedCallRoomWrapper>
        );
      }
    };

    return (
      <FeedContentWrapper onClick={onClickItem}>
        {renderFeedText()}
        {renderImages()}
        {renderSoundPlayer()}
        {renderGif()}
        {renderLegacyCallRoom()}
      </FeedContentWrapper>
    );
  };

  const renderFeedActionButtons = () => {
    return (
      <FeedItemActionWrapper>
        <LikeCommentWrapper>
          <LikeCommentWrapper marginRight={24}>
            <Icon
              name={is_liked ? "ic_heart_filled" : "ic_heart_line_regular"}
              onClick={onClickHeart}
              size={20}
              color={is_liked ? colors.Hotpink700 : modeColors.background_05}
            />
            {total_count_likes > 0 && <div style={{ marginRight: 4 }} />}

            {total_count_likes > 0 && (
              <ShowTranslation
                weight={"r"}
                color={modeColors.text_02}
                size={13}
              >
                {total_count_likes}
              </ShowTranslation>
            )}
          </LikeCommentWrapper>
          <LikeCommentWrapper>
            <Icon
              name={"ic_burble_line_bold"}
              onClick={onClickCommentButton}
              size={20}
              color={modeColors.background_05}
            />
            {total_count_comments > 0 && <div style={{ marginRight: 4 }} />}

            {total_count_comments > 0 && (
              <ShowTranslation
                weight={"r"}
                color={modeColors.text_02}
                size={13}
              >
                {total_count_comments}
              </ShowTranslation>
            )}
          </LikeCommentWrapper>
        </LikeCommentWrapper>

        <Icon
          name={
            is_bookmarked ? "ic_bookmark_filled" : "ic_bookmark_line_regular"
          }
          onClick={onClickBookmarkButton}
          size={20}
          color={
            is_bookmarked ? modeColors.background_04 : modeColors.background_05
          }
        />
      </FeedItemActionWrapper>
    );
  };

  const renderHiddenContent = () => {
    if (hide === "CS") {
      return <FeedHiddenContent hiddenType={hide} />;
    }

    if (hide === "REPORT") {
      return (
        <FeedHiddenContent
          onClickLearnMore={onClickLearnMore}
          hiddenType={hide}
          reportCount={total_count_reports}
        />
      );
    }

    return (
      <FeedHiddenContent
        onClickLearnMore={onClickLearnMore}
        hiddenType={"REPORT"}
        reportCount={total_count_reports}
      />
    );
  };

  if (!isDetail && hide === "OWN" && uuid !== userData.uuid) {
    return null;
  }

  return (
    <>
      <FeedItemContainer>
        {renderFeedItemHeader()}
        {hide === "NONE" ||
        (hide === "OWN" && uuid === userData.uuid) ||
        (isDetail && hide === "OWN") ? (
          <>
            {renderFeedContent()}
            {renderFeedActionButtons()}
          </>
        ) : (
          renderHiddenContent()
        )}
        <ThickBorder />
      </FeedItemContainer>
    </>
  );
};

function shouldMemo(prevFeedItem: IProps, nextFeedItem: IProps) {
  return prevFeedItem.item === nextFeedItem.item;
}
export default React.memo(FeedItem, shouldMemo);
