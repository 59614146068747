import React, { useEffect } from "react";

import { Container } from "./styles";
import { Error } from "../../components";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";

const FourOFourError = () => {
  useEffect(() => {
    ReactNativeWebview.postMessageToApp({ type: "SHOW_APP_HEADER" });
  }, []);

  return (
    <Container>
      <Error text={"404 Error!!!"} />
    </Container>
  );
};

export default FourOFourError;
