import { createAsyncThunk } from "@reduxjs/toolkit";

import apis from "../../api/apis";

import { mainAxiosInstance } from "../../common/utils/axios";

const fetchUserData = createAsyncThunk(
  "account/fetchUserData",
  async (arg, { rejectWithValue }) => {
    try {
      const response = await mainAxiosInstance.get(apis.account.userData);

      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        return rejectWithValue("NOT_AUTHORIZED_USER");
      }
    }
  }
);

const emailLogin = createAsyncThunk(
  "account/emailLogin",
  async (
    { email, password }: { email: string; password: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await mainAxiosInstance.post(apis.account.emailLogin, {
        email,
        password,
        fcmToken: "logged in from web",
      });

      if (response.data.code === "0") {
        return rejectWithValue("LOGIN_FAILED");
      }
      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        return rejectWithValue("NOT_AUTHORIZED_USER");
      }
      return rejectWithValue("LOGIN_FAILED");
    }
  }
);

const smsLogin = createAsyncThunk(
  "account/smsLogin",
  async ({ authHash }: { authHash: string }, { rejectWithValue }) => {
    try {
      const response = await mainAxiosInstance.post(apis.account.smsLogin, {
        auth_hash: authHash,
        fcmToken: "logged in from web",
      });

      if (response.data.res_code !== "LOGIN_SUCCESS") {
        return rejectWithValue("LOGIN_FAILED");
      }

      return response.data;
    } catch (error: any) {
      if (error.response.status === 401) {
        return rejectWithValue("NOT_AUTHORIZED_USER");
      }
      return rejectWithValue("LOGIN_FAILED");
    }
  }
);

const getSMSAuthCode = createAsyncThunk(
  "account/getSMSAuthCode",
  async ({ phoneNumber }: { phoneNumber: string }, { rejectWithValue }) => {
    try {
      const response = await mainAxiosInstance.post(
        apis.account.getSMSAuthCode,
        {
          country_code: "KR",
          phone_number: phoneNumber,
        }
      );

      if (response.data.res_code !== "SMS_SEND_SUCCESS") {
        return rejectWithValue("SMS_SEND_FAILED");
      }
      return response.data.res_code;
    } catch (error) {
      return rejectWithValue("SMS_SEND_FAILED");
    }
  }
);

const verifySMSAuthCode = createAsyncThunk(
  "account/verifySMSAuthCode",
  async (
    { phoneNumber, authCode }: { phoneNumber: string; authCode: string },
    { rejectWithValue }
  ) => {
    try {
      const response = await mainAxiosInstance.post(
        apis.account.verifySMSAuthCode,
        {
          country_code: "KR",
          phone_number: phoneNumber,
          auth_code: authCode,
        }
      );

      if (response.data.res_code === "SMS_CHECK_SUCCESS") {
        return rejectWithValue("NEW_USER_SIGNUP");
      }

      return response.data;
    } catch (error) {
      return rejectWithValue("NOT_VALID_AUTH_CODE");
    }
  }
);

export {
  fetchUserData,
  emailLogin,
  getSMSAuthCode,
  verifySMSAuthCode,
  smsLogin,
};
