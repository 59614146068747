import i18n from "i18next";
import { initReactI18next } from "react-i18next";

const resources = {
  en: {
    translation: require("../../node_modules/@yplabs-ltd/cnt-translations/lib/connecting-feed-web/en.json"),
  },
  ko: {
    translation: require("../../node_modules/@yplabs-ltd/cnt-translations/lib/connecting-feed-web/ko.json"),
  },
  ja: {
    translation: require("../../node_modules/@yplabs-ltd/cnt-translations/lib/connecting-feed-web/ja.json"),
  },
  ms: {
    translation: require("../../node_modules/@yplabs-ltd/cnt-translations/lib/connecting-feed-web/ms.json"),
  },
  zh: {
    translation: require("../../node_modules/@yplabs-ltd/cnt-translations/lib/connecting-feed-web/zh.json"),
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
});

export const getLanguage = () => {
  return i18n.language;
};

export default i18n;
