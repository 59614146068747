import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { BookmarkLoadingStatusType, ShowAlertStatusType } from "./types";
import {
  createBookmark,
  deleteBookmark,
  fetchBookmarkList,
} from "./bookmarkThunks";
import { likeFeed, unlikeFeed } from "../feedList/feedListThunks";

type BookmarkInitialStateType = {
  loading: BookmarkLoadingStatusType;
  showAlert: boolean;
  list: Array<any>;
  currentPageId: number;
  nextPageId: number;
};

const initialState: BookmarkInitialStateType = {
  loading: "INIT_LOADING",

  showAlert: false,
  list: [],
  currentPageId: 0,
  nextPageId: 0,
};

export const bookmarkSlice = createSlice({
  name: "bookmark",
  initialState,
  reducers: {
    setShowAlertStatus: (state, action: PayloadAction<ShowAlertStatusType>) => {
      state.showAlert = action.payload;
    },
    setBookmarkLoadingStatus: (
      state,
      action: PayloadAction<BookmarkLoadingStatusType>
    ) => {
      state.loading = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(fetchBookmarkList.pending, (state, action) => {
      if (action.meta.arg.fetchType === "INIT") {
        state.loading = "INIT_LOADING";
        return;
      }

      if (action.meta.arg.fetchType === "REFRESH") {
        state.loading = "LOADING";
        return;
      }

      if (action.meta.arg.fetchType === "MORE") {
        state.loading = "LOADING_MORE";
      }
    });

    builder.addCase(fetchBookmarkList.fulfilled, (state, action) => {
      if (
        action.meta.arg.fetchType === "REFRESH" ||
        action.meta.arg.fetchType === "INIT"
      ) {
        state.list = action.payload.wrappedData.feed_list;
      } else {
        state.list = state.list.concat(action.payload.wrappedData.feed_list);
      }

      state.currentPageId = action.payload.currentPage;
      state.nextPageId =
        action.payload.nextPageId === 0
          ? 0
          : parseInt(action.payload.nextPageId);

      state.loading = "NONE";
    });

    builder.addCase(fetchBookmarkList.rejected, (state, action) => {
      state.loading = "NONE";
    });

    builder.addCase(createBookmark.pending, (state, action) => {
      const feedId = action.meta.arg.feedpostId;

      const updatedList = state.list.map(item => {
        if (item.feedpost.feedpost_id === feedId) {
          item.is_bookmarked = true;
        }

        return item;
      });

      state.list = updatedList;
    });

    builder.addCase(createBookmark.fulfilled, (state, action) => {});

    builder.addCase(createBookmark.rejected, (state, action) => {
      const feedId = action.meta.arg.feedpostId;

      const updatedList = state.list.map(item => {
        if (item.feedpost.feedpost_id === feedId) {
          item.is_bookmarked = false;
        }

        return item;
      });

      state.list = updatedList;
    });

    builder.addCase(deleteBookmark.pending, (state, action) => {
      const feedId = action.meta.arg.feedpostId;

      const updatedList = state.list.map(item => {
        if (item.feedpost.feedpost_id === feedId) {
          item.is_bookmarked = false;
        }

        return item;
      });

      state.list = updatedList;
    });

    builder.addCase(deleteBookmark.fulfilled, (state, action) => {});

    builder.addCase(deleteBookmark.rejected, (state, action) => {
      const feedId = action.meta.arg.feedpostId;

      const updatedList = state.list.map(item => {
        if (item.feedpost.feedpost_id === feedId) {
          item.is_bookmarked = true;
        }

        return item;
      });

      state.list = updatedList;
    });

    builder.addCase(likeFeed.pending, (state, action) => {
      const feedId = action.meta.arg.feedId;

      const updateList = state.list.map(item => {
        if (item.feedpost.feedpost_id === feedId) {
          const updatedFeed = { ...item };
          const { feedpost } = updatedFeed;
          const updatedPost = { ...feedpost };

          updatedFeed.is_liked = true;
          updatedPost.total_count_likes += 1;
          updatedFeed.feedpost = updatedPost;

          return updatedFeed;
        }

        return item;
      });

      state.list = updateList;
    });

    builder.addCase(likeFeed.fulfilled, (state, action) => {});

    builder.addCase(likeFeed.rejected, (state, action) => {
      const feedId = action.meta.arg.feedId;

      const updateList = state.list.map(item => {
        if (item.feedpost.feedpost_id === feedId) {
          const updatedFeed = { ...item };
          const { feedpost } = updatedFeed;
          const updatedPost = { ...feedpost };

          updatedFeed.is_liked = false;
          updatedPost.total_count_likes -= 1;
          updatedFeed.feedpost = updatedPost;

          return updatedFeed;
        }

        return item;
      });

      state.list = updateList;
    });

    builder.addCase(unlikeFeed.pending, (state, action) => {
      const feedId = action.meta.arg.feedId;

      const updatedList = state.list.map(item => {
        if (item.feedpost.feedpost_id === feedId) {
          const updatedFeed = { ...item };
          const { feedpost } = updatedFeed;
          const updatedPost = { ...feedpost };

          updatedFeed.is_liked = false;
          updatedPost.total_count_likes -= 1;
          updatedFeed.feedpost = updatedPost;

          return updatedFeed;
        }

        return item;
      });

      state.list = updatedList;
    });

    builder.addCase(unlikeFeed.fulfilled, (state, action) => {});

    builder.addCase(unlikeFeed.rejected, (state, action) => {
      const feedId = action.meta.arg.feedId;

      const updateList = state.list.map(item => {
        if (item.feedpost.feedpost_id === feedId) {
          const updatedFeed = { ...item };
          const { feedpost } = updatedFeed;
          const updatedPost = { ...feedpost };

          updatedFeed.is_liked = true;
          updatedPost.total_count_likes += 1;
          updatedFeed.feedpost = updatedPost;

          return updatedFeed;
        }

        return item;
      });

      state.list = updateList;
    });
  },
});

export const { setShowAlertStatus, setBookmarkLoadingStatus } =
  bookmarkSlice.actions;

export const selectBookmarkLoadingStatus = (state: RootState) =>
  state.bookmark.loading;
export const selectBookmarkList = (state: RootState) => state.bookmark.list;
export const selectBookmarkNextPageId = (state: RootState) =>
  state.bookmark.nextPageId;
export const selectBookmarkCurrentPageId = (state: RootState) =>
  state.bookmark.currentPageId;
export const selectShowAlertStatus = (state: RootState) =>
  state.bookmark.showAlert;

export default bookmarkSlice.reducer;
