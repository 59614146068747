import styled from "styled-components";

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  margin-right: 16px;
`;

export const Spacer = styled.div`
  width: 6px;
`;
