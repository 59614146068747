import React from "react";

import { ShowTranslation, Icon } from "../../components";

import getTranslation from "../../common/utils/getTranslation";

import { ProfileExchangeButtonProps } from "./types";
import { Container } from "./styles";
import useModeColors from "../../common/hooks/useModeColors";

const ProfileExchangeButton = ({ onClick }: ProfileExchangeButtonProps) => {
  const modeColors = useModeColors();
  return (
    <Container onClick={onClick}>
      <Icon name={"ic_profileexchange_line_regular"} size={20} />
      <div style={{ marginRight: 6 }} />

      <ShowTranslation size={15} weight={"b"} color={modeColors.text_01}>
        {getTranslation("ProfileExchangeButton.buttonText")}
      </ShowTranslation>
    </Container>
  );
};

export default ProfileExchangeButton;
