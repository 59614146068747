import { EmptyList, FeedItem } from "../../components";
import { Container } from "./styles";
import { IFeedItem } from "../../common/types/commonTypes";

interface IProps {
  data: Array<IFeedItem>;
  listType?: "LATEST" | "MY" | "TARGET_USER" | "RECOMMEND" | "BOOKMARK";
  emptyText?: string | string[];
  emptyBackgroundColor?: "white" | "gray";
  navigateToFeedReportDetail: (item: IFeedItem) => void;
  navigateToFeedDetail: (item: IFeedItem) => void;
  subtractHeight?: number;
  showUserControlModal?: () => void;
}

const FeedItemList = ({
  data,
  listType,
  navigateToFeedDetail,
  navigateToFeedReportDetail,
  emptyText,
  emptyBackgroundColor = "white",
  subtractHeight,
  showUserControlModal,
}: IProps) => {
  if (data && data.length === 0) {
    return (
      <EmptyList
        backgroundType={emptyBackgroundColor}
        subtractHeight={subtractHeight}
        text={emptyText}
      />
    );
  }

  return (
    <Container>
      {data.map((item, index) => (
        <FeedItem
          showUserControlModal={showUserControlModal}
          onClickComment={navigateToFeedDetail}
          navigateToFeedDetail={navigateToFeedDetail}
          navigateToFeedReportDetail={navigateToFeedReportDetail}
          listType={listType}
          key={`feeditem-id-${item.feedpost.feedpost_id}-${listType}+${index}`}
          item={item}
          isTruncate={true}
        />
      ))}
    </Container>
  );
};

export default FeedItemList;
