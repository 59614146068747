import React from "react";

import { Container, LoadingWrapper } from "./styles";
import { ShowTranslation } from "../../components";
import ReactLoading from "react-loading";
import colors from "../../styles/colors";

interface IProps {
  text?: string;
  subtractHeight?: number;
}

const Loading = ({ text, subtractHeight }: IProps) => {
  return (
    <Container subtractHeight={subtractHeight}>
      <LoadingWrapper>
        <ReactLoading
          color={colors.Blue700}
          width={33}
          height={33}
          type={"spin"}
        />
      </LoadingWrapper>
      {text && (
        <ShowTranslation size={12} weight={"r"} color={colors.CNTDarkGreen}>
          {text}
        </ShowTranslation>
      )}
    </Container>
  );
};

Loading.defaultProps = {
  text: "",
};

export default Loading;
