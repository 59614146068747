export interface ThemeColors {
  //background colors
  background_01: string;
  background_02: string;
  background_03: string;
  background_04: string;
  background_05: string;
  // border colors
  border_01: string;
  border_02: string;
  border_03: string;

  // text colors
  text_01: string;
  text_02: string;
  text_03: string;
  text_04: string;
  text_05: string;
}

const gray = {
  Black: "#000000",
  Gray900: "#222222",
  Gray800: "#333333",
  Gray750: "#535353",
  Gray700: "#666666",
  Gray600: "#858585",
  Gray500: "#AAAAAA",
  Gray400: "#CCCCCC",
  Gray300: "#E6E6E6",
  Gray200: "#F2F2F2",
  Gray100: "#F8F8F8",
  White: "#FFFFFF",
};

const blue = {
  Blue900: "#0E2062",
  Blue800: "#062BB0",
  Blue700: "#3D67FC",
  Blue500: "#85A7FF",
  Blue300: "#DDE9FF",
};

const purple = {
  Purple900: "#250677",
  Purple750: "#6733ED",
  Purple700: "#723DFC",
  Purple500: "#A786FF",
  Purple300: "#E3D2FF",
};

const mint = {
  Mint800: "#077878",
  Mint700: "#00C2B0",
  MintNeon: "#0FF6CD",
};

const navy = {
  Navy950: "#050C18",
  Navy900: "#050E24",
  Navy800: "#0C1A3B",
  Navy750: "#16264E",
  Navy700: "#273559",
  Navy650: "#2F3D66",
  Navy600: "#394A77",
};

const red = {
  Red100: "#FFEAEC",
  Red700: "#EE434E",
  RedLight: "#FF7156",
};

const yellow = {
  Yellow700: "#FFCD11",
  Yellow300: "#FFE687",
};

const dim = {
  Black70: "rgba(0, 0, 0, 0.7)",
  Black50: "rgba(0, 0, 0, 0.5)",
  Black30: "rgba(0, 0, 0, 0.3)",
  Black15: "rgba(0, 0, 0, 0.15)",
  Black5: "rgba(0, 0, 0, 0.05)",
  White30: "rgba(255, 255, 255, 0.3)",
  White15: "rgba(255, 255, 255, 0.15)",
  White5: "rgba(255, 255, 255, 0.05)",
};

const orange = {
  Orange100: "#FFECD0",
  Orange700: "#FFA011",
  Orange900: "#FF7A00",
};

const hotpink = {
  Hotpink700: "#F74379",
};

const transparent = {
  Transparent: "transparent",
};

const legacyColors = {
  PRIMARY: "rgb(18, 196, 174)",
  neonGreen: "rgb(0, 219, 199)",
  CNTMint: "rgb(0, 194, 176)",
  CNTDarkGreen: "rgb(0, 156, 142)",
  CNTMidGreen: "rgb(214, 248, 242)",
  CNTLightGreen: "rgb(238, 251, 250)",
  CNTPurple: "rgb(108, 77, 255)",
  lightPurple: "rgb(241, 239, 255)",
  callingGreen: "rgb(52, 199, 89)",
  notiRed: "rgb(255, 86, 53)",
  bannerBlue: "rgb(57, 108, 233)",
  toastGray: "rgb(238, 239, 241)",
  linkBlue: "rgb(37, 129, 192)",
};

const colors = {
  ...gray,
  ...blue,
  ...purple,
  ...mint,
  ...navy,
  ...red,
  ...yellow,
  ...dim,
  ...orange,
  ...hotpink,
  ...transparent,
  ...legacyColors,
};

const lightModeColors: ThemeColors = {
  background_01: colors.White,
  background_02: colors.Gray200,
  background_03: colors.Gray300,
  background_04: colors.Gray900,
  background_05: colors.Gray400,
  border_01: colors.Gray100,
  border_02: colors.Gray400,
  border_03: colors.Gray600,
  text_01: colors.Gray900,
  text_02: colors.Gray700,
  text_03: colors.Gray600,
  text_04: colors.Gray400,
  text_05: colors.Gray500,
};

const darkModeColors: ThemeColors = {
  background_01: colors.Navy900,
  background_02: colors.Navy800,
  background_03: colors.Navy700,
  background_04: colors.White,
  background_05: colors.Gray750,
  border_01: colors.Navy800,
  border_02: colors.Navy700,
  border_03: colors.Gray750,
  text_01: colors.White,
  text_02: colors.Gray600,
  text_03: colors.Gray700,
  text_04: colors.Gray750,
  text_05: colors.White30,
};

export { lightModeColors, darkModeColors };

export default colors;
