import { ModalProvider } from "styled-react-modal";
import {
  ModalBackground,
  StyledModal,
  Title,
  Content,
  ActionButton,
  Action,
  SecondActionButton,
  SecondAction,
} from "./styles";

interface IProps {
  isVisible: boolean;
  title: string | JSX.Element;
  content: string | JSX.Element;
  action: string | JSX.Element;
  secondAction?: string | JSX.Element;
  canBackdropPress?: boolean;
  onAction: () => void;
  onSecondAction?: () => void;
  onClose: () => void;
}

const TwoButtonModal = ({
  isVisible,
  title,
  content,
  action,
  secondAction,
  canBackdropPress,
  onAction,
  onSecondAction,
  onClose,
}: IProps) => {
  const renderTitle = () => {
    if (typeof title === "string") {
      return <Title>{title}</Title>;
    } else {
      return title;
    }
  };
  const renderContent = () => {
    if (typeof content === "string") {
      return <Content>{content}</Content>;
    } else {
      return content;
    }
  };
  const renderActionButton = () => {
    return (
      <ActionButton onClick={onAction}>
        {typeof action === "string" ? <Action>{action}</Action> : action}
      </ActionButton>
    );
  };

  const renderSecondActionButton = () => {
    if (secondAction) {
      return (
        <SecondActionButton onClick={onSecondAction}>
          {typeof secondAction === "string" ? (
            <SecondAction>{secondAction}</SecondAction>
          ) : (
            secondAction
          )}
        </SecondActionButton>
      );
    } else {
      return (
        <SecondActionButton
          onClick={() => {
            onClose();
          }}
        >
          <SecondAction>취소</SecondAction>
        </SecondActionButton>
      );
    }
  };

  return (
    <ModalProvider backgroundComponent={ModalBackground}>
      <StyledModal
        isOpen={isVisible}
        onBackgroundClick={canBackdropPress ? onClose : () => {}}
      >
        {renderTitle()}
        {renderContent()}
        {renderActionButton()}
        {renderSecondActionButton()}
      </StyledModal>
    </ModalProvider>
  );
};

TwoButtonModal.defaultProps = {
  isVisible: true,
  title: "",
  content: "",
  action: "",
  onAction: () => {},
  onSecondAction: () => {},
  onClose: () => {},
};

export default TwoButtonModal;
