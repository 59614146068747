import {
  PostMessageToAppData,
  PostMessageDataPartial,
  PostMessageDataRequired,
} from "./types";

const postMessage = <EventType extends keyof PostMessageToAppData>({
  type,
  data,
}: PostMessageToAppData[EventType] extends undefined
  ? PostMessageDataPartial<EventType>
  : PostMessageDataRequired<EventType>) => {
  if (window.ReactNativeWebView) {
    window.ReactNativeWebView.postMessage(JSON.stringify({ type, data }));
  }
};

export default postMessage;
