import postMessage from "./postMessage";
import AppEventListenerService from "./appEventListenerService";

class ReactNativeWebview {
  public static isReactNativeWebviewAvailable = window.ReactNativeWebView
    ? true
    : false;

  public static postMessageToApp = postMessage;

  private static listenerService = new AppEventListenerService();

  public static addAppEventListener =
    ReactNativeWebview.listenerService.addListener;

  public static removeAppEventListener =
    ReactNativeWebview.listenerService.removeListener;
}

export default ReactNativeWebview;
