import React from "react";

import PullRefresh from "react-simple-pull-to-refresh";
import ReactLoading from "react-loading";

import { LoadingContainer, Empty } from "./styles";
import useModeColors from "../../common/hooks/useModeColors";
import colors from "../../styles/colors";

const PullToRefresh = ({
  children,
  onRefresh,
}: {
  children: JSX.Element;
  onRefresh: () => Promise<void>;
}) => {
  const modeColors = useModeColors();
  return (
    <PullRefresh
      resistance={4}
      refreshingContent={
        <LoadingContainer>
          <ReactLoading
            height={28}
            width={28}
            color={colors.Gray500}
            type={"spokes"}
          />
        </LoadingContainer>
      }
      pullDownThreshold={80}
      pullingContent={<Empty />}
      backgroundColor={modeColors.background_01}
      onRefresh={onRefresh}
    >
      {children}
    </PullRefresh>
  );
};

export default PullToRefresh;
