import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";
import { fetchServerStatus } from "./serverMaintenaceThunks";

import { ServerMaintenanceState } from "./types";

const initialState: ServerMaintenanceState = {
  serverMaintenance: {
    isMaintenance: false,
    startTime: "",
    endTime: "",
  },
  isServerMaintenanceError: false,
};

export const serverMaintenanceSlice = createSlice({
  name: "serverMaintenance",
  initialState,
  reducers: {},
  extraReducers: builders => {
    builders.addCase(fetchServerStatus.fulfilled, (state, action) => {
      state.serverMaintenance = {
        ...state.serverMaintenance,
        ...action.payload,
      };
    });

    builders.addCase(fetchServerStatus.rejected, (state, action) => {
      state.isServerMaintenanceError = true;
    });
  },
});

export const selectServerMaintenance = (state: RootState) =>
  state.serverMaintenance.serverMaintenance;

export const selectIsServerMaintenanceError = (state: RootState) =>
  state.serverMaintenance.isServerMaintenanceError;

export default serverMaintenanceSlice.reducer;
