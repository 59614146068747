import { useAppSelector, useAppDispatch } from "../../common/hooks/reduxHooks";

import {
  selectUserControl,
  selectUserControlLoading,
  selectuserControlError,
} from "../../features/report/reportSlice";
import { ReportKey } from "../../features/report/reportSlice";

import {
  fetchUserControl,
  updateUserControl,
} from "../../features/report/reportThunks";

type ControlType = {
  key: "WARNING" | "PAUSE" | "FIRST_WARNING";
  active_days: number;
};
type TUserControl = {
  control_type: ControlType;
  reason: ReportKey;
  control_off_at: string;
  created_at: string;
  count: number;
  active_count: number;
  is_clean_report: boolean;
  is_automation: boolean;
};

type TUserControlLoading = "NONE" | "LOADING_USER_CONTROL";
type TUSerControlError = boolean;

function isEmpty(object: any) {
  for (const property in object) {
    return false;
  }
  return true;
}

const useUserControl = () => {
  const dispatch = useAppDispatch();

  const userControl: TUserControl = useAppSelector(selectUserControl);
  const userControlLoading: TUserControlLoading = useAppSelector(
    selectUserControlLoading
  );
  const userControlError: TUSerControlError = useAppSelector(
    selectuserControlError
  );

  const getIsAutomation = () => {
    return userControl.is_automation;
  };

  const getUserControlType = () => {
    if (isEmpty(userControl)) {
      return "NONE";
    }

    if (
      !isEmpty(userControl) &&
      userControl.control_type.key === "FIRST_WARNING"
    ) {
      return "FIRST_WARNING";
    }

    if (!isEmpty(userControl) && userControl.control_type.key === "WARNING") {
      return "WARNING";
    }

    if (!isEmpty(userControl) && userControl.control_type.key === "PAUSE") {
      return "PAUSE";
    }

    return "NONE";
  };

  const getControlOff = () => {
    if (!isEmpty(userControl) && userControl.control_off_at) {
      return userControl.control_off_at;
    }
    return new Date();
  };

  const getReasonKey = (): ReportKey | "NONE" => {
    if (!isEmpty(userControl) && userControl.reason) {
      return userControl.reason;
    }

    return "NONE" as ReportKey;
  };

  const getUserControl = async (domain: "FEED") => {
    await dispatch(fetchUserControl({ domain }));
  };

  const checkUserControlWarning = async () => {
    await dispatch(updateUserControl({ domain: "FEED" }));
  };

  return {
    userControl,
    userControlLoading,
    userControlError,
    getUserControl,
    checkUserControlWarning,
    getUserControlType,
    getControlOff,
    getReasonKey,
    getIsAutomation,
  };
};

export default useUserControl;
