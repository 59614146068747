import { createContext } from "react";
export interface DisplayMode {
  LIGHT: "light";
  DARK: "dark";
}

export type Settings = {
  displayMode: DisplayMode[keyof DisplayMode];
  setDisplayMode: (mode: DisplayMode[keyof DisplayMode]) => void;
};

export const defaultSettings: Settings = {
  displayMode: "light",
  setDisplayMode: mode => {
    return mode;
  },
};

export const SettingsContext = createContext<Settings>(defaultSettings);
