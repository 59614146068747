import { useCallback } from "react";
import { useAppSelector, useAppDispatch } from "../../common/hooks/reduxHooks";

import {
  ServerMaintenance,
  IsServerMaintenanceError,
} from "../../features/serverMaintenance/types";

import {
  selectServerMaintenance,
  selectIsServerMaintenanceError,
} from "../../features/serverMaintenance/serverMaintenanceSlice";

import { fetchServerStatus } from "../../features/serverMaintenance/serverMaintenaceThunks";

const useServerMaintenance = () => {
  const dispatch = useAppDispatch();

  const serverMaintenance: ServerMaintenance = useAppSelector(
    selectServerMaintenance
  );

  const isMaintenance = serverMaintenance.isMaintenance;
  const maintenanceStartTime = serverMaintenance.startTime;
  const maintenanceEndTime = serverMaintenance.endTime;

  const isServerMaintenanceError: IsServerMaintenanceError = useAppSelector(
    selectIsServerMaintenanceError
  );

  const getServerStatus = useCallback(async () => {
    await dispatch(fetchServerStatus());
  }, [dispatch]);

  return {
    isMaintenance,
    maintenanceStartTime,
    maintenanceEndTime,
    isServerMaintenanceError,
    getServerStatus,
  };
};

export default useServerMaintenance;
