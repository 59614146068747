import { configureStore, combineReducers } from "@reduxjs/toolkit";

import serverMaintenanceReducer from "./serverMaintenance/serverMaintenanceSlice";
import accountReducer from "../features/account/accountSlice";
import reportReducer from "../features/report/reportSlice";
import feedListReducer from "../features/feedList/feedListSlice";
import globalAppStateReducer from "../features/globalAppState/globalAppStateSlice";
import abTestDataReducer from "../features/abTestData/abTestDataSlice";
import bookmarkReducer from "./bookmark/bookmarkSlice";
import promotionReducer from "./promotion/promotionSlice";

const reducer = combineReducers({
  serverMaintenance: serverMaintenanceReducer,
  account: accountReducer,
  report: reportReducer,
  globalAppState: globalAppStateReducer,
  feedList: feedListReducer,
  abTestData: abTestDataReducer,
  bookmark: bookmarkReducer,
  promotion: promotionReducer,
});

export const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware(),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
