import { ComponentPropsWithoutRef } from "react";
import { ToolTip } from "../../components";
import { useDispatch } from "react-redux";
import {
  selectToolTips,
  setToolTips,
} from "../../features/globalAppState/globalAppStateSlice";
import { useAppSelector } from "../../common/hooks/reduxHooks";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";
import getTranslation from "../../common/utils/getTranslation";

// NOTE - 무료 프로필 교환 아이템 지급 완료 후 Feed Detail 화면에서 아이템 사용 넛지하는 툴팁
const FreeProfileExchangeItemUseNudgeToolTip = () => {
  const dispatch = useDispatch();

  const freeProfileExchangeItemUseNudgeToolTip =
    useAppSelector(selectToolTips).FREE_PROFILE_EXCHANGE_ITEM_USE_NUDGE_VISIBLE;

  const tooltipProps: ComponentPropsWithoutRef<typeof ToolTip> = {
    title: getTranslation(
      "ProfileExchangeNudge.sendProfileExchangeWithFreeVoucher"
    ) as string,
    content: getTranslation(
      "ProfileExchangeNudge.sendAnonymousProfileToSeeRealProfile"
    ) as string,
    isVisible: freeProfileExchangeItemUseNudgeToolTip,
    onClose: () => {
      dispatch(
        setToolTips({
          key: "FREE_PROFILE_EXCHANGE_ITEM_USE_NUDGE_VISIBLE",
          isVisible: false,
        })
      );

      ReactNativeWebview.postMessageToApp({
        type: "FREE_PROFILE_EXCHANGE_ITEM_USE_NUDGE_TOOL_TIP_SEEN",
      });
    },
    triangleType: "TOP",
    triangleHorizontalPosition: {
      left: 11,
    },
    toolTipPosition: {
      left: 20,
      top: 49,
    },
  };

  return <ToolTip {...tooltipProps} />;
};

export default FreeProfileExchangeItemUseNudgeToolTip;
