import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store";

import { DeliverFirebaseRemoteConfigValueData } from "../../common/utils/ReactNativeWebview/appEventListenerService/types";

type ServerAbTestType = {
  key: string;
  value: string;
};

type abTestDataType = {
  remoteConfig: any;
  serverAbTest: ServerAbTestType[];
};

const initialState: abTestDataType = {
  remoteConfig: {},
  serverAbTest: [],
};

export const abTestDataSlice = createSlice({
  name: "abTestData",
  initialState,
  reducers: {
    setFirebaseAbTestData: (
      state,
      action: PayloadAction<DeliverFirebaseRemoteConfigValueData>
    ) => {
      return {
        ...state,
        remoteConfig: { ...state.remoteConfig, ...action.payload },
      };
    },
    setAbTestGroupData: (state, action) => {
      return {
        ...state,
        serverAbTest: action.payload,
      };
    },
  },
});

export const { setFirebaseAbTestData, setAbTestGroupData } =
  abTestDataSlice.actions;

export const selectABTestData = (state: RootState) => state.abTestData;

export default abTestDataSlice.reducer;
