import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";

type PromotionType = {
  profile_exchange?: any;
  profile_exchange_3_discount?: any;
};

export type PromotionDataType = {
  [key: string]: {
    id: number;
    status: "ENABLED" | "DISABLED" | "PENDING";
    startTime: string | null;
    endTime: string | null;
    usedAt: string | null;
  };
};

const initialState: PromotionType = {};

export const promotionSlice = createSlice({
  name: "promotion",
  initialState,
  reducers: {
    setPromotion: (state, action: PayloadAction<PromotionDataType>) => {
      return { ...state, ...action.payload };
    },
  },
});

export const { setPromotion } = promotionSlice.actions;

export const selectPromotion = (state: RootState) => state.promotion;

export default promotionSlice.reducer;
