import React from "react";

import { GifContainer, Gif, GifWatermark, GifMark } from "./styles";

const BigGif = ({ source }: { source: string }) => {
  return (
    <GifContainer>
      <Gif src={source} />
      <GifMark />
      <GifWatermark />
    </GifContainer>
  );
};

export default BigGif;
