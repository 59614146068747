import { createAsyncThunk } from "@reduxjs/toolkit";
import { snsAxiosInstance } from "../../common/utils/axios";
import apis from "../../api/apis";
import { IFeedItem } from "../../common/types/commonTypes";
import { parseNextFeedId } from "../../common/utils/parseNextPageUrl";
import { setShowAlertStatus } from "./bookmarkSlice";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";

const fetchBookmarkList = createAsyncThunk(
  "bookmark/fetchBookmarkList",
  async (
    {
      fetchType,
      page = 0,
    }: { fetchType: "REFRESH" | "MORE" | "INIT"; page?: number },
    { rejectWithValue }
  ) => {
    try {
      const response = await snsAxiosInstance.get(apis.bookmark.bookmark, {
        params: {
          page,
        },
      });

      let wrappedData = response.data.data;

      wrappedData.feed_list.map((feedItem: IFeedItem) => {
        let wrappedFeedItem = feedItem;
        wrappedFeedItem.feedpost.content.detail =
          wrappedFeedItem.feedpost.content.url!;
        wrappedFeedItem.feedpost.content.url = null;
      });

      const nextPageId = parseNextFeedId(response.data.data.next_page) as
        | string
        | 0;

      const currentPage =
        fetchType === "REFRESH" || fetchType === "INIT" ? 0 : page;

      return { wrappedData, nextPageId, currentPage };
    } catch (error: any) {
      return rejectWithValue("FAIL_RETRIEVE_BOOKMARK_LIST");
    }
  }
);

const createBookmark = createAsyncThunk(
  "bookmark/createBookmark",
  async (
    {
      feedpostId,
      contentType,
      contentUserUuid,
    }: {
      feedpostId: string;
      contentType: "NORMAL" | "AUDIO" | "GIF" | "IMAGE";
      contentUserUuid: string;
    },
    { dispatch, rejectWithValue }
  ) => {
    try {
      const response = await snsAxiosInstance.post(apis.bookmark.bookmark, {
        feedpostId,
      });

      ReactNativeWebview.postMessageToApp({
        type: "LOG_DATA_FROM_FEED",
        data: {
          eventName: "Feed_Create_Bookmark",
          eventParams: {
            content: contentType,
            feedpost_id: feedpostId,
            target_uuid: contentUserUuid,
          },
        },
      });

      return response.data.data;
    } catch (error: any) {
      if (error.response.data.message === "FEED_POST_IS_HIDED") {
        dispatch(setShowAlertStatus(true));

        return rejectWithValue("FEED_POST_IS_HIDED");
      }
      if (error.response.data.message === "FEED_POST_DOES_NOT_EXIST") {
        dispatch(setShowAlertStatus(true));

        return rejectWithValue("FEED_POST_DOES_NOT_EXIST");
      }

      return rejectWithValue("FAIL_CREATE_BOOKMARK");
    }
  }
);

const deleteBookmark = createAsyncThunk(
  "bookmark/deleteBookmark",
  async ({ feedpostId }: { feedpostId: string }, { rejectWithValue }) => {
    try {
      const response = await snsAxiosInstance.delete(apis.bookmark.bookmark, {
        data: {
          feedpostId,
        },
      });

      return response.data.data;
    } catch (error: any) {
      return rejectWithValue("FAIL_DELETE_BOOKMARK");
    }
  }
);

export { fetchBookmarkList, createBookmark, deleteBookmark };
