import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../store";
import {
  GlobalAppStateState,
  AudioPlayerGlobalState,
  SetToolTipsPayload,
} from "./types";

const initialState: GlobalAppStateState = {
  audioPlayerGlobalState: "NONE",
  toolTips: {
    PROFILE_EXCHANGE_VISIBLE: false,
    FREE_PROFILE_EXCHANGE_ITEM_NOTICE_VISIBLE: false,
    FREE_PROFILE_EXCHANGE_ITEM_USE_NUDGE_VISIBLE: false,
  },
};

export const globalAppStateSlice = createSlice({
  name: "globalAppState",
  initialState,
  reducers: {
    setAudioPlayerGlobalState: (
      state,
      action: PayloadAction<AudioPlayerGlobalState>
    ) => {
      state.audioPlayerGlobalState = action.payload;
    },

    setToolTips: (state, action: PayloadAction<SetToolTipsPayload>) => {
      state.toolTips[action.payload.key] = action.payload.isVisible;
    },
  },
});

export const { setAudioPlayerGlobalState, setToolTips } =
  globalAppStateSlice.actions;

export const selectAudioPlayerGlobalState = (state: RootState) =>
  state.globalAppState.audioPlayerGlobalState;

export const selectToolTips = (state: RootState) =>
  state.globalAppState.toolTips;

export default globalAppStateSlice.reducer;
