import { createAsyncThunk } from "@reduxjs/toolkit";
import apis from "../../api/apis";
import { mainAxiosInstance } from "../../common/utils/axios";

const fetchFeedReportDetail = createAsyncThunk(
  "feedReportDetail/fetchFeedReportDetail",
  async ({ feedId }: { feedId: string }, { rejectWithValue }) => {
    try {
      const response = await mainAxiosInstance.get(
        `${apis.feed.receivedReports}?domain=FEED&id=${feedId}`
      );
      return response.data;
    } catch (error: any) {
      return rejectWithValue("FAILED_TO_FETCH_FEED_REPORT_DETAIL");
    }
  }
);

export { fetchFeedReportDetail };
