import axios from "axios";
import apis, { snsServerBaseURL, mainServerBaseURL } from "../../api/apis";

export const snsAxiosInstance = axios.create({
  baseURL: snsServerBaseURL,
});

export const mainAxiosInstance = axios.create({
  baseURL: mainServerBaseURL,
});

export const serverMaintenanceApi = axios.create({
  baseURL: apis.serverMaintenance,
});

export const configureAxiosAuthorizationHeader = (authToken: string) => {
  snsAxiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Token ${authToken}`;
  mainAxiosInstance.defaults.headers.common[
    "Authorization"
  ] = `Token ${authToken}`;
};

export const configureAxiosUuidHeader = (uuid: string) => {
  snsAxiosInstance.defaults.headers.common["uuid"] = uuid;
  mainAxiosInstance.defaults.headers.common["uuid"] = uuid;
};
