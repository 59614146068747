import styled, { keyframes } from "styled-components";

const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const fadeOut = keyframes`
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const ScrollToTopIconContainer = styled.div`
  display: flex;
  position: fixed;
  bottom: 20px;
  left: 20px;
  visibility: ${(props: { visible: boolean }) =>
    props.visible ? "visible" : "hidden"};
  z-index: 15;
  animation: ${(props: { visible: boolean }) =>
      props.visible ? fadeIn : fadeOut}
    0.2s ease-out;
  transition: visibility 0.2s ease-out;
`;
