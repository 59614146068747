import { createAsyncThunk } from "@reduxjs/toolkit";

import apis from "../../api/apis";

import { snsAxiosInstance } from "../../common/utils/axios";
import ReactNativeWebview from "../../common/utils/ReactNativeWebview";
import { store } from "../store";
import { selectABTestData } from "../abTestData/abTestDataSlice";

const fetchFeedDetail = createAsyncThunk(
  "feedDetail/fetchFeedDetail",
  async (
    {
      feedId,
    }: {
      feedId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await snsAxiosInstance.get(
        `${apis.feed.feedPost}/${feedId}`
      );
      let wrappedData = response.data.data;
      wrappedData.feedpost.content.detail = wrappedData.feedpost.content.url!;
      wrappedData.feedpost.content.url = null;
      return wrappedData;
    } catch (error: any) {
      return rejectWithValue(error.response.data);
    }
  }
);

const createFeedComment = createAsyncThunk(
  "feedDetail/createFeedComment",
  async (
    {
      feedId,
      text,
      fileType,
      files,
      audioDuration,
      callRoomId,
    }: {
      feedId: string;
      text: string;
      fileType: "AUDIO" | "GIF" | "NORMAL" | "IMAGE";
      files: Array<{ url: string }>;
      audioDuration: number;
      callRoomId?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      // Example logic using serverAbTest data

      const response = await snsAxiosInstance.post(
        `${apis.feed.feedComment}/`,
        {
          feedpostId: feedId,
          text: text,
          contentType: fileType,
          contentURL: files,
          contentDuration: audioDuration,
          callRoomId,
        }
      );

      if (response.data.data) {
        const profileExchangeNudgeAbTestGroup = selectABTestData(
          store.getState()
        ).serverAbTest.find(
          (abTest) => abTest.key === "ab_profile_exchange_nudge_KRJP"
        );

        if (!profileExchangeNudgeAbTestGroup) {
          ReactNativeWebview.postMessageToApp({
            type: "GET_FREE_PROFILE_EXCHANGE_NUDGE_INFORMATION",
          });
        }
      }

      return response.data.data;
    } catch (error: any) {
      if (error.response.data.message === "BLACK_TYPE_USER") {
        return rejectWithValue("BLACK_TYPE_USER");
      }

      if (
        error.response.data.message === "WRITE_COMMENT_IN_DELETED_FEED_ERROR"
      ) {
        return rejectWithValue("WRITE_COMMENT_IN_DELETED_FEED_ERROR");
      }

      return rejectWithValue("FAILED_TO_CREATE_FEED_COMMENT");
    }
  }
);

const createFeedRecomment = createAsyncThunk(
  "feedDetail/createFeedRecomment",
  async (
    {
      feedId,
      commentId,
      mentionedUserUuid,
      text,
      fileType,
      files,
      audioDuration,
      callRoomId,
    }: {
      feedId: string;
      commentId: string;
      mentionedUserUuid: string;
      text: string;
      fileType: "AUDIO" | "GIF" | "NORMAL" | "IMAGE";
      files: Array<{ url: string }>;
      audioDuration: number;
      callRoomId?: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await snsAxiosInstance.post(
        `${apis.feed.feedComment}/re`,
        {
          feedpostId: feedId,
          commentId: commentId,
          mentionedUserUUID: mentionedUserUuid,
          text: text,
          contentType: fileType,
          contentURL: files,
          contentDuration: audioDuration,
          callRoomId,
        }
      );

      if (response.data.data) {
        const profileExchangeNudgeAbTestGroup = selectABTestData(
          store.getState()
        ).serverAbTest.find(
          (abTest) => abTest.key === "ab_profile_exchange_nudge_KRJP"
        );

        if (!profileExchangeNudgeAbTestGroup) {
          ReactNativeWebview.postMessageToApp({
            type: "GET_FREE_PROFILE_EXCHANGE_NUDGE_INFORMATION",
          });
        }
      }

      return response.data.data;
    } catch (error: any) {
      return rejectWithValue("FAILED_TO_CREATE_FEED_RECOMMENT");
    }
  }
);

const fetchFeedComments = createAsyncThunk(
  "feedDetail/fetchFeedComments",
  async (
    {
      feedId,
      page,
    }: {
      feedId: string;
      page: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await snsAxiosInstance.get(
        `${apis.feed.feedPost}/${feedId}/comments`,
        {
          params: {
            page,
          },
        }
      );

      return response.data.data;
    } catch (error: any) {
      if (error.response.data.message === "BLACK_TYPE_USER") {
        return rejectWithValue("BLACK_TYPE_USER");
      }

      return rejectWithValue("FAILED_TO_FETCH_FEED_DETAIL");
    }
  }
);

const fetchFeedRecomments = createAsyncThunk(
  "feedDetail/fetchFeedRecomments",
  async (
    {
      commentId,
      page,
    }: {
      commentId: string;
      page: number;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await snsAxiosInstance.get(
        `${apis.feed.feedComment}/${commentId}/recomments`,
        {
          params: {
            page,
          },
        }
      );

      return response.data.data;
    } catch (error: any) {
      return rejectWithValue("FAILED_TO_FETCH_FEED_RECOMMENTS");
    }
  }
);

const likeComment = createAsyncThunk(
  "feedDetail/likeComment",
  async (
    {
      feedId,
      commentId,
    }: {
      feedId: string;
      commentId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await snsAxiosInstance.post(
        `${apis.feed.toggleLikeComment}/`,
        {
          feedpostId: feedId,
          commentId,
        }
      );

      return response.data.data;
    } catch (error) {
      return rejectWithValue("FAILED TO LIKE COMMENT");
    }
  }
);

const unlikeComment = createAsyncThunk(
  "feedDetail/unlikeComment",
  async (
    {
      feedId,
      commentId,
    }: {
      feedId: string;
      commentId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await snsAxiosInstance.delete(
        `${apis.feed.toggleLikeComment}/`,
        {
          data: {
            feedpostId: feedId,
            commentId,
          },
        }
      );

      return response.data.data;
    } catch (error) {
      return rejectWithValue("FAILED TO unLIKE COMMENT");
    }
  }
);

//TODO: 임시 레거시 입니다. 나중에 다시 들어갈수 있습니다. 피드 익명으로 들어가면서 프로필 교환 으로 인헤 글이 삭제 되는 이슈를 방제 하기 위헤 임시로 사용안하고 다시 들어갈수 있습니다.
const deleteComment = createAsyncThunk(
  "feedDetail/deleteComment",
  async (
    {
      commentId,
    }: {
      commentId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await snsAxiosInstance.delete(
        `${apis.feed.feedComment}/${commentId}`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue("FAILED TO DELETE COMMENT");
    }
  }
);

export {
  fetchFeedDetail,
  createFeedComment,
  fetchFeedComments,
  fetchFeedRecomments,
  createFeedRecomment,
  likeComment,
  unlikeComment,
  deleteComment,
};
