import axios, { AxiosResponse } from "axios";

import { createAsyncThunk } from "@reduxjs/toolkit";

import { FeedDataResponse, FeedListData } from "./types";

import apis from "../../api/apis";

import { snsAxiosInstance } from "../../common/utils/axios";
import { IFeedItem } from "../../common/types/commonTypes";

import { parseNextFeedId } from "../../common/utils/parseNextPageUrl";

const fetchInitFeeds = createAsyncThunk(
  "feedList/fetchInitFeeds",
  async (args, { dispatch, rejectWithValue }) => {
    try {
      const fetchList = [
        {
          page: 0,
          category: "notice",
        },
        {
          page: 0,
          category: "recommend",
        },
        {
          page: 0,
          category: "latest",
        },
        {
          page: 0,
          category: "my",
        },
      ];

      const responses: Array<{
        feed_list: FeedListData;
        next_page: string;
      }> = await Promise.all(
        fetchList.map(async feed => {
          const response: AxiosResponse<FeedDataResponse> =
            await snsAxiosInstance.get(apis.feed.feedLists, {
              params: {
                category: feed.category,
                page: feed.page,
              },
            });

          let wrappedData = response.data.data;

          wrappedData.feed_list.map((feedItem: IFeedItem) => {
            let wrappedFeedItem = feedItem;
            wrappedFeedItem.feedpost.content.detail =
              wrappedFeedItem.feedpost.content.url!;
            wrappedFeedItem.feedpost.content.url = null;
          });

          return wrappedData;
        })
      );

      const feeds = {
        notice: responses[0].feed_list,
        recommend: responses[1].feed_list,
        latest: responses[2].feed_list,
        my: responses[3].feed_list,
      };

      const nextPageIds = {
        notice: parseNextFeedId(responses[0].next_page) as string | 0,
        recommend: parseNextFeedId(responses[1].next_page) as string | 0,
        latest: parseNextFeedId(responses[2].next_page) as string | 0,
        my: parseNextFeedId(responses[3].next_page) as string | 0,
      };

      return { feeds, nextPageIds };
    } catch (error) {
      return rejectWithValue("FAILED_FETCH_INIT_FEEDS");
    }
  }
);

const fetchFeed = createAsyncThunk(
  "feedList/fetchFeed",
  async (
    {
      fetchType,
      page,
      category,
    }: {
      fetchType: "REFRESH" | "MORE";
      page: number | string;
      category: "my" | "latest" | "notice" | "recommend";
    },
    { rejectWithValue }
  ) => {
    try {
      const response: AxiosResponse<FeedDataResponse> =
        await snsAxiosInstance.get(apis.feed.feedLists, {
          params: {
            category: category,
            page: fetchType === "REFRESH" ? 0 : page,
          },
        });

      let wrappedData = response.data.data;

      wrappedData.feed_list.map((feedItem: IFeedItem) => {
        let wrappedFeedItem = feedItem;
        wrappedFeedItem.feedpost.content.detail =
          wrappedFeedItem.feedpost.content.url!;
        wrappedFeedItem.feedpost.content.url = null;
      });

      return wrappedData;
    } catch (error: any) {
      return rejectWithValue("FAILED_TO_FETCH_FEED");
    }
  }
);

const likeFeed = createAsyncThunk(
  "feedList/likeFeed",
  async (
    {
      feedId,
    }: {
      feedId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await snsAxiosInstance.post(apis.feed.toggleLikeFeed, {
        feedpostId: feedId,
      });

      return response.data.data;
    } catch (error: any) {
      return rejectWithValue("FAILED TO LIKE FEED");
    }
  }
);

const unlikeFeed = createAsyncThunk(
  "feedList/unlikeFeed",
  async (
    {
      feedId,
    }: {
      feedId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await snsAxiosInstance.delete(apis.feed.toggleLikeFeed, {
        data: { feedpostId: feedId },
      });

      return response.data.data;
    } catch (error: any) {
      return rejectWithValue("FAILED TO UNLIKE FEED");
    }
  }
);

//TODO: 임시 레거시 입니다. 나중에 다시 들어갈수 있습니다. 피드 익명으로 들어가면서 프로필 교환 으로 인헤 글이 삭제 되는 이슈를 방제 하기 위헤 임시로 사용안하고 다시 들어갈수 있습니다.
const deleteFeed = createAsyncThunk(
  "feedPost/delete",
  async (
    {
      feedId,
    }: {
      feedId: string;
    },
    { rejectWithValue }
  ) => {
    try {
      const response = await snsAxiosInstance.delete(
        `${apis.feed.feedPost}/${feedId}`
      );
      return response.data.data;
    } catch (error: any) {
      return rejectWithValue("FAILED TO DELETE FEED");
    }
  }
);

const fetchMyFeedStatus = createAsyncThunk(
  "feedList/myFeedStatus",
  async ({ uuid }: { uuid: string }, { rejectWithValue }) => {
    try {
      const response = await snsAxiosInstance.get(
        `${apis.feed.myFeedStatus}/${uuid}/status/count`,
        {
          headers: {
            "Remote-user": uuid,
          },
        }
      );
      return response.data.data;
    } catch (error) {}
  }
);

export {
  fetchFeed,
  fetchInitFeeds,
  likeFeed,
  unlikeFeed,
  deleteFeed,
  fetchMyFeedStatus,
};
