import { useContext } from "react";
import { SettingsContext, defaultSettings } from "../../core/contexts/settings";

const useSettings = () => {
  const settings = useContext(SettingsContext);
  if (!settings) {
    return defaultSettings;
  }

  return settings;
};

export default useSettings;
