import styled from "styled-components";

interface ImageSourceButtonProps {
  width?: number;
  height?: number;
  source: string;
}

const ImageSourceButton = styled.button`
  width: ${(props: ImageSourceButtonProps) => props.width || 18}px;
  height: ${(props: ImageSourceButtonProps) => props.height || 18}px;
  background-size: contain;
  background-image: url(${(props: ImageSourceButtonProps) => props.source});
`;

export type { ImageSourceButtonProps };

export { ImageSourceButton };

interface ImageButtonProps {
  source: string;
}

const ImageButton = styled.button`
  background-size: contain;
  background-image: url(${(props: ImageButtonProps) => props.source});
`;

interface IconProps {}

const Icon = styled.img``;
