import React from "react";

import { useNavigate } from "react-router-dom";

import ReactNativeWebview from "../../common/utils/ReactNativeWebview";

import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";

import { selectMyFeedStatus } from "../../features/feedList/feedListSlice";

import { MyFeedDataProps } from "../../components/MyFeedData/types";
import { ProfileExchangeButtonProps } from "../../components/ProfileExchangeButton/types";
import { MyFeedData, ProfileExchangeButton } from "../../components";

import { Container, Spacer, WithBookmarkButtonContainer } from "./styles";
import { selectABTestData } from "../../features/abTestData/abTestDataSlice";
import BookmarkButton from "../../components/BookmarkButton";
import { fetchBookmarkList } from "../../features/bookmark/bookmarkThunks";

const MyFeedBanner = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const myFeedStatus = useAppSelector(selectMyFeedStatus);
  const abTest = useAppSelector(selectABTestData);

  const myFeedDataProps: MyFeedDataProps = {
    totalFeedCount: myFeedStatus.totalFeedCount,
    totalLikeCount: myFeedStatus.totalLikeCount,
    totalCommentCount: myFeedStatus.totalCommentCount,
  };

  const profileExchangeButtonProps: ProfileExchangeButtonProps = {
    onClick: () => {
      ReactNativeWebview.postMessageToApp({
        type: "NAVIGATE_TO_PROFILE_EXCHANGE",
      });
    },
  };

  const bookmarkButtonProps = {
    onClick: async () => {
      navigate("/bookmark_list");

      await dispatch(fetchBookmarkList({ fetchType: "INIT" }));
    },
  };

  return (
    <Container>
      <MyFeedData {...myFeedDataProps} />
      <Spacer />

      <WithBookmarkButtonContainer>
        <ProfileExchangeButton {...profileExchangeButtonProps} />
        <BookmarkButton {...bookmarkButtonProps} />
      </WithBookmarkButtonContainer>
    </Container>
  );
};

export default MyFeedBanner;
