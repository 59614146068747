import { useState, useEffect, useCallback } from "react";
import { ModalProvider } from "styled-react-modal";
import {
  CommonHeader,
  ShowTranslation,
  Loading as LoadingScreen,
} from "../../components";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getYearMonthDay } from "../../common/utils/timeFormatters";
import getTranslation from "../../common/utils/getTranslation";
import showMoreIcon from "../../assets/images/icOpenArrow14@3x.png";
import { useAppDispatch, useAppSelector } from "../../common/hooks/reduxHooks";
import { unwrapResult } from "@reduxjs/toolkit";
import { selectReportItemDescription } from "../../features/report/reportSlice";
import { fetchFeedReportDetail } from "../../features/feedReportDetail/feedReportDetailThunks";
import colors from "../../styles/colors";
import {
  ModalBackground,
  StyledModal,
  StickyHeader,
  Container,
  BannerContainer,
  TotalCountContainer,
  ListContainer,
  ItemContainer,
  ReportType,
  ReportTime,
  ShowMoreContainer,
} from "./styles";
import { ReportKey } from "../../features/report/reportSlice";
import useModeColors from "../../common/hooks/useModeColors";

type TLoading = "LOADING" | "NONE";

type TReport = {
  id: number;
  reason: ReportKey;
  created_at: string;
  first_warning: boolean;
};

const FeedReportDetailModal = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { id: feedId } = useParams();
  const modeColors = useModeColors();
  const reportItemDescription = useAppSelector(selectReportItemDescription);
  const location = useLocation();
  const { state }: { state: any } = location;
  const [loadedFeedReportDetail, setLoadedFeedReportDetail] = useState<
    Array<TReport>
  >([]);
  const [showingFeedReportDetail, setShowingFeedReportDetail] = useState<
    Array<TReport>
  >([]);
  const [isShowMore, setIsShowMore] = useState<boolean>(false);
  const [loading, setLoading] = useState<TLoading>("LOADING");

  const afterModalClose = () => {
    setLoadedFeedReportDetail([]);
    setShowingFeedReportDetail([]);
    setIsShowMore(false);
    setLoading("LOADING");
  };

  const onClickBack = () => {
    if (state && "prevpath" in state) {
      navigate(-1);
      return;
    }
  };

  const showMoreFeedReportDetail = useCallback(() => {
    if (loadedFeedReportDetail.length > 4) {
      setLoadedFeedReportDetail(loadedFeedReportDetail.slice(4));
      setShowingFeedReportDetail(prevShowingFeedReportDetail => {
        return prevShowingFeedReportDetail.concat(
          loadedFeedReportDetail.slice(0, 4)
        );
      });
      setIsShowMore(true);
      return;
    }

    if (loadedFeedReportDetail.length > 0) {
      setLoadedFeedReportDetail([]);
      setShowingFeedReportDetail(prevShowingFeedReportDetail => {
        return prevShowingFeedReportDetail.concat(loadedFeedReportDetail);
      });
      setIsShowMore(false);
      return;
    }

    setIsShowMore(false);
  }, [loadedFeedReportDetail]);

  const getFeedReportDetail = useCallback(async () => {
    try {
      if (feedId) {
        const feedReportDetailResult = await dispatch(
          fetchFeedReportDetail({ feedId: feedId })
        );
        const unwrappedFeedReportDetailResult = unwrapResult(
          feedReportDetailResult
        );
        setLoadedFeedReportDetail(unwrappedFeedReportDetailResult.slice(4));
        setShowingFeedReportDetail(unwrappedFeedReportDetailResult.slice(0, 4));
        if (unwrappedFeedReportDetailResult.length > 4) {
          setIsShowMore(true);
        }
      }
    } catch (error) {
      setLoading("NONE");
    }
  }, [dispatch, feedId]);

  useEffect(() => {
    if (feedId) {
      getFeedReportDetail();
    }
  }, [feedId, getFeedReportDetail]);

  useEffect(() => {
    if (showingFeedReportDetail.length) {
      setLoading("NONE");
    }
  }, [showingFeedReportDetail]);

  const renderReportBanner = () => {
    return (
      <BannerContainer>
        <ShowTranslation weight={"m"} size={13} color={colors.White}>
          {getTranslation("FeedReportDetailModal.feedReportBanner")}
        </ShowTranslation>
      </BannerContainer>
    );
  };
  const renderReportCount = () => {
    const totalCount =
      loadedFeedReportDetail.length + showingFeedReportDetail.length;
    return (
      <TotalCountContainer>
        <ShowTranslation weight={"m"} size={15} color={modeColors.text_03}>
          {getTranslation("FeedReportDetailModal.feedReportCount", {
            "{n}": totalCount,
          })}
        </ShowTranslation>
      </TotalCountContainer>
    );
  };

  const renderReportItem = (report: TReport) => {
    return (
      <ItemContainer key={`report-id-${report.id}`}>
        <ReportType>
          <ShowTranslation weight={"m"} size={17} color={modeColors.text_01}>
            {reportItemDescription[report.reason as ReportKey]}
          </ShowTranslation>
        </ReportType>
        <ReportTime>
          <ShowTranslation weight={"r"} size={13} color={modeColors.text_02}>
            {getYearMonthDay(report.created_at)}
          </ShowTranslation>
        </ReportTime>
      </ItemContainer>
    );
  };

  const renderShowMore = () => {
    return (
      <ShowMoreContainer onClick={showMoreFeedReportDetail}>
        <ShowTranslation weight={"b"} size={15} color={modeColors.text_03}>
          {getTranslation("FeedReportDetailModal.more")}
        </ShowTranslation>
      </ShowMoreContainer>
    );
  };

  const renderReportList = () => {
    return (
      <ListContainer>
        {showingFeedReportDetail.map(report => {
          return renderReportItem(report);
        })}
        {isShowMore && renderShowMore()}
      </ListContainer>
    );
  };

  if (showingFeedReportDetail) {
    return (
      <ModalProvider backgroundComponent={ModalBackground}>
        <StyledModal afterClose={afterModalClose} isOpen={true}>
          {loading === "LOADING" ? (
            <LoadingScreen />
          ) : (
            <>
              <StickyHeader>
                <CommonHeader
                  headerLeft={"back"}
                  headerTitle={
                    <ShowTranslation
                      weight="m"
                      size={17}
                      color={modeColors.text_01}
                    >
                      {getTranslation("FeedReportDetailModal.feedReportDetail")}
                    </ShowTranslation>
                  }
                  onClickLeft={onClickBack}
                />
              </StickyHeader>
              <Container>
                {renderReportBanner()}
                {renderReportCount()}
                {renderReportList()}
              </Container>
            </>
          )}
        </StyledModal>
      </ModalProvider>
    );
  }

  return null;
};

export default FeedReportDetailModal;
