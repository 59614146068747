import React, { useState, useCallback } from "react";

import { SettingsContext, DisplayMode } from "../contexts/settings";

const SettingsProvider: React.FC = ({ children }) => {
  const [displayMode, setDisplayMode] =
    useState<DisplayMode[keyof DisplayMode]>("light");

  // Memoize the setter function to avoid unnecessary re-renders
  const handleSetDisplayMode = useCallback(
    (mode: DisplayMode[keyof DisplayMode]) => {
      setDisplayMode(mode);
    },
    []
  );

  return (
    <SettingsContext.Provider
      value={{
        displayMode,
        setDisplayMode: handleSetDisplayMode,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
