import styled from "styled-components";

export const LoadingContainer = styled.div`
  display: flex;
  padding: 30px 0px;
  justify-content: center;
  align-items: center;
  background-color: ${({ theme }) => theme.modeColors.background_01};
`;

export const Empty = styled.div`
  background-color: ${({ theme }) => theme.modeColors.background_01};
`;
