import { ReactComponent as ic_bookmark_filled } from "./ic_bookmark_filled.svg";
import { ReactComponent as ic_bookmark_line_regular } from "./ic_bookmark_line_regular.svg";
import { ReactComponent as ic_burble_line_bold } from "./ic_burble_line_bold.svg";
import { ReactComponent as ic_close_line_bold } from "./ic_close_line_bold.svg";
import { ReactComponent as ic_close_line_regular } from "./ic_close_line_regular.svg";
import { ReactComponent as ic_heart_filled } from "./ic_heart_filled.svg";
import { ReactComponent as ic_heart_line_regular } from "./ic_heart_line_regular.svg";
import { ReactComponent as ic_left_line_regular } from "./ic_left_line_regular.svg";
import { ReactComponent as ic_mic_filled } from "./ic_mic_filled.svg";
import { ReactComponent as ic_more_filled_bold } from "./ic_more_filled_bold.svg";
import { ReactComponent as ic_pause_filled } from "./ic_pause_filled.svg";
import { ReactComponent as ic_pausecircle_line_regular } from "./ic_pausecircle_line_regular.svg";
import { ReactComponent as ic_play_filled } from "./ic_play_filled.svg";
import { ReactComponent as ic_playcircle_line_regular } from "./ic_playcircle_line_regular.svg";
import { ReactComponent as ic_profileexchange_line_regular } from "./ic_profileexchange_line_regular.svg";
import { ReactComponent as ic_switch01_regular } from "./ic_switch01_regular.svg";
import { ReactComponent as ic_upcircle_filled } from "./ic_upcircle_filled.svg";
import { ReactComponent as ic_bell_line_regular } from "./ic_bell_line_regular.svg";
import { ReactComponent as ic_image_line_regular } from "./ic_image_line_regular.svg";

const basicIcons = {
  ic_bell_line_regular,
  ic_bookmark_filled,
  ic_bookmark_line_regular,
  ic_burble_line_bold,
  ic_close_line_bold,
  ic_close_line_regular,

  ic_heart_filled,
  ic_heart_line_regular,
  ic_left_line_regular,
  ic_mic_filled,
  ic_more_filled_bold,
  ic_pause_filled,
  ic_pausecircle_line_regular,
  ic_play_filled,
  ic_playcircle_line_regular,
  ic_profileexchange_line_regular,
  ic_switch01_regular,
  ic_upcircle_filled,
  ic_image_line_regular,
};

export default basicIcons;
